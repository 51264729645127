import React from 'react';
import { useField, useFormikContext } from 'formik';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import s from './PhoneNumberField.module.css';

export const PhoneNumberField = (props) => {
  const [field, meta] = useField(props);
  const context = useFormikContext();
  const { values, setFieldValue } = context;
  const value = values[field.name];
  const isError = meta.touched && meta.error && true;

  const handleChange = (phoneNumber, { countryCode }) => {
    setFieldValue('countryCode', countryCode);
    setFieldValue(field.name, phoneNumber);
  };

  return (
    <div>
      <span className="col-form-label modal-field-label mb-1">
        {props.label}
        {props.required && <span style={{ color: '#DE684F', marginLeft: '4px' }}>*</span>}
      </span>

      <div className={s.container}>
        <PhoneInput
          country={values.countryCode}
          placeholder="Choose country code"
          enableSearch={true}
          countryCodeEditable={false}
          disableCountryGuess={true}
          value={value}
          onChange={handleChange}
          inputClass={`form-control input-custom false ${s.input} ${s.phoneInput}`}
          dropdownClass={s.phoneInputDropdown}
          buttonClass={s.phoneInputFlagDropdown}
          searchClass={s.phoneInputSearch}
        />
      </div>
      {isError && <span className={s.error}>{meta.error}</span>}
    </div>
  );
};
