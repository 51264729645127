import React from 'react';
import { useField, useFormikContext } from 'formik';
import s from './TextField.module.css';

export const TextField = (props) => {
  const { errorText, ...rest } = props;
  const [field, meta] = useField(props);
  const context = useFormikContext();

  const isError = meta.touched && meta.error && true;

  const handleChange = (e, context) => {
    if (e.target.value === '') {
      context.setFieldValue(props.name, undefined);
    } else {
      context.handleChange(e);
    }
  };

  return (
    <div>
      <span className="col-form-label modal-field-label mb-1" style={{ justifyContent: 'space-between' }}>
        <div>
          {props.label}
          {props.required ? (
            <span style={{ color: '#DE684F', marginLeft: '4px' }}>*</span>
          ) : (
            <span style={{ color: '#6c757d', marginLeft: '4px', fontStyle: 'italic' }}>(Optional)</span>
          )}
        </div>
        {isError && <span className={s.error}>{meta.error}</span>}
      </span>
      <input
        id={props.name}
        type="text"
        error={meta.touched && meta.error && true}
        onChange={(e) => handleChange(e, context)}
        className={`form-control input-custom false ${props.isBlackInput ? s.inputBlack : s.input} ${props.isSmallHeight ? s.smallHeight : ''}`}
        value={field.value}
        {...rest}
      />
    </div>
  );
};
