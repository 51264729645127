import React from 'react';
import PropTypes from 'prop-types';

import './CriticalIssueContainer.css';

const CriticalIssueContainer = ({ children }) => (
	<div className="critical-issue-container d-flex flex-column align-items-center justify-content-center m-3">
		<i className="critical-issue-container-icon material-icons text-danger">
			warning
		</i>
		<p>{children}</p>
	</div>
);
CriticalIssueContainer.propTypes = {
	children: PropTypes.string
};

export default React.memo(CriticalIssueContainer);
