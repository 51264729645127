import React, { useState } from 'react';
import s from './ExportConfig.module.css';
import { data } from './data';
import { CustomRTMPForm } from "./components/CustomRTMPForm/CustomRTMPForm";
import { SRTCallerForm } from "./components/SRTCallerForm/SRTCallerForm";

export const ExportConfig = ({ setDisable, selectedId, setSelectedId, exportForm, setExportForm }) => {

    if (selectedId !== null) {
        setDisable(false)
    }

    const handleFieldClick = (id) => {
        setSelectedId(id);
    };

    const selectedItem = data.find(item => item.id === selectedId);
    const selectedText = selectedItem
        ? selectedItem.label === 'CUSTOM RTMP' || selectedItem.label === 'SRT CALLER'
            ? selectedItem.text
            : (
                <span>
                    <span style={{ color: '#DE684F' }}>{selectedItem.name}</span> {selectedItem.text}
                </span>
            )
        : 'Choose the type of export to be created or click Skip to continue';

    return (
        <div className={s.container}>
            <div className={s.wrapper}>
                {data.map((item) => (
                    <div
                        key={item.id}
                        className={`${s.option} ${selectedId === item.id ? s.active : ''}`}
                        onClick={() => handleFieldClick(item.id)}
                    >
                        <div className={s.optionText}>{item.label}</div>
                    </div>
                ))}
            </div>
            <div>
                {selectedItem && selectedItem.label === 'CUSTOM RTMP' ? (
                    <CustomRTMPForm exportForm={exportForm} setExportForm={setExportForm} />
                ) : selectedItem && selectedItem.label === 'SRT CALLER' ? (
                    <SRTCallerForm exportForm={exportForm} setExportForm={setExportForm} />
                ) : (
                    <div className={s.mainContent}>
                        <div className={s.mainContentText}>{selectedText}</div>
                    </div>
                )}
            </div>
        </div>
    );
};
