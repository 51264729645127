import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import $ from 'jquery';

import './Dashboard.css';

import MultiViewer from '../MultiViewer/MultiViewer';
import OutputsList from '../OutputsList/OutputsList';
import BroadcastDetails from '../BroadcastDetails/BroadcastDetails';
import SettingsModal from '../SettingsModal/SettingsModal';

import { APIs } from '../../constants/endPointsURLs';
import Modal from '../common/Modal/Modal';
import ExportModal from '../Modal/Modal';
import BroadcastDetailsBar from '../BroadcastDetailsBar/BroadcastDetailsBar';
import { ShareModal } from "../ShareModal/ShareModal";
import { DeletePrimaryModal } from '../DeletePrimaryModal/DeletePrimaryModal';

class Dashboard extends React.Component {
  alertDialogs = {
    source_mismatch: {
      title: 'Source Mismatch',
      description: 'Do you want to change primary settings to match source?',
      buttons: [
        {
          name: 'Yes',
          className: 'danger danger-submit-btn',
          clickHandler: () => this.props.onAlertDialogClick(true),
        },
        {
          name: 'No',
          className: 'danger danger-submit-btn',
          clickHandler: () => this.props.onAlertDialogClick(false),
        },
      ],
    },
  };

  state = {
    draggedElementObj: {},
    typeForModal: '',
    idForModal: '',
    nameForModal: '',
    displayedNameForModal: '',
    isDesignatedOutputForModal: '',
    uuidForModal: '',
    recordNameForModal: '',
    isSlidingPanelDisplayed: false,
    handleHideModal: undefined,
    exportsItemId: undefined,
    handleDelete: undefined,
    showExportModal: false,
    exportButtonRole: undefined,
    exportList: [],
    deleteLabelItem: undefined,
    setShowEditModal: undefined,
    exportName: '',
    labelValue: '',
    handleCopyField: undefined,
    videoUrl: undefined,
    checkIsExistOmniSRT: undefined,
    handleUpdateCodecType: undefined,
    isUserFreeTrial: undefined,
    isUserBasic: undefined,
    isUserLiveOmni: undefined,
    isUserGuest: undefined,
    shareId: null,
    primaryToDeleteById: null,
    primaryToDeleteByName: null,
  };

  componentDidMount() {
    if (localStorage.getItem('isSlidingPanelDisplayedState') !== null) {
      const isSlidingPanelDisplayed = JSON.parse(
        localStorage.getItem('isSlidingPanelDisplayedState')
      );
      this.setState({ isSlidingPanelDisplayed });
    } else {
      this.saveBottomPanelTabsStateToLocalStorage();
    }
  }

  componentDidUpdate(prevProps) {
    const { isAlertDialogDisplayed } = this.props;
    if (prevProps.isAlertDialogDisplayed !== isAlertDialogDisplayed) {
      if (isAlertDialogDisplayed) {
        $('#dialog-alert-modal').modal('show');
      } else {
        $('#dialog-alert-modal').modal('hide');
      }
    }
  }

  //-------------- Drag and drop related methods --------------//
  setShareId = (id) => {
    this.setState({ ...this.state, shareId: id })
  }

  setPrimaryToDelete = (id, name) => {
    this.setState({ ...this.state, primaryToDeleteById: id, primaryToDeleteByName: name });
  }

  setDraggedElement = (draggedElementObj) => {
    this.setState({ draggedElementObj });
  };

  handleDrop = (droppedElementObj) => {
    // this.props.handleIFBupEvents();
    // Below is protection agaist self drag and drop (Primary to Primary or Output to Output)
    if (droppedElementObj.type === this.state.draggedElementObj.type) {
      return;
    }

    let primaryId, outputId;
    if (droppedElementObj.type === 'primary') {
      primaryId = droppedElementObj.id;
      outputId = this.state.draggedElementObj.id;
    } else if (droppedElementObj.type === 'output') {
      primaryId = this.state.draggedElementObj.id;
      outputId = droppedElementObj.id;
    }

    this.associatePrimaryWithOutput(primaryId, outputId);
  };
  //------------------------------------------------------------//

  setDesignatedOutput = (outputId, isDesignated) => {
    axios({
      method: 'post',
      url: APIs.setRecordsDesignated,
      data: {
        isDesignated: isDesignated,
        broadcastOutputId: outputId,
      },
      withCredentials: true,
      validateStatus: function (status) {
        return status >= 200;
      },
    })
      .then((response) => { })
      .catch((err) => {
        this.props.showNotification(
          'set_designated',
          'Unable to set designated output'
        );
      });
  };

  associatePrimaryWithOutput = (primaryId, outputId) => {
    const { primaryUsers, outputs, showNotification } = this.props;
    const primaryObj = primaryUsers.find((obj) => obj.id === primaryId);
    const outputObj = outputs.find((obj) => obj.id === outputId);
    let notificationData = {
      primaryName: primaryObj.displayedName || primaryObj.username,
    };

    if (outputObj !== undefined) {
      notificationData.outputName = outputObj.displayedName || outputObj.name;
      if (outputObj.primaryId === primaryId) {
        showNotification(
          'output_connect',
          notificationData.primaryName +
          ' is connected to ' +
          notificationData.outputName
        );
        return;
      }
    }

    axios({
      method: 'post',
      url: APIs.associatePrimaryWithOutput,
      data: {
        primaryId: primaryId,
        broadcastOutputId: outputId,
      },
      withCredentials: true,
      validateStatus: function (status) {
        return status >= 200;
      },
    })
      .then((response) => {
        if (response.status === 201) {
          if (outputObj !== undefined) {
            showNotification(
              'output_connect',
              notificationData.primaryName +
              ' is connected to ' +
              notificationData.outputName
            );
          } else {
            showNotification(
              'output_disconnect',
              notificationData.primaryName + ' is disconnected from output'
            );
          }
        } else if (response.status === 500) {
          if (response.data.message === 'Not supported by license') {
            showNotification(
              'output_blocked',
              notificationData.primaryName +
              ' has no supported license for connecting with ' +
              notificationData.outputName
            );
          } else {
            showNotification('output_blocked', 'Internal Error');
            console.error('Internal Error:', 'Response Message:', response.data.message, 'Response Status Code:', response.status);
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  handleSlidingPanelToggle = () => {
    this.setState(
      {
        isSlidingPanelDisplayed: !this.state.isSlidingPanelDisplayed,
      },
      () => {
        this.saveBottomPanelTabsStateToLocalStorage();
      }
    );
  };

  saveBottomPanelTabsStateToLocalStorage = () => {
    localStorage.setItem(
      'isSlidingPanelDisplayedState',
      JSON.stringify(this.state.isSlidingPanelDisplayed)
    );
  };

  setDetailsForModal = (
    type,
    id,
    name,
    displayedName,
    isDesignatedOutput,
    uuid
  ) => {
    this.setState({
      typeForModal: type,
      idForModal: id,
      nameForModal: name,
      displayedNameForModal: displayedName,
    });

    if (type === 'Output') {
      this.setState({
        isDesignatedOutputForModal: isDesignatedOutput,
        uuidForModal: uuid,
      });
    }
  };

  handleGuidCopyIconClick = (guid) => {
    navigator.clipboard.writeText(guid);
    this.props.showNotification('guid_copy', 'Guid copied to clipboard');
  };

  setDetailsForDeleteRecordModal = (recordNameForModal) => {
    this.setState(
      {
        recordNameForModal,
      },
      () => {
        $('#delete-record-modal').modal();
      }
    );
  };

  setExportModalData = (
    exportsItemId,
    handleDelete,
    handleHideModal,
    exportButtonRole,
    exportList,
    fetchItems,
    deleteLabelItem,
    setShowEditModal,
    exportName,
    labelValue,
    handleCopyField,
    showExportModal,
    videoUrl,
    checkIsExistOmniSRT,
    handleUpdateCodecType,
    isUserFreeTrial,
    isUserBasic,
    isUserLiveOmni,
    isUserGuest
  ) => {
    this.setState({
      exportsItemId,
      handleDelete,
      handleHideModal,
      exportButtonRole,
      exportList,
      fetchItems,
      deleteLabelItem,
      setShowEditModal,
      exportName,
      labelValue,
      handleCopyField,
      showExportModal,
      videoUrl,
      checkIsExistOmniSRT,
      handleUpdateCodecType,
      isUserFreeTrial,
      isUserBasic,
      isUserLiveOmni,
      isUserGuest,
    });
  };

  render() {
    const {
      broadcastDetailsJsonObject,
      isWebSocketConnected,
      primaryUsers,
      arePrimaryUsersLoading,
      outputs,
      areOutputsLoading,
      videoServerStatus,
      focusedPrimaryId,
      isChangingStartStopInProgress,
      primariesBroadcastDetails,
      isUserFreeTrial,
      isUserBasic,
      isUserLiveOmni,
      isUserGuest,
      broadcastProperties,
      focusedPrimaryBroadcastSettings,
      arePrimaryBroadcastSettingsLoading,
      arePrimaryRecordsLoading,
      focusedPrimayBroadcastDetails,
      focusedPrimayDevicesSetInfo,
      isDevicesSetsInfoLoading,
      focusedPrimaryVideoAlarms,
      focusedPrimaryLogs,
      isBitrateChartDisplayed,
      screenSize,
      companyType,
      alertDialogType,
      primaryRecordsDetails,
      handlePrimaryFocus,
      setDisplayedName,
      clickStartStop,
      clickIFBmouseDown,
      clickIFBmouseUp,
      clickSettingsApply,
      clickLogsClear,
      handleStartRecordUpload,
      handlePauseRecordUpload,
      handleDeleteRecord,
      showNotification,
      onAudioSelect,
      onAttachPrimary,
      onDettachPrimary,
      audioDevices,
      selectedDevice,
      isAudioChannelOpen,
      setFetchExportListFn,
      dashboardCompanyName,
    } = this.props;

    const {
      typeForModal,
      idForModal,
      nameForModal,
      displayedNameForModal,
      isDesignatedOutputForModal,
      uuidForModal,
      isSlidingPanelDisplayed,
      recordNameForModal,
      handleHideModal,
      exportsItemId,
      handleDelete,
      showExportModal,
      exportButtonRole,
      exportList,
      fetchItems,
      deleteLabelItem,
      setShowEditModal,
      exportName,
      labelValue,
      handleCopyField,
      videoUrl,
      checkIsExistOmniSRT,
      handleUpdateCodecType,
    } = this.state;

    let primaryName = '';
    let isFocusedPrimaryBroadcasting = undefined;
    let focusedPrimarySocialMediaSettings = {};

    if (focusedPrimaryId) {
      const focusedPrimaryObj = primaryUsers.find(
        (obj) => obj.id === focusedPrimaryId
      );

      if (!focusedPrimaryObj) {
        handlePrimaryFocus(primaryUsers[0].id)
      }

      if (focusedPrimaryObj) {
        primaryName = (focusedPrimaryObj.displayedName || focusedPrimaryObj.username);

        isFocusedPrimaryBroadcasting = focusedPrimaryObj.isBroadcasting;
        focusedPrimarySocialMediaSettings.type = focusedPrimaryObj.type;
        focusedPrimarySocialMediaSettings.url = focusedPrimaryObj.url;
      }
    }

    return (
      <div className="dashboard">
        <MultiViewer
          isWebSocketConnected={isWebSocketConnected}
          primaryUsers={primaryUsers}
          arePrimaryUsersLoading={arePrimaryUsersLoading}
          videoServerStatus={videoServerStatus}
          focusedPrimaryId={focusedPrimaryId}
          primariesBroadcastDetails={broadcastProperties}
          screenSize={screenSize}
          handlePrimaryFocus={handlePrimaryFocus}
          setDraggedElement={this.setDraggedElement}
          handleDrop={this.handleDrop}
          setDetailsForModal={this.setDetailsForModal}
          onAttachPrimary={onAttachPrimary}
          onDettachPrimary={onDettachPrimary}
          audioDevices={audioDevices}
          selectedDevice={selectedDevice}
          companyType={companyType}
          isPrimaryBroadcasting={isFocusedPrimaryBroadcasting}
          streamType={focusedPrimaryBroadcastSettings.streamType}
          clickStartStop={clickStartStop}
          isChangingStartStopInProgress={isChangingStartStopInProgress}
          showNotification={showNotification}
          setShareId={this.setShareId}
          setPrimaryToDelete={this.setPrimaryToDelete}
        />

        <OutputsList
          primaryUsers={primaryUsers.map(({ id, isBroadcasting }) => {
            return { id, isBroadcasting };
          })}
          outputs={outputs}
          areOutputsLoading={areOutputsLoading}
          videoServerStatus={videoServerStatus}
          focusedPrimaryId={focusedPrimaryId}
          screenSize={screenSize}
          setDraggedElement={this.setDraggedElement}
          handleDrop={this.handleDrop}
          companyType={companyType}
          setDetailsForModal={this.setDetailsForModal}
          handleGuidCopyIconClick={this.handleGuidCopyIconClick}
        />

        <div
          className={`container-fluid sliding-panel sliding-panel__${isSlidingPanelDisplayed || companyType != 0
            ? companyType === 0
              ? 'show'
              : 'show_simple'
            : 'hide'
            }`}
        >
          {companyType === 0 && (
            <div className="d-flex control-panel">
              <BroadcastDetailsBar
                focusedPrimaryId={parseInt(focusedPrimaryId)}
                isChangingStartStopInProgress={isChangingStartStopInProgress}
                isPrimaryBroadcasting={isFocusedPrimaryBroadcasting}
                isSlidingPanelDisplayed={isSlidingPanelDisplayed}
                streamType={focusedPrimaryBroadcastSettings.streamType}
                screenSize={screenSize}
                clickStartStop={clickStartStop}
                clickIFBmouseDown={clickIFBmouseDown}
                clickIFBmouseUp={clickIFBmouseUp}
                handleSlidingPanelToggle={this.handleSlidingPanelToggle}
                primaryUsers={primaryUsers}
                onAudioSelect={onAudioSelect}
                audioDevices={audioDevices}
                selectedDevice={selectedDevice}
                isAudioChannelOpen={isAudioChannelOpen}
                companyType={companyType}
              />
            </div>
          )}
          <BroadcastDetails
            broadcastDetailsJsonObject={broadcastDetailsJsonObject}
            isWebSocketConnected={isWebSocketConnected}
            outputs={outputs.map(({ id, name, uuid, primaryId }) => {
              return { id, name, uuid, primaryId };
            })}
            focusedPrimaryId={focusedPrimaryId}
            isChangingStartStopInProgress={isChangingStartStopInProgress}
            broadcastSettings={focusedPrimaryBroadcastSettings}
            areBroadcastSettingsLoading={arePrimaryBroadcastSettingsLoading}
            primaryUsers={primaryUsers}
            arePrimaryRecordsLoading={arePrimaryRecordsLoading}
            primaryName={primaryName}
            isPrimaryBroadcasting={isFocusedPrimaryBroadcasting}
            socialMediaSettings={focusedPrimarySocialMediaSettings}
            broadcastDetails={focusedPrimayBroadcastDetails}
            primaryRecords={primaryRecordsDetails}
            devicesSetInfo={focusedPrimayDevicesSetInfo}
            isDevicesSetsInfoLoading={isDevicesSetsInfoLoading}
            alarmsVideo={focusedPrimaryVideoAlarms}
            logs={focusedPrimaryLogs}
            isBitrateChartDisplayed={
              isBitrateChartDisplayed && isSlidingPanelDisplayed
            }
            screenSize={screenSize}
            clickStartStop={clickStartStop}
            clickIFBmouseDown={clickIFBmouseDown}
            clickIFBmouseUp={clickIFBmouseUp}
            clickSettingsApply={clickSettingsApply}
            clickLogsClear={clickLogsClear}
            associatePrimaryWithOutput={this.associatePrimaryWithOutput}
            handleStartRecordUpload={handleStartRecordUpload}
            handlePauseRecordUpload={handlePauseRecordUpload}
            setDetailsForDeleteRecordModal={this.setDetailsForDeleteRecordModal}
            showNotification={showNotification}
            companyType={companyType}
            setExportModalData={this.setExportModalData}
            isUserFreeTrial={isUserFreeTrial}
            isUserBasic={isUserBasic}
            isUserLiveOmni={isUserLiveOmni}
            isUserGuest={isUserGuest}
            setFetchExportListFn={setFetchExportListFn}
          />
        </div>

        <SettingsModal
          type={typeForModal}
          id={idForModal}
          name={nameForModal}
          displayedName={displayedNameForModal}
          isDesignatedOutput={isDesignatedOutputForModal}
          uuid={uuidForModal}
          setDisplayedName={setDisplayedName}
          handleGuidCopyIconClick={this.handleGuidCopyIconClick}
          setDesignatedOutput={this.setDesignatedOutput}
        />

        {this.alertDialogs[alertDialogType] && (
          <Modal
            uniqueId="dialog-alert-modal"
            headerTitle={this.alertDialogs[alertDialogType].title}
            buttons={this.alertDialogs[alertDialogType].buttons}
            bodyClass="modal-body modal-body-alert"
          >
            {this.alertDialogs[alertDialogType].description}
          </Modal>
        )}

        <Modal
          uniqueId="delete-record-modal"
          headerTitle="Delete record"
          bodyClass="modal-body modal-body-alert"
          buttons={[
            {
              name: 'Yes',
              className: 'danger danger-submit-btn',
              clickHandler: () => handleDeleteRecord(recordNameForModal),
            },
            {
              name: 'No',
              className: 'danger danger-submit-btn',
              clickHandler: () => this.props.onAlertDialogClick(false),
            },
          ]}
        >
          Are you sure you want to delete record "{recordNameForModal}"?
          <br></br>* Recorded file will be deleted from primary "{primaryName}".
        </Modal>

        <ShareModal id={this.state.shareId} showNotification={showNotification} dashboardCompanyName={dashboardCompanyName} />

        <DeletePrimaryModal id={this.state.primaryToDeleteById} primaryName={this.state.primaryToDeleteByName} showNotification={showNotification} />

        {showExportModal && (
          <ExportModal
            handleHideModal={handleHideModal}
            exportsItemId={exportsItemId}
            handleDelete={handleDelete}
            exportButtonRole={exportButtonRole}
            exportList={exportList}
            showNotification={showNotification}
            focusedPrimaryId={focusedPrimaryId}
            fetchItems={fetchItems}
            deleteLabelItem={deleteLabelItem}
            setShowEditModal={setShowEditModal}
            exportName={exportName}
            labelValue={labelValue}
            handleCopyField={handleCopyField}
            primaryName={primaryName}
            videoUrl={videoUrl}
            checkIsExistOmniSRT={checkIsExistOmniSRT}
            handleUpdateCodecType={handleUpdateCodecType}
            primaryUsers={primaryUsers.map(({ id, isActive }) => {
              return { id, isActive };
            })}
            isUserFreeTrial={isUserFreeTrial}
            isUserBasic={isUserBasic}
            isUserLiveOmni={isUserLiveOmni}
            isUserGuest={isUserGuest}
          />
        )}
      </div>
    );
  }
}

Dashboard.propTypes = {
  primaryUsers: PropTypes.array.isRequired,
  outputs: PropTypes.array.isRequired,
  focusedPrimaryId: PropTypes.number,
  focusedPrimaryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  isBitrateChartDisplayed: PropTypes.bool.isRequired,
  screenSize: PropTypes.string,
  showNotification: PropTypes.func.isRequired,
  isAlertDialogDisplayed: PropTypes.bool.isRequired,
  alertDialogType: PropTypes.string,
  onAlertDialogClick: PropTypes.func.isRequired,
};

export default Dashboard;
