export const steps = [
  {
    title: 'Guest\'s Details',
    subTitle: 'Please fill in your guest information',
    hint: '* Guest\'s details are not stored and will only be used for the invitation',
  },
  {
    title: 'Stream Settings',
    subTitle: 'Please configure the Stream setting using the form below',
  },
  {
    title: 'Export Configuration',
    subTitle: 'Please configure the destination you would like to stream to',
  },
  {
    title: 'Your guest is ready to be invited!',
    subTitle: 'Your guest has been successfully created and is ready for an invitation.',
  },
];