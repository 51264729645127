import React from 'react';
import PropTypes from 'prop-types';
import "./ProvidersChart.css";
import { Chart } from "react-google-charts";
import config from '../../dashboard_config.json';

const { generalColors } = config.dashboard.charts.colors;
const { maxLegendItemsPerColumn } = config.dashboard.charts.layout.pieChartLayout;

class ProvidersChart extends React.Component {
	state = {
		data: [["Name", "Load"]],
		showLegendToolTip: false,
	};

	componentDidMount() {
		this.clearChartUrl();
	}

	componentDidUpdate(prevProps) {
		const {
			isPrimaryBroadcasting,
			focusedPrimaryId,
			broadcastDetailsJsonObject,
			isProviderChartDisplayed
		} = this.props;

		if (!isPrimaryBroadcasting && prevProps.isPrimaryBroadcasting) {
			this.clearChartUrl();
		} else if (isProviderChartDisplayed) {
			if (focusedPrimaryId !== prevProps.focusedPrimaryId) {
				this.clearChartUrl();
			} else if (broadcastDetailsJsonObject !== undefined &&
				broadcastDetailsJsonObject["primaryId"] === focusedPrimaryId && isPrimaryBroadcasting === true) {
				this.createChartUrl(broadcastDetailsJsonObject);
				this.updateShowLegendToolTip();
				this.adjustLegendLayout();
			}
		}
	}

	componentWillUnmount() {
		this.clearChartUrl();
	}

	updateShowLegendToolTip = () => {
		const legendItems = document.querySelectorAll('.legend-item');
		const shouldShowTooltip = legendItems.length > maxLegendItemsPerColumn;

		if (this.state.showLegendToolTip !== shouldShowTooltip) {
			this.setState({ showLegendToolTip: shouldShowTooltip });

		}
	};

	adjustLegendLayout = () => {
		const legendItems = document.querySelectorAll('.legend-item');
		const customLegend = document.querySelector('.custom-legend');

		if (customLegend) {
			if (legendItems.length <= maxLegendItemsPerColumn) {
				customLegend.classList.add('single-column');
			} else {
				customLegend.classList.remove('single-column');
			}
		}
	};

	clearChartUrl() {
		if (this.state.data.length > 1) {
			this.setState({ data: [["Name", "Load"]] });
		}
	}

	createChartUrl(broadcastDetailsJsonObject) {
		let broadcastOperatorsJsonArray = broadcastDetailsJsonObject["providers"];
		let totalDeliveredPackets = broadcastDetailsJsonObject["totalDeliveredPackets"];
		if (broadcastOperatorsJsonArray === undefined) {
			this.clearChartUrl();
		} else {
			let data = [["Name", "Load"]];
			for (let index = 0; index < broadcastOperatorsJsonArray.length; index++) {
				let broadcastOperatorJsonObject = broadcastOperatorsJsonArray[index];
				let deliveredPackets = broadcastOperatorJsonObject["deliveredPackets"];
				let chartPortionParam = Math.round((deliveredPackets * 100) / totalDeliveredPackets);
				let chartNamesParam = broadcastOperatorJsonObject["providerName"];
				data.push([chartNamesParam, chartPortionParam]);

			}
			if (data.length > 1) {
				this.state.data = data;
			}
		}
	}

	render() {
		const { data } = this.state;
		const { broadcastDetails } = this.props;

		if (!broadcastDetails || !broadcastDetails.operatorsDataArr) {
			return <div className="no-data-message">No Data Available</div>;
		}

		const legendData = broadcastDetails.operatorsDataArr.map((operator, index) => {
			const latestBitrate = parseFloat(operator.operatorData[operator.operatorData.length - 1]) || 0;

			return {
				name: operator.operatorName,
				color: generalColors[index],
				bitrate: latestBitrate.toFixed(2)
			};
		});

		const totalBitrate = legendData.reduce((sum, item) => sum + parseFloat(item.bitrate), 0).toFixed(2);

		const options = {
			// is3D: true,
			backgroundColor: 'transparent',
			legend: { position: 'none' },
			tooltip: { trigger: 'none' },
			// tooltip: { showColorCode: true },
			chartArea: {
				left: 0,
				top: 45,
				width: "100%",
				height: "75%"
			},
			slices: generalColors.slice(0, data.length - 1).map(color => ({ color, offset: 0.020 })),
			pieSliceBorderColor: "#343434",
			pieSliceText: 'percentage',
			pieSliceTextStyle: { color: "#F4EFE9", fontSize: 15 },
		};

		return (
			<div className="chart-container">
				{data.length > 1 ? (
					<div className="chart-and-legend">
						<Chart
							chartType="PieChart"
							data={this.state.data}
							options={options}
							graph_id="PieChart"
							width={"100%"}
							height={310}
							legend_toggle
						/>
						<div className="custom-legend">
							{legendData.map((item, index) => (
								<div key={index} className="legend-item"
									title={this.state.showLegendToolTip ? `${item.name} - ${item.bitrate} Mb/s` : undefined}>
									<span className="legend-square" style={{ backgroundColor: item.color }}></span>
									<span className="legend-text">{item.name} -</span>
									<span className="legend-bitrate">{item.bitrate} Mb/s</span>
								</div>
							))}
							<div className="measured-bitrate">
								<strong>Measured Bitrate: {totalBitrate} Mb/s</strong>
							</div>
						</div>
					</div>
				) : (
					<div className="no-data-message">No Data Available</div>
				)}
			</div>
		);
	}

}

ProvidersChart.propTypes = {
	screenSize: PropTypes.string.isRequired,
	isProviderChartDisplayed: PropTypes.bool.isRequired,
	broadcastDetails: PropTypes.object.isRequired,
};

export default ProvidersChart;
