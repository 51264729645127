import React from 'react';
import PropTypes from 'prop-types';

import './OutputsList.css';

import Spinner from '../common/Spinner/Spinner';
import Output from '../Output/Output';
import CriticalIssueContainer from '../CriticalIssueContainer/CriticalIssueContainer';

const OutputsList = ({
	primaryUsers,
	outputs,
	areOutputsLoading,
	videoServerStatus,
	focusedPrimaryId,
	screenSize,
	setDraggedElement,
	handleDrop,
	setDetailsForModal,
	handleGuidCopyIconClick,
	companyType
}) => {
	let issueElementToDisplay = null;
	let sortedOutputs;

	if (videoServerStatus.generalStatus === 'ERROR') {
		issueElementToDisplay = (
			<CriticalIssueContainer>
				{videoServerStatus.errors &&
					videoServerStatus.errors[0].Description}
			</CriticalIssueContainer>
		);
	} else if (areOutputsLoading === true) {
		issueElementToDisplay = <Spinner customClass="outputs-list-spinner" />;
	} else {
		sortedOutputs = outputs.sort((obj1, obj2) => obj1.id - obj2.id);
	}

	return (
		<div className="outputs-list container-fluid pt-3 outputs-scrollbar">
			<div className="row">
				{issueElementToDisplay ? (
					issueElementToDisplay
				) : (
					sortedOutputs.map(
						({
							primaryId,
							id,
							name,
							displayedName,
							uuid,
							isActive,
							isDesignated,
							generalStatus,
							statuses
						}) => {
							const associatedPrimaryUser = primaryUsers.find(
								(primaryUser) => primaryUser.id === primaryId
							);
							return (
								<Output
									key={id}
									outputId={id}
									name={name}
									displayedName={displayedName}
									uuid={uuid}
									isActive={isActive}
									isDesignated={isDesignated}
									associatedPrimaryId={primaryId}
									isAssociatedPrimaryBroadcasting={
										associatedPrimaryUser?
										associatedPrimaryUser.isBroadcasting : false
									}
									generalStatus={generalStatus}
									statuses={statuses}
									focusedPrimaryId={focusedPrimaryId}
									screenSize={screenSize}
									setDraggedElement={setDraggedElement}
									handleDrop={handleDrop}
									setDetailsForModal={setDetailsForModal}
									handleGuidCopyIconClick={handleGuidCopyIconClick}
									companyType={companyType}
								/>
							);
						}
					)
				)}
			</div>
		</div>
	);
};

OutputsList.propTypes = {
	primaryUsers: PropTypes.array.isRequired,
	outputs: PropTypes.array.isRequired,
	areOutputsLoading: PropTypes.bool.isRequired,
	videoServerStatus: PropTypes.object.isRequired
};

export default React.memo(OutputsList);
