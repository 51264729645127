import React from 'react';
import PropTypes from 'prop-types';

import './DevicesTableRow.css';

const DevicesTableRow = ({
	isActive,
	isPrimary,
	deviceName,
	batteryLevel,
	isCharging,
	batteryTemperature,
	providerName,
	networkType,
	signalStrength
}) => {
	let percentageClass;
	let bsColor;

	if (isActive === true) {
		if (batteryLevel >= 40) {
			percentageClass = 'success';
		} else if (batteryLevel >= 20) {
			percentageClass = 'warning';
		} else {
			percentageClass = 'danger';
		}

		if (signalStrength >= 3) {
			bsColor = 'success';
		} else if (signalStrength >= 2) {
			bsColor = 'warning';
		} else {
			bsColor = 'danger';
		}
	} else {
		percentageClass = 'secondary';
		bsColor = 'secondary';
	}

	let barsArr = [];

	for (let i = 1; i <= 4; i++) {
		const calculatedClassName =
			i <= signalStrength
				? `badge badge-${bsColor}`
				: `badge badge-light border border-${bsColor}`;

		barsArr.push(
			<span key={i} className={`${calculatedClassName} mr-1`}>
				<span className={`signal-bars-${i}`}>.</span>
			</span>
		);
	}

	return (
		<tr>
			<td className="text-font text-center align-middle txt-color">
				{deviceName ? deviceName : 'N/A'}
				{isPrimary && (
					<div>
						<span className="badge badge-secondary">Primary</span>
					</div>
				)}
			</td>

			<td className="text-font text-center align-middle txt-color">
				{batteryLevel ? (
					<div>
						<div className="d-flex justify-content-center align-items-center mb-1 txt-color">
							{batteryLevel + '%'}
							{isCharging && (
								<i className="material-icons">battery_charging_full</i>
							)}
						</div>
						<div className="progress">
							<div
								className={`progress-bar bg-${isActive
									? 'secondary'
									: 'white'} border`}
								style={{ width: 100 - batteryLevel + '%' }}
							/>
							<div
								className={`progress-bar bg-${percentageClass}`}
								style={{ width: batteryLevel + '%' }}
							/>
						</div>
					</div>
				) : (
					'N/A'
				)}
			</td>

			<td className="text-font text-center align-middle txt-color">
				{batteryTemperature ? (
					<span
						className={`badge temp-${isActive ? (batteryTemperature>41 ? 'critical' : (batteryTemperature>37 ? 'warn' : 'cool')) : 'background'}`}
					>
						{batteryTemperature}&#8451;
					</span>
				) : (
					'N/A'
				)}
			</td>

			<td className="text-font text-center align-middle txt-color">
				{providerName ? providerName : 'N/A'}
			</td>

			<td className="text-font text-center align-middle txt-color">
				{networkType ? networkType : 'N/A'}
			</td>

			<td className="text-font signal-strength-td text-center align-middle txt-color">
				{signalStrength ? (
					<div className="d-flex justify-content-center align-items-end">
						{barsArr}
					</div>
				) : (
					'N/A'
				)}
			</td>
		</tr>
	);
};


export default React.memo(DevicesTableRow);
