import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../common/Modal/Modal';
import RadioButton from '../common/RadioButton/RadioButton';

class IfbModal extends React.PureComponent {
	handleModalRadioButtonChange = (e) => {
		this.props.changeIFBbytesPerSample(parseInt(e.target.value));
	};

	radioBtn1Text = (
		<span>
			Change the audio to be <span className="font-weight-bold">
				higher
			</span>{' '}
			in pitch and play <span className="font-weight-bold">faster</span>
		</span>
	);

	radioBtn2Text = (
		<span>
			Change the audio to be <span className="font-weight-bold">
				lower
			</span>{' '}
			in pitch and play <span className="font-weight-bold">slower</span>
		</span>
	);

	render() {
		return (
			<Modal
				uniqueId="ifb-modal"
				headerTitle="In case you experience issues with IFB audio, please choose from below:"
				buttons={[{name: 'Save', className: 'info'}]}
			>
				<RadioButton
					radioGroupName="ifb-options"
					radioId="option1"
					radioValue="4"
					changeHandler={this.handleModalRadioButtonChange}
					labelText={this.radioBtn1Text}
				/>
				<RadioButton
					radioGroupName="ifb-options"
					radioId="option2"
					radioValue="2"
					changeHandler={this.handleModalRadioButtonChange}
					labelText={this.radioBtn2Text}
				/>
			</Modal>
		);
	}
}

IfbModal.propTypes = {
	changeIFBbytesPerSample: PropTypes.func.isRequired
};

export default IfbModal;
