import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import s from './GuestModeModal.module.css';
import '../../index.css';
import axios from 'axios';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { GUESTS_API } from '../../../../constants/endPointsURLs';
import { Formik, Form } from 'formik';
import validationSchema from '../../form/model/validationSchema';
import initialValues from '../../form/model/initialValues';
import { GuestInfo } from '../../form/steps/GuestInfo/GuestInfo';
import guestFormModel from '../../form/model/guestFormModel';
import { steps } from '../../steps.config';
import { GuestModalStepper } from '../GuestModalStepper/GuestModalStepper';
import { StreamInfo } from '../../form/steps/StreamInfo/StreamInfo';
import { ExportConfig } from '../../form/steps/ExportConfig/ExportConfig';
import { InviteGuest } from '../../form/steps/InviteGuest/InviteGuest';
import { exportTypeMapping } from '../../../Export/ExportTypeMapping';
import { data } from '../../form/steps/ExportConfig/data';
import { Modal } from 'reactstrap';
import config from '../../../../dashboard_config.json';

export const GuestModeModal = ({
    guestModeModalState,
    setGuestModeModalState,
    filteredOutputs,
    guestsMax,
    showNotification,
    dashboardCompanyName,
    handlePrimaryFocus,
    primaryUsers,
    focusedPrimary,
    getControllerInfo,
    fetchExportList,
    setDisplayedName,
}) => {
    const { open } = guestModeModalState;
    const [selectedStep, setSelectedStep] = useState(0);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [disable, setDisable] = useState(true);
    const [response, setResponse] = useState({});
    const [lastPrimaryId, setLastPrimaryId] = useState(null);
    const focusedPrimaryId = focusedPrimary ? focusedPrimary.primaryId : null;
    const [isModalClosed, setIsModalClosed] = useState(false);
    const [exportForm, setExportForm] = useState({
        rtmpHost: '',
        srtHost: '',
        key: '',
        port: '',
        id: '',
        passphrase: '',
    });

    const resetForm = () => {
        setExportForm({
            rtmpHost: '',
            srtHost: '',
            key: '',
            port: '',
            id: '',
            passphrase: '',
        });
    };

    useEffect(() => {
        if (lastPrimaryId && lastPrimaryId !== focusedPrimaryId) {
            if (primaryUsers.find(({ id }) => id === lastPrimaryId)) {
                handlePrimaryFocus(lastPrimaryId);
                setLastPrimaryId(null);
            }
        }
    }, [handlePrimaryFocus, primaryUsers, focusedPrimaryId, lastPrimaryId]);

    useEffect(() => {
        if (open) {
            formikRef.current && formikRef.current.resetForm();
            setSelectedStep(0);
            setIsSubmitting(false);
            resetForm();
        }
    }, [open]);

    const currentValidationSchema = validationSchema[selectedStep];
    const { formField, formId } = guestFormModel;
    const isLastStep = selectedStep === steps.length - 1;
    const formikRef = useRef(null);
    const { title, subTitle, hint } = steps[selectedStep];

    const handleCreateGuest = async (data) => {
        try {
            const response = await axios.post(GUESTS_API.createGuest(), data, { withCredentials: true });
            return response;
        } catch (error) {
            console.error('Error:', error)
            const { response } = error;
            return response;
        }
    };

    const onNextStep = useCallback(() => {
        const currentStep = selectedStep + 1;
        if (currentStep === steps.length) return;
        setSelectedStep(currentStep);
    }, [steps, selectedStep, setSelectedStep]);

    const onPrevStep = useCallback(() => {
        const currentStep = selectedStep - 1;
        if (currentStep < 0) return;
        setSelectedStep(currentStep);
    }, [steps, selectedStep, setSelectedStep]);

    // Define guest primary and output custom naming logic with maximum length check
    const handleGuestCustomName = (type, primaryName, outputName, firstName, lastName) => {

        const {
            enableGuestCustomNaming,
            customPrimaryNameMaxLength,
            customOutputNameMaxLength
        } = config.dashboard.guests.guestCustomNaming;

        if (!enableGuestCustomNaming) return null;

        const maxLength = type === 'Primary' ? customPrimaryNameMaxLength : customOutputNameMaxLength;

        let customName = type === 'Primary'
            ? `${primaryName} - (${firstName} ${lastName})`
            : `${outputName} - (${firstName} ${lastName})`;

        // console.log(`Initial customName (${type}): "${customName}" Length: ${customName.length}`);

        if (customName.length > maxLength) {
            customName = type === 'Primary'
                ? `${primaryName} - (${firstName})`
                : `${outputName} - (${firstName})`;

            // console.log(`Adjusted customName (${type}): "${customName}" Length: ${customName.length}`);

            if (customName.length > maxLength) {
                customName = null;
                // console.log(`CustomName still too long (${type}). Setting to null.`);
            }
        }

        return customName;
    };

    const handleParseRtmpURL = (url) => {
        const regex = /^(rtmps?):\/\/([^:\/]*)(?::(\d+))?(\/.*)?$/;
        try {
            const matches = url.match(regex);
            if (!matches[1]) {
                throw new Error('Invalid URL format');
            }

            const protocol = matches[1];
            const host = matches[2];
            const port = matches[3] ? Number(matches[3]) : null;
            const endpoint = matches[4]
                ? matches[4]
                    .slice(1)
                    .split('/')
                    .filter((part) => part)
                    .join('/')
                : null;

            return { protocol, host, port, endpoint };
        } catch (error) {
            console.error('Error parsing RTMP URL:', error);
            return null;
        }
    };

    const getExportNameByType = (type) => {
        return exportTypeMapping[type] || 'Unknown Export Type';
    }

    const showSuccessExportMessage = (exportTypeById) => {
        const exportName = getExportNameByType(exportTypeById);
        showNotification('export', `${exportName} - Successfully created`);
    }

    const showErrorExportMessage = () => {
        showNotification('export', 'An error occurred while creating the export');
    }

    const sendPostRequest = async (url, data = {}) => {
        try {
            const response = await axios.post(url, data, { withCredentials: true });
            if (response.status === 201) {
                showSuccessExportMessage(response.data.entry.type);
            } else {
                showErrorExportMessage();
            }
            return response;
        } catch (error) {
            const { response } = error;
            showNotification('export', response.data.message || response.data.error);
            return response;
        }
    };

    const handleSendData = async (cbUrl, id) => {
        try {
            const currentGuestPrimaryId = response.primaryId;
            const url = cbUrl(currentGuestPrimaryId);

            if (id === 1 || id === 2) {
                return await sendPostRequest(url);
            }

            if (id === 3) {
                const parsedRtmpData = handleParseRtmpURL(exportForm.rtmpHost);
                if (!parsedRtmpData) {
                    showNotification('url', 'Invalid RTMP URL');
                    return null;
                }
                const { protocol, host, port, endpoint } = parsedRtmpData;
                const data = {
                    protocol,
                    host,
                    port,
                    endpoint,
                    key: exportForm.key,
                    src: 0,
                    type: 0,
                    isEnabled: true,
                };
                return await sendPostRequest(url, data);
            }

            if (id === 4) {
                const data = {
                    host: exportForm.srtHost,
                    port: Number(exportForm.port),
                    key: exportForm.passphrase,
                    exportStreamId: exportForm.id,
                    protocol: 'srt',
                    src: 0,
                    type: 1,
                    isEnabled: true,
                };
                return await sendPostRequest(url, data);
            }

            if (id === 5) {
                const data = {
                    protocol: 'srt',
                    src: 0,
                    type: 2,
                    isEnabled: true,
                };
                return await sendPostRequest(url, data);
            }

            // Log unexpected id values
            console.error('Unexpected id value:', id);
            showErrorExportMessage();
            return null;

        } catch (error) {
            const { response } = error;
            showNotification('export', response.data.message || response.data.error);
            return response;
        }
    };

    const handleSkip = () => {
        onNextStep();
    };

    const renderForm = useCallback(() => {
        switch (selectedStep) {
            case 0:
                return <GuestInfo formField={formField} isModalClosed={isModalClosed} />;
            case 1:
                return <StreamInfo filteredOutputs={filteredOutputs} formField={formField} />;
            case 2:
                return (
                    <ExportConfig
                        setDisable={setDisable}
                        selectedId={selectedId}
                        setSelectedId={setSelectedId}
                        exportForm={exportForm}
                        setExportForm={setExportForm}
                    />
                );
            case 3:
                return (
                    <InviteGuest
                        showNotification={showNotification}
                        data={response}
                        dashboardCompanyName={dashboardCompanyName}
                        title={title}
                        subTitle={subTitle}
                    />
                );
            default:
                return <div>Not Found</div>;
        }
    }, [
        selectedStep,
        selectedId,
        setSelectedId,
        setDisable,
        formField,
        filteredOutputs,
        showNotification,
        exportForm,
        response,
        title,
        subTitle,
    ]);

    const submitForm = async (values, actions) => {
        actions.setTouched({});
        actions.setSubmitting(false);
        setDisable(true);
        setIsSubmitting(false);
        handleModalClose();
    };

    const onModalClose = () => setGuestModeModalState({ open: false });

    const handleParsedPhoneNumber = (phoneNumber, countryCode) => {
        const parsedNumber = parsePhoneNumberFromString(phoneNumber, countryCode.toUpperCase());
        if (parsedNumber && parsedNumber.isValid()) {
            return parsedNumber.number;
        } else {
            console.log('Failed to parse phone number');
            return phoneNumber;
        }
    };

    const handleSubmit = async (values, actions) => {
        if (selectedStep === 1) {
            setIsSubmitting(true);

            const { broadcastOutputId, countryCode, phoneNumber, email, ...rest } = values;
            const formattedPhoneNumber = handleParsedPhoneNumber(phoneNumber, countryCode);
            const payload = {
                ...rest,
                ...(broadcastOutputId !== 'None' && { broadcastOutputId }),
                email: email || null,
                phoneNumber: formattedPhoneNumber,
            };

            try {
                const res = await handleCreateGuest(payload);

                if (res.status === 200) {
                    const { primaryId, primaryName, outputId, outputName, firstName, lastName } = res.data;

                    // Set custom name for guest primary name
                    const customGuestPrimaryName = handleGuestCustomName('Primary', primaryName, null, firstName, lastName);
                    setDisplayedName('Primary', primaryId, customGuestPrimaryName);
                    // Set custom name for guest output name
                    const customGuestOutputName = handleGuestCustomName('Output', null, outputName, firstName, lastName);
                    setDisplayedName('Output', outputId, customGuestOutputName);

                    getControllerInfo();
                    setLastPrimaryId(primaryId);
                    setResponse(res.data);
                    onNextStep();
                    actions.setTouched({});
                    actions.setSubmitting(false);
                    return;
                }

                if (res.status === 500) {
                    showNotification('error', res.data.message);
                    console.error('Server error:', res.data);
                    return;
                }

            } catch (error) {
                console.error('Error creating guest:', error);
            } finally {
                setIsSubmitting(false);
            }
        } else if (selectedStep === 2) {
            const selectedItem = data.find((item) => item.id === selectedId);

            if (selectedItem.id === 3) {
                if (!exportForm.rtmpHost) {
                    showNotification('url', 'RTMP URL is required');
                    actions.setSubmitting(false);
                    return;
                } else if (!exportForm.rtmpHost.startsWith('rtmp://') && !exportForm.rtmpHost.startsWith('rtmps://')) {
                    showNotification('url', "Invalid url - Must start with 'rtmp://' or 'rtmps://'");
                    actions.setSubmitting(false);
                    return;
                }
            } else if (selectedItem.id === 4) {
                const hostnameRegex = /^(?!:\/\/)([a-zA-Z0-9-_]{1,63}\.)+[a-zA-Z]{2,6}$/;
                const ipv4Regex = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

                const ipv6Regex = /^(([0-9a-fA-F]{1,4}:){7}([0-9a-fA-F]{1,4}|:))|(([0-9a-fA-F]{1,4}:){1,7}:)|(([0-9a-fA-F]{1,4}:){1,6}(:[0-9a-fA-F]{1,4}|:))|(([0-9a-fA-F]{1,4}:){1,5}(:([0-9a-fA-F]{1,4}|:)){1,2})|(([0-9a-fA-F]{1,4}:){1,4}(:([0-9a-fA-F]{1,4}|:)){1,3})|(([0-9a-fA-F]{1,4}:){1,3}(:([0-9a-fA-F]{1,4}|:)){1,4})|(([0-9a-fA-F]{1,4}:){1,2}(:([0-9a-fA-F]{1,4}|:)){1,5})|([0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}|:)){1,6})|(:((:[0-9a-fA-F]{1,4}|:)){1,7}|:)$/;

                const isHostname = hostnameRegex.test(exportForm.srtHost);
                const isIPv4 = ipv4Regex.test(exportForm.srtHost);
                const isIPv6 = ipv6Regex.test(exportForm.srtHost);

                const portNumber = parseInt(exportForm.port, 10);
                const isValidPort = portNumber >= 1 && portNumber <= 65535;

                if (!exportForm.srtHost && !exportForm.port) {
                    showNotification('url', 'Host and Port are required');
                    actions.setSubmitting(false);
                    return;
                }

                if (!exportForm.srtHost) {
                    showNotification('url', 'Host is required');
                    actions.setSubmitting(false);
                    return;
                } else if (!isHostname && !isIPv4 && !isIPv6) {
                    showNotification('url', 'Invalid Host - Must be a valid hostname or IP address');
                    actions.setSubmitting(false);
                    return;
                }

                if (!exportForm.port) {
                    showNotification('url', 'Port is required');
                    actions.setSubmitting(false);
                    return;
                } else if (!isValidPort) {
                    showNotification('url', 'Invalid Port - Must be between 1 and 65535');
                    actions.setSubmitting(false);
                    return;
                }
            }

            if (!selectedItem) return;
            const response = await handleSendData(selectedItem.cbUrl, selectedItem.id);
            if (response.status >= 400) return;
            if (response.status === 201) {
                onNextStep();
                fetchExportList(true, focusedPrimaryId);
                actions.setTouched({});
                actions.setSubmitting(false);
                setDisable(true);
                return;
            }
        } else if (isLastStep) {
            submitForm(values, actions);
        } else {
            onNextStep();
            actions.setTouched({});
            actions.setSubmitting(false);
            setDisable(true);
        }
    };

    const handleModalClose = () => {
        if (formikRef.current) {
            formikRef.current.resetForm();
            setSelectedStep(0);
            resetForm();
            setSelectedId(null);
            onModalClose();
        }
    };

    useEffect(() => {
        const handleModalHidden = () => {
            handleModalClose();
        };

        return () => {
            handleModalHidden();
        };
    }, []);

    return (
        <Modal isOpen={open} toggle={onModalClose} className={s.modal} centered>
            <div className={`${s.content_container} modal-content`}>
                {selectedStep !== 3 && (
                    <>
                        <div className={s.title}>{title}</div>
                        <div className={s.subtitle}>{subTitle}</div>
                        <div className={s.hint} style={{ visibility: hint ? 'inherit' : 'hidden' }}>
                            {hint || 'plug-text'}
                        </div>

                        <div className={s.stepper}>
                            <GuestModalStepper steps={steps} currentStep={selectedStep} />
                        </div>
                    </>
                )}

                <Formik
                    innerRef={formikRef}
                    initialValues={initialValues}
                    validationSchema={currentValidationSchema}
                    onSubmit={handleSubmit}
                >
                    <Form id={formId} className={s.formContainer} noValidate>
                        <div className={s.form}>{renderForm()}</div>

                        <div
                            className={s.buttons}
                            style={{
                                justifyContent:
                                    selectedStep === 0 || selectedStep === 2 || selectedStep === 3
                                        ? 'flex-end'
                                        : 'space-between',
                            }}
                        >
                            {selectedStep !== 0 && selectedStep !== 2 && selectedStep !== 3 && (
                                <button className="btn list-bottom-buttons-dark" type="button" onClick={onPrevStep}>
                                    <div className="list-bottom-buttons-text">Previous</div>
                                </button>
                            )}

                            <div>
                                {selectedStep === 2 && (
                                    <button
                                        className="btn list-bottom-buttons-dark"
                                        type="button"
                                        onClick={handleSkip}
                                    >
                                        <div className="list-bottom-buttons-text">Skip</div>
                                    </button>
                                )}

                                <button
                                    className="btn list-bottom-buttons"
                                    type="submit"
                                    disabled={isSubmitting || selectedStep === 2 ? disable : false}
                                >
                                    <div className="list-bottom-buttons-text">
                                        {selectedStep === 3 ? 'Done' : 'Next'}
                                    </div>
                                </button>
                            </div>
                        </div>
                    </Form>
                </Formik>
            </div>
        </Modal >
    );
};

GuestModeModal.propTypes = {
    uniqueId: PropTypes.string.isRequired,
    headerTitle: PropTypes.string,
    showExclamationIcon: PropTypes.bool,
    children: PropTypes.any.isRequired,
    buttons: PropTypes.arrayOf(
        PropTypes.exact({
            name: PropTypes.string.isRequired,
            className: PropTypes.string.isRequired,
            clickHandler: PropTypes.func,
        })
    ).isRequired,
};
