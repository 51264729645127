import React from 'react';
import PropTypes from 'prop-types';

import './DevicesInfo.css';

import Spinner from '../common/Spinner/Spinner';
import DevicesTableRow from '../DevicesTableRow/DevicesTableRow';

const DevicesInfo = ({ devicesSetInfo, isDevicesSetsInfoLoading }) => {
	let devicesSetInfoWithPrimaryFirst = [];
	devicesSetInfo.forEach((deviceInfo) => {
		if (deviceInfo.isPrimary === true) {
			devicesSetInfoWithPrimaryFirst.unshift(deviceInfo);
		} else {
			devicesSetInfoWithPrimaryFirst.push(deviceInfo);
		}
	});

	return (
		<div className="devices-info">
			{isDevicesSetsInfoLoading ? (
				<Spinner customClass="devices-info-spinner" />
			) : (
				<table
					className={`table table-striped${devicesSetInfo.length >=
					5
						? ' lower-rows'
						: ''}`}
				>
					<thead
						className={
							// devicesSetInfo.length > 0 ? 'thead-dark' : 'thead-light'
							devicesSetInfo.length > 0 ? 'thead-dark' : 'thead-dark'

						}
					>
						<tr>
							<th className="text-center align-middle device-name-column">
								Device
							</th>
							<th className="text-center align-middle battery-column">
								Battery
							</th>
							<th className="text-center align-middle temperature-column">
								Temperature
							</th>
							<th className="text-center align-middle provider-column">
								Provider
							</th>
							<th className="text-center align-middle network-type-column">
								Network
							</th>
							<th className="text-center align-middle signal-strength-column">
								Signal
							</th>
						</tr>
					</thead>
					<tbody>
						{devicesSetInfoWithPrimaryFirst.map((deviceInfo, i) => (
							<DevicesTableRow key={i} {...deviceInfo} />
						))}
					</tbody>
				</table>
			)}
		</div>
	);
};

DevicesInfo.propTypes = {
	devicesSetInfo: PropTypes.array.isRequired,
	isDevicesSetsInfoLoading: PropTypes.bool.isRequired
};

export default React.memo(DevicesInfo);
