import React from 'react';
import PropTypes from 'prop-types';

import './WarningMessage.css';

const WarningMessage = ({ uniqueId, show, text, isAlarm }) => (
	<div
		id={uniqueId}
		className={`p-1 d-flex align-items-center ${show ? 'visible' : 'invisible'}`}
	>
		<i className={`alert-size material-icons mr-1 text-${isAlarm ? 'danger' : 'warning'}`}>warning</i>
		<strong className="alert-text">{text}</strong>
	</div>
);

WarningMessage.propTypes = {
	isAlarm: PropTypes.bool,
	uniqueId: PropTypes.string.isRequired,
	show: PropTypes.oneOf([ undefined, false, true ]),
	text: PropTypes.string.isRequired
};

export default React.memo(WarningMessage);
