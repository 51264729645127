function buildByteArray(length, inputVal) {
	let byteArray = [];
	for (let i = length - 1; i >= 0; i--) {
		const byte = inputVal & 0xff;
		byteArray[i] = byte;
		inputVal = inputVal >> 8;
	}
	return byteArray;
}

export function longToByteArray(long) {
	// Return representation of the input value as a 8 bytes array
	return buildByteArray(8, long);
}

export function intToByteArray(int) {
	// Return representation of the input value as a 4 bytes array
	return buildByteArray(4, int);
}

export function shortToByteArray(short) {
	// Return representation of the input value as a 2 bytes array
	return buildByteArray(2, short);
}
