//changedUserCodecManually

import { getFromLocalStorage, saveToLocalStorage } from './localStorageMethods';

export const addUserIdToLocalStorage = (id) => {
  let storage = getFromLocalStorage('changedUserCodecManually');
  if (!storage) {
    saveToLocalStorage('changedUserCodecManually', [id]);
  } else {
    if (!Array.isArray(storage)) {
      storage = [];
    }
    const isExistId = storage.find((user) => user === id);
    if (!isExistId) {
      storage.push(id);
      saveToLocalStorage('changedUserCodecManually', storage);
    }
  }
};

export const isExistUser = (id) => {
  const storage = getFromLocalStorage('changedUserCodecManually');
  if (storage) {
    return storage.find((user) => user === id);
  }
  return false;
};
