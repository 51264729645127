import React from 'react';
import PropTypes from 'prop-types';

import './Records.css';
import Spinner from '../common/Spinner/Spinner';
import Record from '../Record/Record';

const Records = ({
	records,
	handleStartUpload,
	handlePauseUpload,
	setDetailsForDeleteRecordModal,
	areRecordsLoading,
	focusedPrimaryId,
}) => {
	return (
		<div className='records mt-1'>
			{areRecordsLoading ? (
				<Spinner customClass='records-control-spinner' />
			) : records.length > 0 ? (
				records.map(record => (
					<Record
						key={record.name}
						record={record}
						uniqueKey={record.name}
						handlePauseUpload={handlePauseUpload}
						handleStartUpload={handleStartUpload}
						setDetailsForDeleteRecordModal={setDetailsForDeleteRecordModal}
					/>
				))
			) : (
				<p className='m-3'>No records.</p>
			)}
		</div>
	);
};

Records.propTypes = {
	records: PropTypes.array.isRequired,
	areRecordsLoading: PropTypes.bool.isRequired,
};

export default Records;
