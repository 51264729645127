import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import './Alarms.css';

import TitleWithUsername from '../common/TitleWithUsername/TitleWithUsername';
import WarningMessage from '../WarningMessage/WarningMessage';

const Alarms = ({ primaryName, alarmsVideo, alarmsPrimary, focusedPrimaryId }) => {
  return (
    <div className="alarms">
      <TitleWithUsername title="alerts" primaryName={primaryName} />

      <div className="alarms-section">
        <ul>
          {(alarmsVideo && alarmsVideo.length > 0) ||
          (alarmsPrimary && alarmsPrimary.length > 0) ? (
            <Fragment>
              {alarmsVideo &&
                alarmsVideo.length > 0 &&
                alarmsVideo.map(({ name, type, message }) => (
                  <li className="mt-1" key={name}>
                    <div className="p-1 d-flex">
                      <i
                        className={`alert-size material-icons mr-2 text-${
                          type === 'ERROR' ? 'danger' : 'warning'
                        }`}>
                        warning
                      </i>
                      <strong className="alert-text">{message}</strong>
                    </div>
                  </li>
                ))}

              {alarmsPrimary &&
                alarmsPrimary.length &&
                alarmsPrimary.map(({ name, type, message }) => (
                  <li className="mt-1" key={name}>
                    <WarningMessage
                      isAlarm={type === 'ALARM'}
                      uniqueId={name}
                      show={true}
                      text={message}
                    />
                  </li>
                ))}
            </Fragment>
          ) : (
            <li className="m-3">
              <span>{focusedPrimaryId ? `No alerts` : 'No primary selected'}</span>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

Alarms.propTypes = {
  primaryName: PropTypes.string.isRequired,
  alarmsVideo: PropTypes.array.isRequired,
};

export default React.memo(Alarms);
