import RoundProgressBar from "../common/RoundProgressBar/RoundProgressBar";
import React from 'react';
import PropTypes from 'prop-types';
import './Record.css'

const Record = ({
    record,
    uniqueKey,
    handleStartUpload,
    handlePauseUpload,
    setDetailsForDeleteRecordModal
}) => {
    const {
        name,
        status,
        percentage,
        size,
        date,
        time
    } = record;

    const buttonsDetails = [
        {id: "download-record-btn", clickHandler: handleStartUpload, icon: "vertical_align_bottom"},
        {id: "pause-record", clickHandler: handlePauseUpload, icon: "pause"},
        {id: "play-record", clickHandler: handleStartUpload, icon: "play_arrow"}];

    const formattedDate = date.replaceAll("-","/");
    const formattedTime = time.slice(0, time.length - 3).replace("-",":");
    const formattedSize = parseFloat(size).toFixed(2);

    return (
        <div key={uniqueKey} className="record-container">
            <div >
                
                <p className="record-title record-text">{name}</p>
                <p className="description record-text">{formattedSize}MB | {formattedDate} | {formattedTime}</p>
                   
                <div className="d-flex row-wrapper">
                <div className="progress-wrapper">
                        <RoundProgressBar
                            isShown={status === 1 || status === 2}
                            percentage={percentage ? percentage : 0}/>
                    </div>
                <div className="d-flex justify-content-center action-wrapper">
                
                    <div className='p-1' hidden={status === 3}>
                        {buttonsDetails[status] && (
                        <button className='delete-btn' value={buttonsDetails[status].id} onClick={() => buttonsDetails[status].clickHandler(name)}><span className='material-icons'>{buttonsDetails[status].icon}</span></button>
                        )}
                    </div>
                    <div className='p-1' hidden={status !== 3}>
                        <button className='delete-btn' disabled={true} value={"record-uploaded"} onClick={()=>setDetailsForDeleteRecordModal(name)}><span className='material-icons'>check</span></button>
                    </div>

                     <div className='p-1' >
				    	<button className='delete-btn' disabled={status === 1} value={"delete-record-btn"} onClick={()=>setDetailsForDeleteRecordModal(name)}><span className='material-icons'>delete_outline</span></button>
				     </div>
    
                </div>
                
                </div>
            </div>
        </div>
    );
};

export default Record;

Record.propTypes = {
    record: PropTypes.object.isRequired,
    uniqueKey: PropTypes.string.isRequired,
    handleStartUpload: PropTypes.func.isRequired,
    handlePauseUpload: PropTypes.func.isRequired,
    setDetailsForDeleteRecordModal: PropTypes.func.isRequired,
}

