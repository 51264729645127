import React, {useState, useEffect} from 'react';
import s from './TextFieldForm.module.css';

export const TextFieldForm = ({
                                  label,
                                  name,
                                  required,
                                  isBlackInput,
                                  isSmallHeight,
                                  errorText,
                                  value,
                                  onChange,
                                  ...rest
                              }) => {
    const [localValue, setLocalValue] = useState(value || '');
    const [touched, setTouched] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        setLocalValue(value || '');
    }, [value]);

    const handleChange = (e) => {
        setLocalValue(e.target.value);
        onChange(e);

        if (required && !e.target.value) {
            setError(`${label} is required`);
        } else {
            setError('');
        }
    };

    const handleBlur = () => {
        setTouched(true);

        if (required && !localValue) {
            setError(`${label} is required`);
        } else {
            setError('');
        }
    };

    const isError = touched && error;

    return (
        <div>
            <span className="col-form-label modal-field-label mb-1" style={{justifyContent: 'space-between'}}>
                <div>
                    {label}
                    {required && <span style={{color: '#DE684F', marginLeft: '4px'}}>*</span>}
                </div>
                {isError && <span className={s.error}>{error}</span>}
            </span>
            <input
                type="text"
                value={localValue}
                onChange={handleChange}
                onBlur={handleBlur}
                className={`form-control input-custom false ${isBlackInput ? s.inputBlack : s.input} ${isSmallHeight ? s.smallHeight : ''}`}
                {...rest}
            />
        </div>
    );
};
