import React, { useEffect, useState } from 'react';
import s from './InviteGuest.module.css';
import { EmailIcon, TelegramIcon, TelegramShareButton, WhatsappIcon } from 'react-share';
import axios from 'axios';
import { GUESTS_API } from '../../../../../constants/endPointsURLs';

export const InviteGuest = ({
    showNotification,
    data,
    dashboardCompanyName,
    imgSrc = './images/guestModeImages/done.svg',
    title,
    paragraph = 'Your guest has been successfully created and is ready for an invitation.',
    span = 'Please select a sharing option using the buttons below',
    userId = null,
}) => {
    const [active, setActive] = useState(true);
    const [sendSMS, setSendSMS] = useState(true);
    const primaryId = data.primaryId ? data.primaryId : null;
    const guestFirstName = data.firstName ? data.firstName.charAt(0).toUpperCase() + data.firstName.slice(1) : '';
    const companyName = dashboardCompanyName
        ? dashboardCompanyName.charAt(0).toUpperCase() + dashboardCompanyName.slice(1)
        : '';
    const shortLink = data.guestDeepLinkShortUrl;
    const message = `Hello ${guestFirstName},\nYou are invited as a guest of ${companyName} to Go Live!\nPlease use the link below to download and install the Omnistream App:\nAndroid: ${shortLink}`;
    const email = data.email || '';
    const encodedPhoneNumber = encodeURIComponent(data.phoneNumber);
    const encodedMessage = encodeURIComponent(message);
    const subject = encodeURIComponent("You're invited to go live");
    const TelegramLink = message;

    useEffect(() => {
        setActive(data.guestSmsInvitationSent ? false : true);
        setSendSMS(data.guestSmsInvitationSent ? false : true);
    }, [userId, data.guestSmsInvitationSent]);

    const SMSIcon = ({ active }) => {
        return (
            <svg
                width="50"
                height="50"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={s.icons}
            >
                <circle cx="25" cy="25" r="25" fill={active ? '#02B735' : 'gray'} />
                <path
                    d="M36.2 11H13.8C12.26 11 11.014 12.26 11.014 13.8L11 39L16.6 33.4H36.2C37.74 33.4 39 32.14 39 30.6V13.8C39 12.26 37.74 11 36.2 11ZM20.8 23.6H18V20.8H20.8V23.6ZM26.4 23.6H23.6V20.8H26.4V23.6ZM32 23.6H29.2V20.8H32V23.6Z"
                    fill="white"
                />
            </svg>
        );
    };

    const CopyIcon = () => {
        return (
            <svg
                width="50"
                height="50"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={s.icons}
            >
                <circle cx="25" cy="25" r="25" fill="#DE684F" />
                <path
                    d="M31.0909 10H14.7273C13.2273 10 12 11.2273 12 12.7273V31.8182H14.7273V12.7273H31.0909V10ZM35.1818 15.4545H20.1818C18.6818 15.4545 17.4545 16.6818 17.4545 18.1818V37.2727C17.4545 38.7727 18.6818 40 20.1818 40H35.1818C36.6818 40 37.9091 38.7727 37.9091 37.2727V18.1818C37.9091 16.6818 36.6818 15.4545 35.1818 15.4545ZM35.1818 37.2727H20.1818V18.1818H35.1818V37.2727Z"
                    fill="white"
                />
            </svg>
        );
    };

    const sendSmsHandle = async () => {
        if (sendSMS) {
            try {
                const response = await axios.post(GUESTS_API.sendGuestInviteViaSMS(primaryId), {}, { withCredentials: true });
                showNotification('send_sms', 'SMS send successfully');
                if (response.status === 200) {
                    setActive(false);
                    setSendSMS(false);
                }
            } catch (error) {
                const { response } = error;
                showNotification('sms_error', response.data.message);
            }
        } else {
            return;
        }
    };

    const handleWhatsAppClick = () => {
        const whatsappLink = `https://web.whatsapp.com/send?phone=${encodedPhoneNumber}&text=${encodedMessage}&app_absent=0`;
        window.open(whatsappLink, '_blank');
    };

    const handleEmailClick = () => {
        window.location.href = `mailto:${email}?subject=${subject}&body=${encodedMessage}`;
    };

    const handleCopyField = (value, name) => {
        navigator.clipboard.writeText(value ? value : null);
        showNotification('value_clipboard', `${name} copied to clipboard`);
    };

    return (
        <div className={s.container}>
            <div className={s.wrapper}>
                <img src={imgSrc} alt="" className={s.img} />
                <h1 className={s.title}>{title}</h1>
                <p className={s.paragraph}>{paragraph}</p>
                <span className={s.span}>{span}</span>
                <div className={s.shareLinks}>
                    <button type="button" onClick={sendSmsHandle} className={s.buttonLink}>
                        <SMSIcon active={active} />
                    </button>
                    <button type="button" onClick={handleWhatsAppClick} className={s.buttonLink}>
                        <WhatsappIcon size={50} round className={s.icons} />
                    </button>
                    <TelegramShareButton url={TelegramLink}>
                        <TelegramIcon size={50} round className={s.icons} />
                    </TelegramShareButton>
                    <button type="button" onClick={handleEmailClick} className={s.buttonLink}>
                        <EmailIcon size={50} round className={s.icons} />
                    </button>
                    <button
                        type="button"
                        onClick={() => handleCopyField(message, 'Invitation Message')}
                        className={s.buttonLink}
                    >
                        <CopyIcon />
                    </button>
                </div>
            </div>
        </div>
    );
};
