import React from 'react';
import PropTypes from 'prop-types';

import ProvidersChart from '../ProvidersChart/ProvidersChart';
import BitrateChart from '../BitrateChart/BitrateChart';
import DevicesInfo from '../DevicesInfo/DevicesInfo';
import './BitrateChartAndDevicesInfo.css';
import { constant } from 'lodash';

class BitrateChartAndDevicesInfo extends React.Component {
  state = {
    showBitrateChart: false,
    showProvidersChart: false,
    showDevicesInfo: true,
  };

  componentDidMount() {
    if (localStorage.getItem('bitrateChartAndDevicesInfoTabsStateV1') !== null) {
      const { showBitrateChart, showProvidersChart, showDevicesInfo } = JSON.parse(
        localStorage.getItem('bitrateChartAndDevicesInfoTabsStateV1'),
      );

      this.setState({ showBitrateChart, showProvidersChart, showDevicesInfo });
    } else {
      this.saveBottomPanelTabsStateToLocalStorage();
    }
  }

  handleTabsSwitch = (e) => {
    const showBitrateChart = e.target.id === 'bitrateChart';
    const showProvidersChart = e.target.id === 'providersChart';
    const showDevicesInfo = e.target.id === 'devicesInfo';

    this.setState(
      {
        showBitrateChart,
        showProvidersChart,
        showDevicesInfo,
      },
      () => {
        this.saveBottomPanelTabsStateToLocalStorage();
      },
    );
  };

  saveBottomPanelTabsStateToLocalStorage = () => {
    const { showBitrateChart, showProvidersChart, showDevicesInfo } = this.state;
    const bitrateChartAndDevicesInfoTabsState = {
      showBitrateChart,
      showProvidersChart,
      showDevicesInfo,
    };
    localStorage.setItem(
      'bitrateChartAndDevicesInfoTabsStateV1',
      JSON.stringify(bitrateChartAndDevicesInfoTabsState),
    );
  };

  render() {
    const {
      broadcastDetailsJsonObject,
      focusedPrimaryId,
      isPrimaryBroadcasting,
      broadcastSettings,
      broadcastDetails,
      devicesSetInfo,
      isDevicesSetsInfoLoading,
      isBitrateChartDisplayed,
      screenSize,
    } = this.props;

    const { showBitrateChart, showProvidersChart, showDevicesInfo } = this.state;

    return (
      <div className="bitrate-chart-and-devices-info col-8 tab-activate">
        <ul className="nav nav-tabs border-bottom-0 ">
          <li className="nav-item">
            <button
              className={`nav-link ${
                showDevicesInfo && 'active'
              } none_active_tab tab-pointer btn-icon`}
              id="devicesInfo"
              onClick={this.handleTabsSwitch}>
              <i className="center-icon material-icons">devices</i>
              Devices Info
            </button>
          </li>

          <li className="nav-item">
            <button
              className={`nav-link ${
                showBitrateChart && 'active'
              } none_active_tab tab-pointer btn-icon`}
              id="bitrateChart"
              onClick={this.handleTabsSwitch}>
              <i className="center-icon material-icons">show_chart</i>
              Bitrate Chart
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link ${
                showProvidersChart && 'active'
              } none_active_tab tab-pointer btn-icon`}
              id="providersChart"
              onClick={this.handleTabsSwitch}>
              <i className="center-icon material-icons" disabled>
                pie_chart_outline
              </i>
              Load Chart
            </button>
          </li>
        </ul>

        <div className="bg-light data-area alerts-bg devices-info-scrollbar">
          <div className={`${showBitrateChart ? 'd-block' : 'd-none'} bitrate-chart-data-container`}>
            <BitrateChart
              focusedPrimaryId={focusedPrimaryId}
              isPrimaryBroadcasting={isPrimaryBroadcasting}
              broadcastSettings={broadcastSettings}
              broadcastDetails={broadcastDetails}
              broadcastDetailsJsonObject={broadcastDetailsJsonObject}
              isBitrateChartDisplayed={isBitrateChartDisplayed && showBitrateChart}
              screenSize={screenSize}
            />
          </div>
          <div className={`${showProvidersChart ? 'd-block' : 'd-none'} pie-data-container`}>
            <ProvidersChart
              isProviderChartDisplayed={showProvidersChart}
              focusedPrimaryId={focusedPrimaryId}
              isPrimaryBroadcasting={isPrimaryBroadcasting}
              broadcastDetailsJsonObject={broadcastDetailsJsonObject}
              broadcastDetails={broadcastDetails}
              screenSize={screenSize}
            />
          </div>
          <div className={showDevicesInfo ? 'd-block' : 'd-none'}>
            <DevicesInfo
              devicesSetInfo={devicesSetInfo}
              isDevicesSetsInfoLoading={isDevicesSetsInfoLoading}
            />
          </div>
        </div>
      </div>
    );
  }
}

BitrateChartAndDevicesInfo.propTypes = {
  isBitrateChartDisplayed: PropTypes.bool.isRequired,
};

export default BitrateChartAndDevicesInfo;
