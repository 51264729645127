import React from 'react'
import PropTypes from 'prop-types';

import './RoundProgressBar.css'

const orangeBorder = '#de684f';
const RoundProgressBar = ({
                    percentage,
                    isShown
                 }) => {

    return (
        <div className="arc-wrapper" hidden={!isShown}>
            <div className="arc arc_start"
                style={
                    {
                        borderColor: `${orangeBorder} 
                                   ${percentage > 25 ? '#de684f ' : 'white '}
                                   ${percentage > 50 ? '#de684f ' : 'white '}
                                   white`
                    }
                }
            />
            {percentage < 75 ?
                (<div className="arc arc_end"
                style={
                    {transform: `rotate(${((percentage / 100) * 360) + 45}deg)`,
                        border: "4px solid",
                        borderColor: "white transparent transparent transparent"}
                }/>) :
                (<div className="arc arc_end"
                      style={
                          {transform: `rotate(${((percentage / 100) * 360) - 45}deg)`,
                              border: "4px solid",
                              borderColor: `${orangeBorder} transparent transparent transparent`}
                      }/>)}
            <p className="percentage" hidden={percentage === 0}>{percentage}%</p>
        </div>
    )
}

RoundProgressBar.propTypes = {
    percentage: PropTypes.number.isRequired,
    isShown: PropTypes.bool
}

export default RoundProgressBar