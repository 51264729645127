export default {
  formId: 'guestFormModel',
  formField: {
    firstName: {
      name: 'firstName',
      label: 'First name',
      requiredErrorMsg: 'First name is required',
    },
    lastName: {
      name: 'lastName',
      label: 'Last name',
      requiredErrorMsg: 'Last name is required',
    },
    email: {
      name: 'email',
      label: 'Email',
      // requiredErrorMsg: 'Email is required',
    },
    countryCode: {
      name: 'countryCode',
      label: 'Country code',
      requiredErrorMsg: 'Country code is required',
    },
    phoneNumber: {
      name: 'phoneNumber',
      label: 'Mobile phone number',
      requiredErrorMsg: 'Mobile phone number is required',
    },
    camera: {
      name: 'camera',
      label: 'Camera Source *',
    },
    resolution: {
      name: 'resolution',
      label: 'Resolution *',
    },
    bitrate: {
      name: 'bitrate',
      label: 'Bitrate *',
    },
    codec: {
      name: 'codec',
      label: 'Codec *'
    },
    streamType: {
      name: 'streamType',
      label: 'Profile *'
    },
    orientation: {
      name: 'orientation',
      label: 'Stream Orientation *'
    },
    broadcastOutputId: {
      name: 'broadcastOutputId',
      label: 'Attach to Output'
    },
    autoStart: {
      name: 'autoStart',
      label: 'Auto-Start Stream *',
    },
  },
};
