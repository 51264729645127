import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';

import './Output.css';

import InfoHeader from '../InfoHeader/InfoHeader';
import MiniPreview from '../MiniPreview/MiniPreview';
import AlarmIcon from '../common/AlarmIcon/AlarmIcon';
import Modal from '../common/Modal/Modal';

class Output extends React.PureComponent {
	state = {
		isCriticalAlarmDisplayed: false,
		criticalAlarmText: '',
		isAlarmIconDisplayed: false,
		alarmTooltipText: ''
	};

	componentDidMount() {
		this.checkStatusesForAlarms(this.props.statuses);

		$('.output').fadeIn(500);
	}

	componentDidUpdate(prevProps) {
		const { statuses } = this.props;

		if (statuses !== prevProps.statuses) {
			this.checkStatusesForAlarms(statuses);
		}
	}

	checkStatusesForAlarms = (statuses) => {
		const criticalAlarmIndex = statuses.findIndex(
			(status) => status.Name === 'PortsUnavailable'
		);
		const isCriticalAlarmDisplayed = criticalAlarmIndex > -1 ? true : false;
		const criticalAlarmText =
			criticalAlarmIndex > -1
				? statuses[criticalAlarmIndex].Description
				: '';

		const alarmIndex = statuses.findIndex(
			(status) => status.Name === 'DownLinkStatus'
		);

		const isAlarmIconDisplayed = alarmIndex > -1 ? true : false;
		const alarmTooltipText =
			alarmIndex > -1 ? statuses[alarmIndex].Description : '';

		this.setState({
			isCriticalAlarmDisplayed,
			criticalAlarmText,
			isAlarmIconDisplayed,
			alarmTooltipText
		});
	};

	handleCriticalAlarmClick = () => {
		$('#blocked-ports-issue-modal').modal();
	};

	render() {
		const {
			outputId,
			name,
			displayedName,
			uuid,
			isActive,
			isDesignated,
			associatedPrimaryId,
			isAssociatedPrimaryBroadcasting,
			focusedPrimaryId,
			screenSize,
			setDraggedElement,
			handleDrop,
			setDetailsForModal,
			handleGuidCopyIconClick,
			companyType
		} = this.props;

		const {
			isCriticalAlarmDisplayed,
			criticalAlarmText,
			isAlarmIconDisplayed,
			alarmTooltipText
		} = this.state;

		const innerHeight = window.innerHeight;

		return (
			<div
				id={outputId}
				className={`output card mb-1${associatedPrimaryId ===
				focusedPrimaryId
					? ' bg-info'
					: ''}`}
			>
				<div
					className="drag-and-drop-container"
					draggable="true"
					onDragStart={() =>
						setDraggedElement({ type: 'output', id: outputId })}
					onDragOver={(e) => e.preventDefault()}
					onDrop={() => handleDrop({ type: 'output', id: outputId })}
				>
					<InfoHeader
						type="Output"
						id={outputId}
						name={name}
						displayedName={displayedName}
						uuid={uuid}
						isActive={isActive}
						isThereIssue={
							isCriticalAlarmDisplayed || isAlarmIconDisplayed
						}
						isDesignatedOutput={isDesignated}
						screenSize={screenSize}
						componentLocation="dashboard-output"
						setDetailsForModal={setDetailsForModal}
						handleGuidCopyIconClick={handleGuidCopyIconClick}
						companyType={companyType}
					/>
					<div className='parent-class'>
						{isAlarmIconDisplayed &&
							<AlarmIcon
							position="absolute"
							tooltipDirection="down"
							criticalLevel={2}
							alarmTooltipText={alarmTooltipText}
							alarmIcon="warning"/>
						}

						<MiniPreview
							isInput={false}
							signalId={associatedPrimaryId}
							isThereSignal={isAssociatedPrimaryBroadcasting}
							screenSize={screenSize}
							companyType={companyType}
						/>
						</div>
				</div>
					
			
			</div>
		);
	}
}

Output.propTypes = {
	outputId: PropTypes.number.isRequired,
	associatedPrimaryId: PropTypes.number,
	focusedPrimaryId: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
		.isRequired,
	statuses: PropTypes.array.isRequired,
	setDraggedElement: PropTypes.func.isRequired,
	handleDrop: PropTypes.func.isRequired
};

export default Output;

/*
Name: "DownLinkStatus"
Type: "WARNING"
Description: "There are some network bottleneck issues"

Name: "DownLinkStatus"
Type: "ERROR"
Description: "There is a severe network bottleneck, please increase you server bandwidth"


Name: "RecipientStreamError"
Type: "ERROR"
Description: "Could not write video stream data, on output device FFPlay using Default format"
*/
