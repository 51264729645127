import axios from 'axios';
import config from '../../../../dashboard_config.json';

const IP_INFO_IO_TOKEN = config.dashboard.apiKeys.ipInfo.token;

const {
    localStorageKeyName,
    fallBackCountryCode
} = config.dashboard.guests.guestLocation;

export const fetchUserCountry = async () => {
    let userCountry = fallBackCountryCode;

    if (!IP_INFO_IO_TOKEN) {
        console.error('IP Info token is missing.');
        return userCountry;
    }

    try {
        const response = await axios.get(`https://ipinfo.io/json?token=${IP_INFO_IO_TOKEN}`);
        if (response.status === 200) {
            userCountry = response.data.country.toLowerCase();
        } else if (response.status === 429) {
            console.error('Rate limit exceeded.');
        } else {
            console.error('Unexpected response status:', response.status);
        }
    } catch (error) {
        console.error('Error fetching user country:', error);
    }

    localStorage.setItem(localStorageKeyName, userCountry);
    return userCountry;
};

export const getUserCountryFromLocalStorage = () => {
    const storedCountry = localStorage.getItem(localStorageKeyName);
    if (!storedCountry) {
        return fallBackCountryCode;
    }
    return storedCountry;
};
