import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Button from '../common/Button/Button';
import ControlButtons from '../ControlButtons/ControlButtons';
import "./BroadcastDetailsBar.css";

const BroadcastDetailsBar = ({
	focusedPrimaryId,
	isChangingStartStopInProgress,
	isPrimaryBroadcasting,
	streamType,

	screenSize,
	isSlidingPanelDisplayed,

	clickStartStop,
	clickIFBmouseDown,
	clickIFBmouseUp,
	handleSlidingPanelToggle,
	
	primaryUsers,
	onAudioSelect,
	audioDevices,
	selectedDevice,
	isAudioChannelOpen,

	companyType,
}) => {
	return (
		<Fragment>
			<div className='buttons-container layout-block-center d-flex'>
				<ControlButtons
					focusedPrimaryId={focusedPrimaryId}
					isChangingStartStopInProgress={isChangingStartStopInProgress}
					streamType={streamType}
					isPrimaryBroadcasting={isPrimaryBroadcasting}
					screenSize={screenSize}
					clickStartStop={clickStartStop}
					clickIFBmouseDown={clickIFBmouseDown}
					clickIFBmouseUp={clickIFBmouseUp}
					primaryUsers={primaryUsers}
					onAudioSelect={onAudioSelect}
					audioDevices={audioDevices}
					selectedDevice={selectedDevice}
					isAudioChannelOpen={isAudioChannelOpen}
					companyType={companyType}
			
				/>
				
					<div className={`${companyType===0
						? 'p-2 mx-2 center-in-layout' : 'invisible'}`}>
						<Button
							uniqueId='broadcast-details-toggle-btn'
							iconName={isSlidingPanelDisplayed ? 'expand_more' : 'expand_less'}
							text='Broadcast Details'
							clickHandler={handleSlidingPanelToggle}
						/>
					</div>
				</div>
			
			<div className='layout-block-right' />
		</Fragment>
	);
};

BroadcastDetailsBar.propTypes = {
	focusedPrimaryId: PropTypes.number,
	isChangingStartStopInProgress: PropTypes.bool.isRequired,
	streamType: PropTypes.string,
	isPrimaryBroadcasting: PropTypes.oneOf([undefined, false, true]),
	screenSize: PropTypes.string,
	isSlidingPanelDisplayed: PropTypes.bool.isRequired,
	handleSlidingPanelToggle: PropTypes.func.isRequired,
	companyType: PropTypes.number,
};

export default React.memo(BroadcastDetailsBar);
