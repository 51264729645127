import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import * as s from './GuestCounter.module.css';
import config from '../../../../dashboard_config.json';

const {
  guestNormalBgColor, // ORANGE
  guestAlarmBgColor, // YELLOW
  guestAlertBgColor, // RED
  guestButtonWhiteColor,
  guestButtonGrayColor,
  guestCountForAlarm,
  guestCountForAlert,
  guestFreeTrialText,
  guestOrderCanceledText
} = config.dashboard.guests.guestCounter;

export const GuestCounter = ({ guestsTotal, guestsTotalHistory, guestsRemain, guestsFreeTrialRemain, guestsMax }) => {

  const showFreeTrialText = guestsFreeTrialRemain >= 0 && (guestsRemain === 0 && guestsTotal === 0 && guestsTotalHistory === 0);
  const isCanceledOrder = guestsTotalHistory !== 0 && (guestsFreeTrialRemain === 0 && guestsRemain === 0 && guestsTotal === 0);

  const displayGuestsCounter = useMemo(() => {
    if (showFreeTrialText) {
      return `${guestsFreeTrialRemain} ${guestFreeTrialText}`;
    } else if (isCanceledOrder) {
      return `${guestsFreeTrialRemain} ${guestOrderCanceledText}`;
    } else if (guestsRemain >= 0) {
      return `${guestsRemain}/${guestsTotal}`;
    }
    return '';
  }, [showFreeTrialText, isCanceledOrder, guestsFreeTrialRemain, guestsRemain, guestsTotal]);


  const backgroundColor = useMemo(() => {
    if (guestsTotal !== 0) {
      if (guestsRemain <= guestCountForAlert) {
        return guestAlertBgColor;
      } else if (guestsRemain <= guestCountForAlarm) {
        return guestAlarmBgColor;
      } else {
        return guestNormalBgColor;
      }
    }
    return guestNormalBgColor;
  }, [guestsTotal, guestsRemain]);

  const textColor = useMemo(() => {
    if (guestsTotal !== 0) {
      if (backgroundColor === guestAlertBgColor || backgroundColor === guestAlarmBgColor) {
        return guestButtonWhiteColor;
      }
      return guestButtonGrayColor;
    }
    return guestButtonWhiteColor;
  }, [backgroundColor, guestsTotal]);

  const title = useMemo(() => {
    const usedGuestInvitations = guestsTotal - guestsRemain;
    if (showFreeTrialText || usedGuestInvitations === 0) {
      return '';
    }
    return `Used Guest Invitations: ${usedGuestInvitations}`;
  }, [guestsFreeTrialRemain, guestsRemain, showFreeTrialText]);

  return (
    <div className={s.root} style={{ backgroundColor, color: textColor }} title={title}>
      <span>{displayGuestsCounter}</span>
    </div>
  );
};

GuestCounter.propTypes = {
  guestsTotal: PropTypes.number.isRequired,
  guestsTotalHistory: PropTypes.number.isRequired,
  guestsRemain: PropTypes.number.isRequired,
  guestsFreeTrialRemain: PropTypes.number.isRequired,
  guestsMax: PropTypes.number.isRequired,
};
