import { InviteGuest } from '../GuestMode/form/steps/InviteGuest/InviteGuest';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { PRIMARY_API } from '../../constants/endPointsURLs';
import './ShareModal.modal.css';
import $ from 'jquery';

export const ShareModal = ({ id, showNotification, dashboardCompanyName }) => {
    const [data, setData] = useState({});

    const fetchData = async () => {
        try {
            const response = await axios.get(PRIMARY_API.getPrimaryInfo(id), { withCredentials: true });
            setData(response.data);
        } catch (error) {
            console.error('Error fetching data', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [id]);

    const handleCloseModal = () => {
        $('#share-link-modal').modal('hide');
    };

    return (
        <div className="modal fade" id="share-link-modal">
            <div
                role="document"
                className="modal-dialog modal-dialog-centered"
                id="create-guest-mode-modal-dialog"
                style={{ maxWidth: '745px' }}
            >
                <div
                    className={`content_container modal-content`}
                    style={{ display: 'flex', justifyContent: 'center' }}
                >
                    <InviteGuest
                        data={data}
                        dashboardCompanyName={dashboardCompanyName}
                        showNotification={showNotification}
                        imgSrc={'./images/infoHeader/shareBig.svg'}
                        title={'Invite your guest!'}
                        paragraph={'Your guest is ready to go live.'}
                        spanText={'Please select a sharing option using the buttons below'}
                        userId={id}
                    />

                    <div className="button_wrapper">
                        <button className="btn list-bottom-buttons" onClick={handleCloseModal}>
                            <div className="list-bottom-buttons-text">Close</div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
