import React from 'react';
import s from './GuestModalStepper.module.css';

const StepCounter = ({ steps, step, currentStep }) => {
  const isHalfFill = currentStep === step;
  const isAbsoluteFill = currentStep > step;

  return (
    <div className={s.step_root}>
      <div className={`${s.curent_step} ${isHalfFill && s.curent_step_fill} ${isAbsoluteFill && s.curent_step_fill_whole}`}>{step + 1}</div>
      {step !== steps.length - 1 && <div className={`${s.line_step} ${isHalfFill && s.line_step_fill} ${isAbsoluteFill && s.line_step_fill_whole}`}></div>}
    </div>
  );
};

export const GuestModalStepper = ({ steps, currentStep }) => {
  return (
    <div className={s.root}>
      {steps.map((_step, index) => (
        <StepCounter key={index} step={index} steps={steps} currentStep={currentStep} />
      ))}
    </div>
  );
};
