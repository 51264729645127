import React from 'react';
import PropTypes from 'prop-types';

import './MiniPreview.css';


import { APIs } from '../../constants/endPointsURLs';

class MiniPreview extends React.Component {
	state = {
		width: 0,
		height: 0,
	};

	canvasRef = React.createRef();
	imgEl = '';
	scheduler = '';

	componentDidMount() {
		const { isThereSignal, companyType } = this.props;
		
		let width;
		let height;
		if (companyType===0){
			width = 250;
		}else{
			width = 350;
		}
		height = width * 9 / 16;

		const canvas = this.canvasRef.current.getContext('2d');
		this.imgEl = document.createElement('img');
		this.imgEl.onload = () => {
			canvas.drawImage(this.imgEl, 0, 0, width, height);
		};

		if (isThereSignal === true) {
			this.setScheduler();
		}

		this.setState({width:width,height:height});
	}

	componentDidUpdate(prevProps) {
		const { isThereSignal } = this.props;
		if (isThereSignal !== prevProps.isThereSignal) {
			if (isThereSignal === true) {
				this.setScheduler();
			} else {
				clearInterval(this.scheduler);
			}
		}
	}

	componentWillUnmount() {
		clearInterval(this.scheduler);
	}

	setScheduler = () => {
		const { signalId } = this.props;
		this.imgEl.src = APIs.getBroadcastThumbnail(signalId);
		this.scheduler = setInterval(() => {
			this.imgEl.src = APIs.getBroadcastThumbnail(signalId);
		}, 1000);
	};

	render() {
		const { isThereSignal, isInput, companyType } = this.props;
		const { width, height } = this.state;
		
		return (
			<div className="mini-preview">
				<div className={`${isInput ?  (companyType===0 ? 'thumbnail-img-input' : 'thumbnail-img-input-extended') : 'thumbnail-img-output' } ${isThereSignal
						? 'd-none'
						: 'd-block'}`}
				/>
				
				<canvas
					className={isThereSignal ? 'd-block' : 'd-none'}
					ref={this.canvasRef}
					width={width}
					height={height}
				/>

			</div>
		);
	}
}

MiniPreview.propTypes = {
	signalId: PropTypes.number,
	isThereSignal: PropTypes.oneOf([ undefined, false, true ]),
	screenSize: PropTypes.string,
	isInput:  PropTypes.oneOf([ undefined, false, true ])
};

export default MiniPreview;
