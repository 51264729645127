// ---------- WebSocket messages types ---------- //
export const DASHBOARD_TO_PRIMARIES_MSG = 0;
export const DASHBOARD_TO_PRIMARY_MSG = 1;
export const PRIMARY_TO_DASHBOARD_MSG = 2;
export const DASHBOARD_TO_CENTRAL_SERVER_MSG = 3;
export const CENTRAL_SERVER_TO_DASHBOARD_MSG = 4;
export const KEEP_ALIVE_MSG = 6;

// --------------------------------------------------------------------- //

// --- Outgoing WebSocket messages sub types --- //
export const IFB_MSG = 100;
export const START_SESSION_MSG = 101;
export const STOP_SESSION_MSG = 102;
export const GET_PRIMARY_SETTINGS_MSG = 103;
export const SET_PRIMARY_SETTINGS_MSG = 104;
// export const GET_DEVICES_INFO_MSG = 105;		// Currently not in use
export const SET_DESIGNATED_OUTPUT = 106;
export const DIALOG_ALERT_RESPONSE = 107;
export const GET_DEVICE_RECORDS = 108;
export const START_RECORD_UPLOAD = 109;
export const PAUSE_RECORD_UPLOAD = 110;
export const DELETE_RECORD = 111;

// --------------------------------------------------------------------- //

export const PrimaryToDashboardMsgSubtypes = [
	'settings_change', // Index 0 - Primary broadcast setting change message
	'headset', // -- Deprecated -- (currently included in 'settings-change' message)
	'alert', // Index 2 - Primaries alerts
	'attention', // -- Deprecated ---
	'fps', // -- Deprecated ---
	'bitrate', // Index 5 - Broadcast current bitrate and duration
	'devices_info', // Index 6 - Devices (Primary and Secondaries) info
	'session_header', // --- Not arriving here ---
	'session_message', // --- Not arriving here ---
	'dialog_alert_message', // Index 9
	'device_records', // Index 10
	'update_record', // Index 11
	'record_percentage', // Index 12
	'delete_record', // Index 13
];

// --------------------------------------------------------------------- //

export const alertsTypes = [
	'bitrate_change', // Index 0 - Sent, but currently ignored by Dashboard
	'secondary_and_primary_connection_issue', // Index 1
	'secondary_and_video_server_connection_issue', // Index 2
	'insufficient_bandwidth_to_start_session', // Index 3
	'external_source_failure', // Index 4
	'external_source_and_primary_settings_mismatch', // Index 5
	'upload_stopped' // Index 6
];

// --------------------------------------------------------------------- //

export const alertDialogType = [
	'source_mismatch', // Index 0
]

// --------------------------------------------------------------------- //

// export const isPrimaryRemoteEnabled = [ false, true ];

// // --------------------------------------------------------------------- //

// export const isPrimaryHeadsetPlugged = [ false, true ];

// // --------------------------------------------------------------------- //

// export const isOverBitrate = [ false, true ];

// // --------------------------------------------------------------------- //

// export const isInsufficientBattery = [ false, true ];

// // --------------------------------------------------------------------- //

// export const isHotBattery = [ false, true ];