import React from 'react';
import PropTypes from 'prop-types';
import '../../../index.css';

const Modal = ({ uniqueId, headerTitle, showExclamationIcon, buttons, children, bodyClass }) => {
  if (
    uniqueId === 'delete-export' &&
    document.getElementsByClassName('modal-backdrop').length !== 0
  ) {
    document.getElementsByClassName('modal-backdrop')[0].style.zIndex = 1;
  }
  return (
    <div className="modal fade" id={uniqueId}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header d-flex align-items-center">
            {showExclamationIcon && <i className="material-icons text-warning mr-2">warning</i>}
            <div id="first-modal" className="modal-title">
              {uniqueId === 'setting-displayed-name-modal' ? `Change ${headerTitle}` : headerTitle}
            </div>
            <button className="close close-modal-btn" data-dismiss="modal">
              &times;
            </button>
          </div>
          <div className={bodyClass}>{children}</div>

          <div className="modal-footer">
            {buttons.map(({ className, clickHandler, name }, i) => (
              <button
                key={i}
                className={`btn btn-${className}`}
                data-dismiss="modal"
                onClick={clickHandler}>
                {name}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  uniqueId: PropTypes.string.isRequired,
  headerTitle: PropTypes.string,
  showExclamationIcon: PropTypes.bool,
  children: PropTypes.any.isRequired,
  buttons: PropTypes.arrayOf(
    PropTypes.exact({
      name: PropTypes.string.isRequired,
      className: PropTypes.string.isRequired,
      clickHandler: PropTypes.func,
    }),
  ).isRequired,
};

export default Modal;
