export const cameraSourceOptions = [
    {
        value: 'front',
        displayName: 'Internal - Front'
    },
    {
        value: 'back',
        displayName: 'Internal - Back'
    }
];

export const resolutionOptions = [
    {
        value: '1080',
        displayName: '1920 x 1080',
    },
    {
        value: '720',
        displayName: '1280 x 720',
    },
    {
        value: 'SD',
        displayName: 'SD',
    }
];

export const bitrateOptions = [
    {
        value: 0,
        displayName: '1 Mb/s',
    },
    {
        value: 1,
        displayName: '2 Mb/s',
    },
    {
        value: 2,
        displayName: '3 Mb/s',
    },
    {
        value: 3,
        displayName: '4 Mb/s',
    },
    {
        value: 4,
        displayName: '5 Mb/s',
    },
    {
        value: 5,
        displayName: '6 Mb/s',
    },
    {
        value: 6,
        displayName: '8 Mb/s',
    },
    {
        value: 7,
        displayName: '12 Mb/s',
    },
    {
        value: 8,
        displayName: '16 Mb/s',
    }

];

export const codecOptions = [
    {
        value: 'h264',
        displayName: 'H.264 (RTMP Streams)'
    },
    {
        value: 'h265',
        displayName: 'H.265 (SRT Streams)'
    }
];

export const streamTypeOptions = [
    {
        value: 'Live',
        displayName: 'Live'
    },
    {
        value: 'Average',
        displayName: 'Average'
    },
    {
        value: 'Best Quality',
        displayName: 'Best Quality'
    }
];

export const orientationOptions = [
    {
        value: 'Landscape',
        displayName: 'Landscape'
    },
    {
        value: 'Portrait',
        displayName: 'Portrait'
    }
];

export const autoStartOptions = [
    {
        value: true,
        displayName: 'True'
    },
    {
        value: false,
        displayName: 'False'
    }
];
