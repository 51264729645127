import React from 'react';
import { useField, useFormikContext } from 'formik';
import s from './MultiSelectField.module.css';

export const MultiSelectField = ({ label, options, name }) => {
    const [field, meta, helpers] = useField(name);
    const context = useFormikContext();

    const handleSelect = (event) => {
        const value = event.target.value;
        if (name === 'bitrate' || name === 'broadcastOutputId') {
            if (value === 'None') {
                helpers.setValue('');
            } else {
                helpers.setValue(Number(value));
            }
        } else {
            helpers.setValue(value);
        }
    };

    const handleBooleanSelect = (event) => {
        const value = event.target.value === 'true';
        helpers.setValue(value);
    };

    return (
        <div className="multi-select-container">
            <span className="col-form-label modal-field-label mb-1">
                {label.split('*').map((part, index) => (
                    <React.Fragment key={index}>
                        {index > 0 && <span style={{ color: '#DE684F', marginLeft: '4px' }}>*</span>}
                        {part}
                    </React.Fragment>
                ))}
            </span>
            <select
                className={`form-control input-custom false ${s.input}`}
                value={field.value}
                onChange={name === 'autoStart' ? handleBooleanSelect : handleSelect}
            >
                {options.map((option, index) => (
                    <option className={s.dropdownItem} key={index} value={option.value}>
                        {option.displayName}
                    </option>
                ))}
            </select>
            {meta.touched && meta.error ? <div className={s.error}>{meta.error}</div> : null}
        </div>
    );
};
