import React from 'react';
import PropTypes from 'prop-types';
import Chart from 'chart.js';
import './BitrateChart.css';
import config from '../../dashboard_config.json';

const {
  schedulerWaitTime,
  fastIntervalSchedulerWaitTime,
  fastIntervalIterations
} = config.dashboard.charts.scheduler;

const {
  chartMainColor,
  chartLineColor,
  chartBgColor
} = config.dashboard.charts.colors.bitrateChartColors;

const {
  generalColors
} = config.dashboard.charts.colors;

class BitrateChart extends React.Component {
  state = {
    chartParams: {
      chartTimeFrame: '10sec',
      pointBorderWidth: 1.5,
      pointRadius: 2,
    },
    operatorsArr: [],
    showLegends: true,
  };

  chartConfig;
  chart;

  componentDidMount() {
    if (localStorage.getItem('chartParamsStateV1') !== null) {
      const chartParams = JSON.parse(localStorage.getItem('chartParamsStateV1'));
      this.setState({ chartParams });
    } else {
      this.saveСhartParamsState();
    }

    const ctx = document.getElementById('chart-canvas').getContext('2d');

    this.chartConfig = {
      type: 'line',
      data: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: chartBgColor,
            borderCapStyle: 'round',
            borderColor: chartLineColor,
            borderWidth: 1,
            label: 'Mb/s',
            lineTension: 0.1,
            spanGaps: false,
          },
        ],
      },

      options: {
        animation: {
          duration: 100,
          easing: 'linear',
        },
        events: ['click'],
        legend: {
          position: 'top',
          onClick() {
            return;
          },
          labels: {
            filter: function (item, chart) {
              return item.text.includes('Mb/s');
            },
            fontSize: 12,
            fontStyle: 'bold',
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                min: 0,
                max: 16,
              },
            },
          ],
        },
      },
    };

    this.chart = new Chart(ctx, this.chartConfig);
  }

  chooseColor = (index) => {
    let color = generalColors[index];
    return color;
  };

  createChartData = (broadcastDetails) => {
    this.clearChart();

    broadcastDetails.operatorsDataArr.forEach((operator, index) => {
      const newData = {
        data: operator.operatorData,
        backgroundColor: chartBgColor,
        borderColor: this.chooseColor(index),
        borderWidth: 1,
        label: operator.operatorName,
        lineTension: 0.1,
        spanGaps: false,
        pointRadius: 0,
      };
      this.chartConfig.data.datasets.push(newData);
    });
    this.setState({ showLegends: true });
    this.chart.update();
  };

  componentDidUpdate(prevProps) {
    const {
      focusedPrimaryId,
      isPrimaryBroadcasting,
      broadcastSettings,
      isBitrateChartDisplayed,
      broadcastDetails,
    } = this.props;

    if (isPrimaryBroadcasting && focusedPrimaryId  && (isBitrateChartDisplayed && !prevProps.isBitrateChartDisplayed)) {
      this.addDataToChart();
      clearInterval(this._updateChartScheduler);
      this.scheduleUpdateChart(fastIntervalSchedulerWaitTime, true);
    }

    if (prevProps.isPrimaryBroadcasting && !isPrimaryBroadcasting) {
      clearInterval(this._updateChartScheduler);
      this.clearChart();
    }

    if (isPrimaryBroadcasting && isBitrateChartDisplayed) {
      if (
        broadcastDetails.operatorsDataArr &&
        broadcastDetails.operatorsDataArr.length !== this.chartConfig.data.datasets.length - 1
      ) {
        this.clearChart();
        this.createChartData(broadcastDetails);
      }
    }

    if (focusedPrimaryId !== prevProps.focusedPrimaryId) {
      clearInterval(this._updateChartScheduler);
      this.clearChart();

      if (isPrimaryBroadcasting && isBitrateChartDisplayed && focusedPrimaryId) {
        this.addDataToChart();
        this.scheduleUpdateChart(fastIntervalSchedulerWaitTime, true);
      }
    } else if (isPrimaryBroadcasting !== prevProps.isPrimaryBroadcasting) {
      if (isPrimaryBroadcasting && isBitrateChartDisplayed) {
        this.addDataToChart();
        this.scheduleUpdateChart();
      } else {
        clearInterval(this._updateChartScheduler);
        this.chartConfig.data.datasets[0].backgroundColor = chartBgColor;
        this.chart.update();
      }
    }

    if (broadcastSettings !== prevProps.broadcastSettings) {
      if (isBitrateChartDisplayed) {
        this.chartConfig.options.scales.yAxes[0].ticks.max = this.calculateYAxesMaxVal();
        this.chart.update();
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this._updateChartScheduler);
  }

  clearChart = () => {
    this.chartConfig.data.labels = [];
    this.chartConfig.data.datasets.forEach((dataset) => {
      dataset.data = [];
    });
    this.chartConfig.data.datasets.splice(1);
    this.setState({ showLegends: false });
    this.chart.update();
  };

  showLegends = () => {
    this.setState({ showLegends: true });
  };

  scheduleUpdateChart = (intervalTime = schedulerWaitTime, switchToRegular = false) => {
    const { pointBorderWidth, pointRadius } = this.state.chartParams;

    this.chartConfig.data.datasets[0].backgroundColor = chartMainColor;
    this.chartConfig.data.datasets[0].pointBorderWidth = pointBorderWidth;
    this.chartConfig.data.datasets[0].pointRadius = pointRadius;
    this.chartConfig.options.scales.yAxes[0].ticks.max = this.calculateYAxesMaxVal();
    this.chart.update();

    this.addDataToChart();

    let updateCount = 0;
    clearInterval(this._updateChartScheduler);

    this._updateChartScheduler = setInterval(() => {
      this.addDataToChart();

      if (switchToRegular && updateCount >= fastIntervalIterations) {
        clearInterval(this._updateChartScheduler);
        this.scheduleUpdateChart();
      }

      updateCount++;
    }, intervalTime);
  };

  addDataToChart = () => {
    const { durationArr, bitrateArr, operatorsDataArr } = this.props.broadcastDetails;

    if (durationArr !== undefined && bitrateArr !== undefined) {
      let currentDurationArr, currentBitrateArr;
      const { chartTimeFrame } = this.state.chartParams;

      const chartDurationData = (time) => {
        for (let i = 0; i < this.chartConfig.data.datasets.length; i++) {
          const label = this.chartConfig.data.datasets[i].label;
          const operator = operatorsDataArr.find((item) => item.operatorName === label);
          if (operator !== undefined) {
            if (time === '5min') {
              this.chartConfig.data.datasets[i].data = operator.operatorData;
            } else {
              this.chartConfig.data.datasets[i].data = operator.operatorData.slice(time);
            }
          }
        }
      };

      const updateBitrate = (currentDurationArr, currentBitrateArr) => {
        this.chartConfig.data.labels = currentDurationArr;
        this.chartConfig.data.datasets[0].data = currentBitrateArr;
      };

      if (chartTimeFrame === '10sec') {
        currentDurationArr = durationArr.slice(-10);
        currentBitrateArr = bitrateArr.slice(-10);
        chartDurationData(-10);
      } else if (chartTimeFrame === '30sec') {
        currentDurationArr = durationArr.slice(-30);
        currentBitrateArr = bitrateArr.slice(-30);
        chartDurationData(-30);
      } else if (chartTimeFrame === '1min') {
        currentDurationArr = durationArr.slice(-60);
        currentBitrateArr = bitrateArr.slice(-60);
        chartDurationData(-60);
      } else if (chartTimeFrame === '5min') {
        currentDurationArr = durationArr;
        currentBitrateArr = bitrateArr;
        chartDurationData('5min');
      }

      updateBitrate(currentDurationArr, currentBitrateArr);
      this.chart.update();
    }
  };

  calculateYAxesMaxVal = () => {
    let yAxesMaxVal;
    if (Object.keys(this.props.broadcastSettings).length !== 0) {
      const { maxBitrate } = this.props.broadcastSettings;
      yAxesMaxVal = parseFloat(maxBitrate);
    }

    return yAxesMaxVal + 2;
  };

  handleChartButtonsClick = (e) => {
    let pointBorderWidth, pointRadius;

    if (e.target.id === '10sec') {
      pointBorderWidth = 2.5;
      pointRadius = 3;
    } else if (e.target.id === '30sec') {
      pointBorderWidth = 2;
      pointRadius = 2.5;
    } else if (e.target.id === '1min') {
      pointBorderWidth = 1.5;
      pointRadius = 2;
    } else if (e.target.id === '5min') {
      pointBorderWidth = 0.5;
      pointRadius = 0;
    }

    this.chartConfig.data.datasets[0].pointBorderWidth = pointBorderWidth;
    this.chartConfig.data.datasets[0].pointRadius = pointRadius;
    this.chart.update();

    const chartParams = {
      chartTimeFrame: e.target.id,
      pointBorderWidth,
      pointRadius,
    };

    this.setState({ chartParams }, () => {
      this.saveСhartParamsState();
    });
  };

  saveСhartParamsState = () => {
    localStorage.setItem('chartParamsStateV1', JSON.stringify(this.state.chartParams));
  };

  render() {
    const { screenSize } = this.props;
    const { chartTimeFrame } = this.state.chartParams;

    let chartWidth, chartHeight;
    if (screenSize === 'large') {
      chartWidth = 200;
      chartHeight = 70;
    } else if (screenSize === 'medium') {
      chartWidth = 200;
      chartHeight = 65;
    } else {
      chartWidth = 200;
      chartHeight = 60;
    }

    return (
      <div className="chart-bitrate chart-canvas-border">
        <div className="chart-container">
          <canvas id="chart-canvas" width={chartWidth} height={chartHeight} />
        </div>
        <div className="chart-buttons-section row ">
          <div className="chart-button-container">
            <button
              className={chartTimeFrame === '10sec' ? 'btn-general' : 'btn-general-disabled'}
              id="10sec"
              onClick={this.handleChartButtonsClick}>
              10 SEC
            </button>
          </div>
          <div className="chart-button-container">
            <button
              className={chartTimeFrame === '30sec' ? 'btn-general' : 'btn-general-disabled'}
              id="30sec"
              onClick={this.handleChartButtonsClick}>
              30 SEC
            </button>
          </div>
          <div className="chart-button-container">
            <button
              className={chartTimeFrame === '1min' ? 'btn-general' : 'btn-general-disabled'}
              id="1min"
              onClick={this.handleChartButtonsClick}>
              1 MIN
            </button>
          </div>
          <div className="chart-button-container">
            <button
              className={chartTimeFrame === '5min' ? 'btn-general' : 'btn-general-disabled'}
              id="5min"
              onClick={this.handleChartButtonsClick}>
              5 MIN
            </button>
          </div>
        </div>
        <div className="chart-legends-container">
          {this.state.showLegends &&
            this.chartConfig !== undefined &&
            this.chartConfig.data.datasets.map(
              (item, index) =>
                index !== 0 && (
                  <div key={index} className="chart-legends-content">
                    <div
                      className="legend-square"
                      style={{ backgroundColor: item.borderColor }}></div>
                    <div>{item.label}</div>
                  </div>
                ),
            )}
        </div>
      </div>
    );
  }
}

BitrateChart.propTypes = {
  focusedPrimaryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isPrimaryBroadcasting: PropTypes.oneOf([undefined, false, true]),
  broadcastSettings: PropTypes.object.isRequired,
  broadcastDetails: PropTypes.object.isRequired,
  isBitrateChartDisplayed: PropTypes.bool.isRequired,
  screenSize: PropTypes.string.isRequired,
};

export default BitrateChart;
