import React from 'react';
import PropTypes from 'prop-types';

import './Spinner.css';

const Spinner = ({ customClass, customText }) => (
	<div
		className={`${customClass
			? customClass + ' '
			: ''}container d-flex flex-column align-items-center justify-content-center`}
	>
		<div className="spinner-border text-info" />
		<h5 className="text-dark">{customText ? customText : 'Loading'} ...</h5>
	</div>
);

Spinner.propTypes = {
	customClass: PropTypes.string.isRequired,
	customText: PropTypes.string
};

export default React.memo(Spinner);
