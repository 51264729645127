import * as Yup from 'yup';
import checkoutFormModel from './guestFormModel';
import { isValidPhoneNumber } from 'react-phone-number-input';

const {
    formField: { firstName, lastName, email, phoneNumber, camera, resolution, bitrate, codec, streamType, orientation, autoStart },
} = checkoutFormModel;

const emailValidationRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export default [
    Yup.object().shape({
        [firstName.name]: Yup.string().required(`${firstName.requiredErrorMsg}`),
        [lastName.name]: Yup.string().required(`${lastName.requiredErrorMsg}`),
        [email.name]: Yup.string().matches(emailValidationRegex, 'Invalid email format').nullable(),
        [phoneNumber.name]: Yup.string()
            .required('Phone number is required')
            .test('is-valid-phone', 'Phone number is not valid', function (value) {
                const { countryCode } = this.parent;
                if (!countryCode || !value) return false;
                return isValidPhoneNumber(value, countryCode.toUpperCase());
            }),
        countryCode: Yup.string().required('Country code is required'),
    }),
    Yup.object().shape({
        [camera.name]: Yup.string().required('Camera source is required'),
        [resolution.name]: Yup.string().required('Resolution is required'),
        [bitrate.name]: Yup.number().required('Bitrate is required'),
        [codec.name]: Yup.string().required('Codec is required'),
        [streamType.name]: Yup.string().required('Profile is required'),
        [orientation.name]: Yup.string().required('Orientation is required'),
        [autoStart.name]: Yup.boolean(),
    }),
];
