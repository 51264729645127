import React from 'react';
import PropTypes from 'prop-types';

import './MapMarker.css';

const MapMarker = ({ lat, lng, imgSrc, primaryName }) => {
	return (
		<div style={{ transform: 'translate(-50%, -50%)' }} className="map-marker-container" lat={lat} lng={lng}>
			<span className="marker-image-container">
				<img src={imgSrc} alt="Omnistream logo" />
			</span>
			<span>{primaryName}</span>
		</div>
	);
};

MapMarker.propTypes = {
	lat: PropTypes.number.isRequired,
	lng: PropTypes.number.isRequired,
	imgSrc: PropTypes.string.isRequired,
	primaryName: PropTypes.string.isRequired
};

export default React.memo(MapMarker);
