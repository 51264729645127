import React from 'react';
import PropTypes from 'prop-types';

import './MultiViewer.css';

import Spinner from '../common/Spinner/Spinner';
import Viewer from '../Viewer/Viewer';
import CriticalIssueContainer from '../CriticalIssueContainer/CriticalIssueContainer';

const MultiViewer = ({
	isWebSocketConnected,
	focusedPrimaryId,
	primaryUsers,
	arePrimaryUsersLoading,
	videoServerStatus,
	primariesBroadcastDetails,
	screenSize,
	handlePrimaryFocus,
	setDraggedElement,
	handleDrop,
	setDetailsForModal,
	onAttachPrimary,
	onDettachPrimary,
	audioDevices,
	selectedDevice,
	companyType,
	isPrimaryBroadcasting,
	streamType,
	clickStartStop,
	isChangingStartStopInProgress,
	showNotification,
	setShareId,
	setPrimaryToDelete
}) => {
	let issueElementToDisplay = null;
	let sortedPrimaryUsers;

	if (videoServerStatus.generalStatus === 'ERROR') {
		issueElementToDisplay = (
			<CriticalIssueContainer>
				{videoServerStatus.errors &&
					videoServerStatus.errors[0].Description}
			</CriticalIssueContainer>
		);
	} else if (isWebSocketConnected === false) {
		issueElementToDisplay = (
			<Spinner
				customClass="multi-viewer-spinner"
				customText="Connection to server is lost. Trying to reconnect"
			/>
		);
	} else if (arePrimaryUsersLoading === true) {
		issueElementToDisplay = <Spinner customClass="multi-viewer-spinner" />;
	} else {
		sortedPrimaryUsers = primaryUsers.sort((obj1, obj2) => obj1.id - obj2.id);
	}

	return (
		<div className="multi-viewer container-fluid mviewer-scrollbar">
			<div className="row">
				{issueElementToDisplay ? (
					issueElementToDisplay
				) : (
					sortedPrimaryUsers.map(
						({
							id,
							username,
							displayedName,
							isActive,
							isBroadcasting,
							statuses,
							outputAddressId,
							daysToExpire,
							showNotification,
							licensePermission,
							licensePermissionId,
							licenseTypeId,
							licenseSource,
						}) => (
							<Viewer
								key={id}
								focusedPrimaryId={focusedPrimaryId}
								primaryId={id}
								primaryName={username}
								displayedName={displayedName}
								isActive={isActive}
								isBroadcasting={isBroadcasting}
								statuses={statuses}
								broadcastDetails={primariesBroadcastDetails.find(
									(obj) => obj.primaryId === id
								)}
								screenSize={screenSize}
								handlePrimaryFocus={handlePrimaryFocus}
								setDraggedElement={setDraggedElement}
								handleDrop={handleDrop}
								setDetailsForModal={setDetailsForModal}
								outputAddressId={outputAddressId}
								daysToExpire={daysToExpire}
								onAttachPrimary={onAttachPrimary}
								onDettachPrimary={onDettachPrimary}
								audioDevices={audioDevices}
								selectedDevice={selectedDevice}
								companyType={companyType}
								isPrimaryBroadcasting={isPrimaryBroadcasting}
								streamType={streamType}
								clickStartStop={clickStartStop}
								isChangingStartStopInProgress={isChangingStartStopInProgress}
								showNotification={showNotification}
								setShareId={setShareId}
								setPrimaryToDelete={setPrimaryToDelete}
								primaryUsers={primaryUsers}
								licensePermission={licensePermission}
								licensePermissionId={licensePermissionId}
								licenseTypeId={licenseTypeId}
								licenseSource={licenseSource}
							/>
						)
					)
				)}
			</div>
		</div>
	);
};

MultiViewer.propTypes = {
	isWebSocketConnected: PropTypes.bool.isRequired,
	primaryUsers: PropTypes.array.isRequired,
	arePrimaryUsersLoading: PropTypes.bool.isRequired,
	videoServerStatus: PropTypes.object.isRequired,
	primariesBroadcastDetails: PropTypes.array.isRequired
};

export default React.memo(MultiViewer);
