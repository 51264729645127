import React from 'react';
import PropTypes from 'prop-types';

import './MapCard.css';

import LightIndicator from '../LightIndicator/LightIndicator';
import Button from '../common/Button/Button';

const MapCard = ({
	primaryId,
	primaryName,
	isActive,
	isBroadcasting,
	focusedPrimaryId,
	screenSize,
	handlePrimaryFocus,
	locatePrimary
}) => {
	return (
		<div
			className={`map-card card shadow-sm${focusedPrimaryId === primaryId
				? ' selected'
				: ''}`}
			onClick={() => handlePrimaryFocus(primaryId)}
			
		>
			{/* <div
				className={`card-header${focusedPrimaryId === primaryId	? ' bg-info ': ''}`}
			> */}
			<div 
				className={`card-header${focusedPrimaryId === primaryId	? ' bg-info-border ': '' }`} 
			>
				<a
					className={`card-link${!focusedPrimaryId === primaryId
						? ' collapsed'
						: ''}`}
					data-toggle="collapse"
					href={`#${primaryId}`}
				>
					<div className="d-flex justify-content-between align-items-center ">
						<span className="d-flex align-items-center ml-1 ">
							{primaryName}
						</span>
						<span className="status-icon mr-1 mb-1">
							<LightIndicator
								type="Primary"
								name={primaryName}
								isActive={isActive}
								isBroadcasting={isBroadcasting}
								componentLocation="map"
								screenSize={screenSize}
							/>
						</span>
					</div>
				</a>
			</div>

			<div
				id={primaryId}
				className={`collapse${primaryId === focusedPrimaryId ? ' show' : ''}`} 
				data-parent="#map-accordion "
			>
				<div className="card-body d-flex justify-content-between px-3 card-map-color" >
					<Button
						iconName="location_searching"
						isDisabled={!isActive}
						clickHandler={locatePrimary}
					/>
					<div className="btn-group ">
						{/* <Button
							// iconName={isIfbChannelOpen ? 'mic' : 'mic_none'}
							isDisabled={!isPrimaryActive}
							// clickHandler={clickIFBmouseClick}
							mouseDownHandler={clickIFBmouseDown}
						/>
						<Button
							bsClassSuffix="secondary"
							// iconName={isIfbButtonLocked ? 'lock' : 'lock_open'}
							// isDisabled={!isPrimaryActive || isIfbChannelOpen}
						//	clickHandler={toggleIFBbuttonLock}
						/> */}
						{/* <button
							id="wrench"
							className="btn btn-outline-secondary btn-sm 
                        d-flex justify-content-center align-items-center"
							disabled={!isPrimaryActive}
							data-toggle="modal"
							data-target="#ifb-modal"
						>
							<i className="material-icons">build</i>
						</button> */}
					</div>
				</div>
			</div>
		</div>
	);
};

MapCard.propTypes = {
	primaryId: PropTypes.number.isRequired,
	primaryName: PropTypes.string.isRequired,
	focusedPrimaryId: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
		.isRequired,
	handlePrimaryFocus: PropTypes.func.isRequired
};

export default React.memo(MapCard);
