import React from 'react';
import PropTypes from 'prop-types';

const RadioButton = ({
	radioGroupName,
	radioId,
	radioValue,
	changeHandler,
	labelText
}) => (
	<div className="custom-control custom-radio custom-control-inline">
		<input
			type="radio"
			className="custom-control-input"
			name={radioGroupName}
			id={radioId}
			value={radioValue}
			onChange={changeHandler}
		/>
		<label className="custom-control-label" htmlFor={radioId}>
			{labelText}
		</label>
	</div>
);

RadioButton.propTypes = {
	radioGroupName: PropTypes.string.isRequired,
	radioId: PropTypes.string.isRequired,
	radioValue: PropTypes.string.isRequired,
	changeHandler: PropTypes.func.isRequired,
	labelText: PropTypes.any.isRequired
};

export default React.memo(RadioButton);
