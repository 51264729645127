import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';

import './Notification.css';

class Notification extends React.PureComponent {
	_fadeTime = 500;

	componentDidUpdate(prevProps) {
		const { isNotificationDisplayed } = this.props;
		if (isNotificationDisplayed !== prevProps.isNotificationDisplayed) {
			if (isNotificationDisplayed === true) {
				$('#notification').fadeIn(this._fadeTime);
			} else {
				$('#notification').fadeOut(this._fadeTime);
			}
		}
	}

	render() {
		const {
			notificationMsg,
			hideNotification
		} = this.props;

		// let notificationText;
		// if (currentNotificationType === 'output_connect') {
		// 	notificationText = (
		// 		<span>
		// 			Primary "<strong>{primaryName}</strong>" is connected to "<strong>{outputName}</strong>"
		// 		</span>
		// 	);
		// } else if (currentNotificationType === 'output_disconnect') {
		// 	notificationText = (
		// 		<span>
		// 			Primary "<strong>{primaryName}</strong>" is disconnected from output
		// 		</span>
		// 	);
		// } else if (currentNotificationType === 'output_blocked') {
        //                 notificationText = (
        //                         <span>
        //                                 Primary "<strong>{primaryName}</strong>" has no suffienct permissions to connect with "<strong>{outputName}</strong>"
        //                         </span>
        //                 );
        //         } else if (currentNotificationType === 'guid_copy') {
		// 	notificationText = <span>GUID was copied to clipboard</span>;
		// }else if (currentNotificationType === 'export') {
		// 	notificationText = <span>Failed to add export</span>;
		// }else if (currentNotificationType === 'set_designated') {
		// 	notificationText = <span>Failed to set designated output</span>;
		// }else if (currentNotificationType === 'general_copy') {
		// 	notificationText = <span>Copied to clipboard</span>;
		// }else if (currentNotificationType === 'invalid_url') {
		// 	notificationText = <span>Inavalid url</span>;
		// }
		let notificationText = <span>{notificationMsg}</span>;
		return (
			<div
				id="notification"
				className="alert alert-warning border border-warning"
			>
				{notificationText}
				<button className="close ml-2" onClick={hideNotification}>
					<i className="material-icons" id="close_btn">close</i>
				</button>
			</div>
		);
	}
}

Notification.propTypes = {
	isNotificationDisplayed: PropTypes.bool.isRequired,
	currentNotificationType: PropTypes.string.isRequired,
	hideNotification: PropTypes.func.isRequired
};

export default Notification;
