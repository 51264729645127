import React from 'react';
import PropTypes from 'prop-types';
import './InputField.css';

const InputField = ({
  uniqueId,
  labelName,
  labelTextWeight,
  type,
  value,
  changeHandler,
  isDisabled,
  reference,
  customElement // Add the customElement prop
}) => (
  <div className="text-input form-input">
    <label
      className={`font-weight-${labelTextWeight === 'bold' ? 'bold' : 'normal'}`}
      htmlFor={uniqueId}
    >
      {labelName}
    </label>
    <div className="input-container">
      <input
        id={uniqueId}
        className="form-control"
        type={type}
        value={value}
        onChange={changeHandler}
        disabled={isDisabled}
        ref={reference}
      />
      {customElement && <div className="custom-element">{customElement}</div>}
    </div>
  </div>
);

InputField.propTypes = {
  uniqueId: PropTypes.string.isRequired,
  labelName: PropTypes.string.isRequired,
  labelTextWeight: PropTypes.string,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  changeHandler: PropTypes.func.isRequired,
  isDisabled: PropTypes.oneOf([undefined, false, true]),
  reference: PropTypes.object,
  customElement: PropTypes.element // Add the customElement prop type
};

export default React.memo(InputField);
