import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import $ from 'jquery';

import './SettingsControl.css';

import availableBroadcastSettings from '../../constants/availableBroadcastSettings';

import Spinner from '../common/Spinner/Spinner';
import SelectInput from '../common/SelectInput/SelectInput';
import Button from '../common/Button/Button';
import Modal from '../common/Modal/Modal';
import OutputAssociation from '../OutputAssociation/OutputAssociation';
import { addUserIdToLocalStorage } from '../../utils/codecHandlers';
import config from '../../dashboard_config.json'

class SettingsControl extends React.PureComponent {
  state = {
    broadcastSettings: {},
    prevBroadcastSettings: {},
    currentOutput: {},
    isSettingsFormEdited: false,
    isSettingApplying: false,
    isVideoSettingsDisplayed: true,
    isSessionSettingsDisplayed: false,
    isOutputSettingsDisplayed: false,
    pendingFirstUpdate: true,
    isSettingsFormAutoEdited: false,
    isAccsoonCameraSelected: false,
  };

  componentDidMount() {
    const { outputs, focusedPrimaryId, broadcastSettings } = this.props;
    const currentOutput = outputs.find(
      (output) => output.primaryId === focusedPrimaryId
    );

    const isAccsoonCameraSelected = broadcastSettings['camera'] === 'external-adapter-accsoon-seemo-4k';

    this.setState({
      isSettingsFormEdited: false,
      isSettingApplying: false,
      currentOutput: currentOutput ? currentOutput : {},
      isAccsoonCameraSelected,
    }, () => {
      this.forceUpdate();
    });
  }

  componentDidUpdate(prevProps) {
    const {
      outputs,
      focusedPrimaryId,
      isPrimaryBroadcasting,
      broadcastSettings,
      socialMediaSettings,
    } = this.props;

    if (broadcastSettings['camera'] !== prevProps.broadcastSettings['camera']) {
      const isAccsoonCameraSelected = broadcastSettings['camera'] === 'external-adapter-accsoon-seemo-4k';
      this.setState({ isAccsoonCameraSelected });
    }

    if (this.state.isSettingsFormAutoEdited) {
      this.handleSettingsApply();
      this.setState({ isSettingsFormAutoEdited: false });
    }

    if (
      focusedPrimaryId !== prevProps.focusedPrimaryId ||
      outputs.length !== prevProps.outputs.length
    ) {
      const currentOutput = outputs.find(
        (output) => output.primaryId === focusedPrimaryId
      );

      this.setState({
        isSettingsFormEdited: false,
        isSettingApplying: false,
        currentOutput: currentOutput ? currentOutput : {},
        isVideoSettingsDisplayedInit: this.state.isVideoSettingsDisplayed,
        isSessionSettingsDisplayedInit: this.state.isSessionSettingsDisplayed,
        isOutputSettingsDisplayedInit: this.state.isOutputSettingsDisplayed,
      });
    }

    if (isPrimaryBroadcasting !== prevProps.isPrimaryBroadcasting) {
      if (
        isPrimaryBroadcasting === true &&
        this.state.isSettingsFormEdited === true
      ) {
        this.handleSettingsRevert();
      }
    }

    if (
      broadcastSettings !== prevProps.broadcastSettings ||
      this.state.pendingFirstUpdate
    ) {
      if (Object.keys(broadcastSettings).length === 0) {
        this.setState({
          broadcastSettings: {},
          prevBroadcastSettings: {},
          isSettingApplying: false,
          pendingFirstUpdate: false,
        });
      } else {
        let newBroadcastSettings = { ...this.state.broadcastSettings };
        for (let property in broadcastSettings) {
          if (newBroadcastSettings[property] !== broadcastSettings[property]) {
            newBroadcastSettings[property] = broadcastSettings[property];
          }
        }
        this.setState({
          broadcastSettings: newBroadcastSettings,
          prevBroadcastSettings: newBroadcastSettings,
          isSettingApplying: false,
          pendingFirstUpdate: false,
        });
      }
    }

    if (
      broadcastSettings.codec === 'H.265' &&
      socialMediaSettings.isRtmpEnabled
    ) {
      this.debouncedUpdateSocialMediaSettings();
    }
  }

  debouncedUpdateSocialMediaSettings = debounce(() => {
    this.props.updateSocialMediaSettings(
      false,
      this.props.socialMediaSettings.psk
    );
  }, 150);

  handleSettingsInputChange = (e) => {
    const { isRtmpEnabled } = this.props.socialMediaSettings;

    if (
      isRtmpEnabled === true &&
      e.target.id === 'codec' &&
      e.target.value === 'H.265'
    ) {
      $('#codec-rtmp-conflict-modal').modal();
    }

    const broadcastSettings = { ...this.state.broadcastSettings };
    broadcastSettings[e.target.id] = e.target.value;

    // handle input change for accsoon camera
    let isAccsoonCameraSelected = this.state.isAccsoonCameraSelected;
    if (e.target.id === 'camera') {
      isAccsoonCameraSelected = e.target.value === 'external-adapter-accsoon-seemo-4k';
      this.setState({ isAccsoonCameraSelected });
    }

    if (e.target.id === 'output') {
      const output = this.props.outputs.find(
        (output) => output.name === e.target.value
      );
      this.setState({
        currentOutput: output,
      });
    }

    this.setState({
      broadcastSettings,
      isSettingsFormEdited: true,
    });
  };

  handleSettingsApply = () => {
    const { broadcastSettings, prevBroadcastSettings } = this.state;
    let areBroadcastSettingsChanged = false;
    let isOutputChanged = false;
    for (let property in broadcastSettings) {
      if (broadcastSettings[property] !== prevBroadcastSettings[property]) {
        if (property === 'output') {
          isOutputChanged = true;
        } else {
          areBroadcastSettingsChanged = true;
        }
      }
    }

    if (areBroadcastSettingsChanged === true) {
      this.setState({
        ...this.state,
        prevBroadcastSettings: this.state.broadcastSettings,
        isSettingsFormEdited: false,
        isSettingApplying: true,
      });
      const newBroadcastSettings = { ...this.state.broadcastSettings };
      addUserIdToLocalStorage(this.props.focusedPrimaryId);
      delete newBroadcastSettings['output'];
      this.props.clickSettingsApply(newBroadcastSettings);
    } else {
      this.setState({ isSettingsFormEdited: false });
    }

    if (isOutputChanged === true) {
      const { outputs, focusedPrimaryId } = this.props;
      const output =
        outputs[
        outputs.findIndex(
          (output) => output.name === broadcastSettings['output']
        )
        ];

      this.setState(
        {
          isSettingsFormEdited: false,
          currentOutput: output,
        },
        () => {
          this.props.associatePrimaryWithOutput(
            focusedPrimaryId,
            this.state.currentOutput ? this.state.currentOutput.id : null
          );
        }
      );
    }
  };

  handleSettingsRevert = () => {
    this.setState({
      ...this.state,
      broadcastSettings: this.state.prevBroadcastSettings,
      isSettingsFormEdited: false,
    });
  };

  handleAccordionHeaderClick = (e) => {
    if (e.currentTarget.id === 'video-settings-header') {
      this.setState({
        ...this.state,
        isVideoSettingsDisplayed: !this.state.isVideoSettingsDisplayed,
        isSessionSettingsDisplayed: false,
        isOutputSettingsDisplayed: false,
      });
    } else if (e.currentTarget.id === 'session-settings-header') {
      this.setState({
        ...this.state,
        isVideoSettingsDisplayed: false,
        isSessionSettingsDisplayed: !this.state.isSessionSettingsDisplayed,
        isOutputSettingsDisplayed: false,
      });
    } else if (e.currentTarget.id === 'output-settings-header') {
      this.setState({
        ...this.state,
        isVideoSettingsDisplayed: false,
        isSessionSettingsDisplayed: false,
        isOutputSettingsDisplayed: !this.state.isOutputSettingsDisplayed,
      });
    }
  };

  setStreamTypesToHideBasedOnDeviceOS = (deviceOS) => {
    // Retrieve session settings restrictions from the config.
    const { sessionSettingsRestrictions } = config.dashboard.streamControlOptions;

    const streamTypesToHide =
      (sessionSettingsRestrictions &&
        sessionSettingsRestrictions[deviceOS] &&
        sessionSettingsRestrictions[deviceOS].streamTypeOptionsToHide) || [];

    return streamTypesToHide;
  };

  setSessionSettingsToRestrictBasedOnDevice = (deviceOS) => {
    // Restrict the following options: "videoFluency", "broadcastType" as they are not supported on iPhone yet
    const { sessionSettingsRestrictions } = config.dashboard.streamControlOptions;

    const sessionOptionsToDisable =
      (sessionSettingsRestrictions &&
        sessionSettingsRestrictions[deviceOS] &&
        sessionSettingsRestrictions[deviceOS].sessionOptionsToDisable) || [];

    return sessionOptionsToDisable;
  };

  setCameraTypesToHideBasedOnDeviceOS = (deviceOS) => {
    // disable UVC and Kiloview for iPhone, disable Accsoon for Android - controled from config file
    const { sourceSettingsRestrictions } = config.dashboard.streamControlOptions;

    const cameraTypesToHide =
      (sourceSettingsRestrictions &&
        sourceSettingsRestrictions[deviceOS] &&
        sourceSettingsRestrictions[deviceOS].cameraTypesToHide) || [];

    return cameraTypesToHide;
  }

  setAccsoonCameraSessionRestrictions = (isAccsoonCameraSelected, deviceOS) => {
    if (!isAccsoonCameraSelected) return [];

    // Disable the following options: "scanMode", "resolution", "codec", "frameRate" as they are autmoaticlly defined from Accsoon
    const { sourceSettingsRestrictions } = config.dashboard.streamControlOptions;
    const sessionSettingsToDisableForAccsoon =
      (sourceSettingsRestrictions[deviceOS] &&
        sourceSettingsRestrictions[deviceOS].cameraRestrictions &&
        sourceSettingsRestrictions[deviceOS].cameraRestrictions.accsoonSeeMo4k &&
        sourceSettingsRestrictions[deviceOS].cameraRestrictions.accsoonSeeMo4k.settingsToDisable) || [];

    return sessionSettingsToDisableForAccsoon;
  }
  
  render() {
    const {
      isPrimaryBroadcasting,
      areBroadcastSettingsLoading,
      primaryUsers,
      screenSize,
      outputs,
      focusedPrimaryId,
      associatePrimaryWithOutput,
      companyType,
    } = this.props;
    const {
      broadcastSettings,
      isSettingsFormEdited,
      isSettingApplying,
      isVideoSettingsDisplayedInit,
      isSessionSettingsDisplayedInit,
      isOutputSettingsDisplayedInit,
      isVideoSettingsDisplayed,
      isSessionSettingsDisplayed,
      isOutputSettingsDisplayed,
      currentOutput,
      isAccsoonCameraSelected,
    } = this.state;

    const user = primaryUsers.find(user => user.id === focusedPrimaryId);
    const deviceOS = user ? user.deviceOS : null;

    let marginStr;
    if (screenSize === 'large') {
      marginStr = 'mt-2';
    } else {
      marginStr = 'mt-2';
    }

    return (
      <div className="settings-control">
        {areBroadcastSettingsLoading ? (
          <Spinner customClass="settings-control-spinner" />
        ) : (
          <div>
            <div id="settings-accordion">
              <div className="card">
                <div
                  className={`${focusedPrimaryId ? 'card-header' : 'card-header'
                    }`}
                >
                  {/* <div className={`${isPrimaryFocused ? 'card-header'	: 'card-header-disabled'}`}> */}
                  <a
                    id="video-settings-header"
                    onClick={this.handleAccordionHeaderClick}
                    className="card-link d-flex justify-content-between align-items-center card-border"
                    data-toggle="collapse"
                    href="#video-settings"
                  >
                    <span className="drawer-text">
                      <i className="icon-size material-icons">
                        settings_input_svideo
                      </i>
                      Source
                    </span>
                    <i className="material-icons arrow-icon">
                      {`arrow_drop_${isVideoSettingsDisplayed ? 'up' : 'down'}`}
                    </i>
                  </a>
                </div>
                <div
                  id="video-settings"
                  className={
                    isVideoSettingsDisplayedInit ? 'collapse show' : 'collapse'
                  }
                  data-parent="#settings-accordion"
                >
                  <div className="card-body">
                    {/*eslint-disable-next-line*/}
                    {availableBroadcastSettings.map(
                      ({ type, id, valuesArray, name, isDynamic }) => {
                        if (type === 'video_setting') {
                          const currentValue = broadcastSettings[id] || '';

                          let valuesArrayToDisplay = currentValue
                            ? valuesArray
                            : [];

                          if (id === 'camera') {
                            const cameraTypesToHide = this.setCameraTypesToHideBasedOnDeviceOS(deviceOS);
                            valuesArrayToDisplay = valuesArrayToDisplay.filter(option => !cameraTypesToHide.includes(option.value));
                          }

                          const isDisabled = (isPrimaryBroadcasting && !isDynamic) || this.setAccsoonCameraSessionRestrictions(isAccsoonCameraSelected, deviceOS).includes(id);

                          return (
                            <SelectInput
                              key={id}
                              uniqueId={id}
                              labelName={name}
                              valuesArray={valuesArrayToDisplay}
                              currentValue={currentValue}
                              isDisabled={isDisabled}
                              changeHandler={this.handleSettingsInputChange}
                            />
                          );
                        }
                        return [];
                      }
                    )}
                  </div>
                </div>
              </div>

              <div className="card">
                <div
                  className={`${focusedPrimaryId ? 'card-header' : 'card-header'
                    }`}
                >
                  {/* <div className={`${isPrimaryFocused ? 'card-header'	: 'card-header-disabled'}`}> */}
                  <a
                    id="session-settings-header"
                    onClick={this.handleAccordionHeaderClick}
                    className="card-link collapsed d-flex justify-content-between align-items-center"
                    data-toggle="collapse"
                    href="#session-settings"
                  >
                    <span className="drawer-text">
                      <i className="icon-size material-icons">tune</i>Session
                    </span>
                    <i className="material-icons arrow-icon">
                      {`arrow_drop_${isSessionSettingsDisplayed ? 'up' : 'down'
                        }`}
                    </i>
                  </a>
                </div>
                <div
                  id="session-settings"
                  className={
                    isSessionSettingsDisplayedInit
                      ? 'collapse show'
                      : 'collapse'
                  }
                  data-parent="#settings-accordion"
                >
                  <div className="card-body">
                    {/*eslint-disable-next-line*/}
                    {availableBroadcastSettings.map(
                      ({ type, id, valuesArray, name }) => {
                        if (type === 'session_setting') {
                          const currentValue = broadcastSettings[id] || '';

                          let valuesArrayToDisplay = currentValue
                            ? valuesArray
                            : [];

                          if (id === 'streamType') {
                            const streamTypesToHide = this.setStreamTypesToHideBasedOnDeviceOS(deviceOS);
                            valuesArrayToDisplay = valuesArrayToDisplay.filter(option =>
                              !streamTypesToHide.includes(option.value)
                            );
                          }

                          const isDisabled = isPrimaryBroadcasting || this.setSessionSettingsToRestrictBasedOnDevice(deviceOS).includes(id);

                          return (
                            <SelectInput
                              key={id}
                              uniqueId={id}
                              labelName={name}
                              valuesArray={valuesArrayToDisplay}
                              currentValue={currentValue}
                              isDisabled={isDisabled}
                              changeHandler={this.handleSettingsInputChange}
                            />
                          );
                        }
                        return [];
                      }
                    )}
                  </div>
                </div>
              </div>

              <div className={`${companyType === 0 ? 'card' : 'invisible'}`}>
                <div
                  className={`${focusedPrimaryId ? 'card-header' : 'card-header'
                    }`}
                >
                  {/* <div className={`${isPrimaryFocused ? 'card-header'	: 'card-header-disabled'}`}> */}
                  <a
                    id="output-settings-header"
                    onClick={this.handleAccordionHeaderClick}
                    className="card-link collapsed d-flex justify-content-between align-items-center"
                    data-toggle="collapse"
                    href="#output-settings"
                  >
                    <span className="drawer-text">
                      <i className="icon-size material-icons">exit_to_app</i>
                      Output
                    </span>
                    <i className="material-icons arrow-icon">
                      {`arrow_drop_${isOutputSettingsDisplayed ? 'up' : 'down'
                        }`}
                    </i>
                  </a>
                </div>
                <div
                  id="output-settings"
                  className={
                    isOutputSettingsDisplayedInit ? 'collapse show' : 'collapse'
                  }
                  data-parent="#settings-accordion"
                >
                  <div className="card-body">
                    <OutputAssociation
                      outputs={outputs}
                      focusedPrimaryId={focusedPrimaryId}
                      associatePrimaryWithOutput={associatePrimaryWithOutput}
                      currentOutput={currentOutput ? currentOutput.name : ''}
                      handleOutputChange={this.handleSettingsInputChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={`d-flex justify-content-center ${marginStr}`}>
              <div className="mr-2">
                <Button
                  uniqueId="settings-revert-btn"
                  iconName="undo"
                  text="Revert"
                  isDisabled={!isSettingsFormEdited}
                  clickHandler={this.handleSettingsRevert}
                />
              </div>

              <div className="mr-2">
                <Button
                  uniqueId="settings-apply-btn"
                  iconName={!isSettingApplying ? 'check' : ''}
                  showSpinner={isSettingApplying}
                  text={'Apply'}
                  isDisabled={!isSettingsFormEdited}
                  clickHandler={this.handleSettingsApply}
                />
              </div>
            </div>
          </div>
        )}

        <Modal
          uniqueId="codec-rtmp-conflict-modal"
          headerTitle="Pay attention"
          showExclamationIcon={true}
          buttons={[{ name: 'Close', className: 'info' }]}
        >
          <p>RTMP streaming can not be done when H.265 codec is used.</p>
          <p>Clicking "Apply" button below will disable RTMP streaming.</p>
        </Modal>
      </div>
    );
  }
}

SettingsControl.propTypes = {
  focusedPrimaryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  isPrimaryBroadcasting: PropTypes.oneOf([undefined, false, true]),
  broadcastSettings: PropTypes.object.isRequired,
  areBroadcastSettingsLoading: PropTypes.bool.isRequired,
  socialMediaSettings: PropTypes.object.isRequired,
  screenSize: PropTypes.string.isRequired,
  clickSettingsApply: PropTypes.func.isRequired,
  updateSocialMediaSettings: PropTypes.func.isRequired,
  outputs: PropTypes.array.isRequired,
  associatePrimaryWithOutput: PropTypes.func.isRequired,
  companyType: PropTypes.number,
};

export default SettingsControl;
