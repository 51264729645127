import React from "react";
import Login from "../Login/Login";
import Main from "../Main/Main";

class App extends React.Component {
  state = {
    isControllerLoggedIn: true,
  };

  componentDidMount() {

  }

  setLogin = (data) => {
    this.setState({ isControllerLoggedIn: true,
                    companyType: data.type });
  };

  setLogout = () => {
    this.setState({ isControllerLoggedIn: false });
  };

  render() {
    let screenSize;
    if (window.matchMedia("(min-width: 1601px)").matches) {
      screenSize = "large";
    } else if (window.matchMedia("(min-width: 1367px)").matches) {
      screenSize = "medium";
    } else {
      screenSize = "small";
    }
    return (
      <div className="app">
        {this.state.isControllerLoggedIn ? (
          <Main
          companyType={this.state.companyType}
          screenSize={screenSize}
          setLogout={this.setLogout} />
        ) : (
          <Login screenSize={screenSize} setLogin={this.setLogin} />
        )}
      </div>
    );
  }
}

export default App;
