import React from 'react';
import PropTypes from 'prop-types';

const TitleWithUsername = ({ title, primaryName }) => (
	<h6 className="text-center">
		{primaryName ? (
			<span>
				{primaryName} {title}
			</span>
		) : (
			title
		)}
	</h6>
);

TitleWithUsername.propTypes = {
	title: PropTypes.string.isRequired,
	primaryName: PropTypes.string.isRequired
};

export default React.memo(TitleWithUsername);
