import React from 'react';

import './AlarmsAndAlerts.css';

import NavigationTabsContainer from '../common/NavigationTabsContainer/NavigationTabsContainer';
import Alarms from '../Alarms/Alarms';
import Logs from '../Logs/Logs';

const AlarmsAndAlerts = ({
    primaryName,
    logs,
    alarmsVideo,
	alarmsPrimary,
	focusedPrimaryId,
    clickLogsClear,
	isPrimaryBroadcasting,
}) => (
	<NavigationTabsContainer
		tabsContainerClass="alarms-and-alerts alarms-and-alerts-padding"
		tabsContainerId="alarmsAndAlerts"
	>

		<Alarms
			componentId="alarms"
			tabName="Alerts"
			tabIcon='warning'
			primaryName={primaryName}
			alarmsVideo={alarmsVideo}
			alarmsPrimary={alarmsPrimary}
			focusedPrimaryId={focusedPrimaryId}
		/>

		<Logs
			componentId="logs"
			tabName="Logs"
			tabIcon='text_snippet'
			primaryName={primaryName}
			logs={logs}
			clickLogsClear={clickLogsClear}
			isPrimaryBroadcasting={isPrimaryBroadcasting}
		/>
	</NavigationTabsContainer>
);

export default React.memo(AlarmsAndAlerts);