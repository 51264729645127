import React from 'react';
import PropTypes from 'prop-types';

class LightIndicator extends React.PureComponent {
	state = {
		color: ''
	};

	componentDidMount() {
		const { type, isActive, isThereIssue, isBroadcasting } = this.props;

		if (type === 'Primary') {
			if (isBroadcasting === true) {
				this.setState({ color: 'red' });
			} else if (isActive === true) {
				this.setState({ color: 'green' });
			}
		}

		if (type === 'Output') {
			if (isThereIssue === true) {
				this.setState({ color: 'yellow' });
			} else if (isActive === true) {
				this.setState({ color: 'green' });
			}
		}
	}

	componentDidUpdate(prevProps) {
		const { type, isActive, isThereIssue, isBroadcasting } = this.props;

		if (type === 'Primary') {
			if (isActive !== prevProps.isActive) {
				if (isBroadcasting === false) {
					const color = isActive ? 'green' : '';
					this.setState({ color });
				}
			}

			if (isBroadcasting !== prevProps.isBroadcasting) {
				if (isBroadcasting === true) {
					this.setState({ color: 'red' });
				} else {
					const color = isActive ? 'green' : '';
					this.setState({ color });
				}
			}
		}

		if (type === 'Output') {
			if (isActive !== prevProps.isActive) {
				if (isThereIssue === false) {
					const color = isActive ? 'green' : '';
					this.setState({ color });
				}
			}

			if (isThereIssue !== prevProps.isThereIssue) {
				if (isThereIssue === true) {
					this.setState({ color: 'yellow' });
				} else {
					const color = isActive ? 'green' : '';
					this.setState({ color });
				}
			}
		}
	}

	render() {
		const { screenSize } = this.props;
		let imageSize;
		if (screenSize === 'large') {
			imageSize = 20;
		} else if (screenSize === 'medium') {
			imageSize = 18;
		} else {
			imageSize = 16;
		}

		return (
			<img
				src={`./images/${this.state.color}_light.png`}
				className="light-indicator"
				alt=""
				height={imageSize}
				width={imageSize}
			/>
		);
	}
}

LightIndicator.propTypes = {
	type: PropTypes.string.isRequired,
	isActive: PropTypes.bool.isRequired,
	isBroadcasting: PropTypes.oneOf([ undefined, false, true ]),
	isThereIssue: PropTypes.oneOf([ undefined, false, true ]),
	screenSize: PropTypes.string.isRequired
};

export default LightIndicator;
