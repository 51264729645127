import React, { useMemo } from 'react';
import { Switch, Route, Redirect, HashRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from 'axios';
import MediaStreamRecorder from 'msr';

import Header from '../Header/Header';
import Dashboard from '../Dashboard/Dashboard';
import Map from '../Map/Map';
import IfbModal from '../IfbModal/IfbModal';

import { APIs, webSocketEndPoint } from '../../constants/endPointsURLs';
import { LicensePermissions } from '../LicensePermissions/LicensePermissions';

import {
  DASHBOARD_TO_PRIMARY_MSG,
  DASHBOARD_TO_PRIMARIES_MSG,
  PRIMARY_TO_DASHBOARD_MSG,
  DASHBOARD_TO_CENTRAL_SERVER_MSG,
  CENTRAL_SERVER_TO_DASHBOARD_MSG,
  KEEP_ALIVE_MSG,
  IFB_MSG,
  START_SESSION_MSG,
  STOP_SESSION_MSG,
  GET_PRIMARY_SETTINGS_MSG,
  SET_PRIMARY_SETTINGS_MSG,
  SET_DESIGNATED_OUTPUT,
  DIALOG_ALERT_RESPONSE,
  PrimaryToDashboardMsgSubtypes,
  alertsTypes,
  alertDialogType,
  GET_DEVICE_RECORDS,
  START_RECORD_UPLOAD,
  PAUSE_RECORD_UPLOAD,
  DELETE_RECORD,
} from '../../constants/webSocketMsgsTypesMap';

import {
  shortToByteArray,
  longToByteArray,
  intToByteArray,
} from '../../utils/ConvertToByteArray';

import availableBroadcastSettings from '../../constants/availableBroadcastSettings';
import Modal from '../common/Modal/Modal';

//--------------- Main.js local constants ---------------//
const MAX_LOGS_PER_PRIMARY = 100;
const SPACEBAR_KEY_CODE = 32;
//-------------------------------------------------------//

let availableBroadcastSettingsObj = {};
availableBroadcastSettings.forEach((obj) => {
  availableBroadcastSettingsObj[obj.id] = obj.valuesArray;
});

class Main extends React.Component {
  state = {
    broadcastDetailsJsonObject: {},
    companyName: 'user',
    adminFirstName: 'user',
    adminLastName: 'user',
    primaryUsers: [],
    primariesDisplayedNames: {},
    arePrimaryUsersLoading: true,
    outputs: [],
    outputsDisplayedNames: {},
    areOutputsLoading: true,
    videoServerStatus: {},

    focusedPrimaryId: '',
    isChangingStartStopInProgress: false,

    focusedPrimaryBroadcastSettings: {},
    arePrimaryBroadcastSettingsLoading: false,
    arePrimaryRecordsLoading: false,

    IFBbytesPerSample: 2,

    primariesAlarms: {},
    primariesLogs: {},
    primariesBroadcastDetails: [],
    primariesUserDetails: [],
    broadcastProperties: [],
    devicesSetsInfo: {},
    isDevicesSetsInfoLoading: false,

    isNotificationDisplayed: false,
    currentNotificationType: '',
    notificationRelatedPrimaryName: '',
    notificationRelatedOutputName: '',

    isAlertDialogDisplayed: false,
    currentAlertDialogType: '',
    alertDialogPrimaryId: '',

    primaryRecordsDetails: [],

    showDashboardPage: true,
    showMapPage: false,

    audioDevices: [],
    selectedDevice: '',
    isAudioChannelOpen: false,
    toggleRefresh: false,

    isUserCorporate: false,
    isUserVideographer: false,
    isUserInfluencer: false,
    isUserFreeTrial: false,
    isUserMyKey: false,
    isUserBasic: false,
    isUserLiveOmni: false,
    isUserGuest: false,

    guestsTotal: 0,
    guestsTotalHistory: 0,
    guestsRemain: 0,
    guestsFreeTrialRemain: 0,
    guestsMax: 0,
    guestsCurrentCount: 0,
    fetchExportList: undefined,
  };

  componentWillUnmount() {
    clearInterval(this.scheduler);
    clearInterval(this.state.keepAliveInterval);
    // this.closeConnectionToServer();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.focusedPrimaryId !== this.state.focusedPrimaryId) {
      this.setState({ primariesBroadcastDetails: [] });
      const currentUser = this.state.primariesUserDetails.find(
        (user) => user.primaryId === this.state.focusedPrimaryId
      );
      if (currentUser) {
        const licensePermissions = {
          isUserCorporate: currentUser.licensePermissionId === LicensePermissions.CORPORATE,
          isUserVideographer: currentUser.licensePermissionId === LicensePermissions.VIDEOGRAPHER,
          isUserInfluencer: currentUser.licensePermissionId === LicensePermissions.INFLUENCER,
          isUserFreeTrial: currentUser.licensePermissionId === LicensePermissions.FREE_TRIAL,
          isUserMyKey: currentUser.licensePermissionId === LicensePermissions.MYKEY,
          isUserBasic: currentUser.licensePermissionId === LicensePermissions.BASIC,
          isUserLiveOmni: currentUser.licensePermissionId === LicensePermissions.LIVE_OMNI,
          isUserGuest: currentUser.licensePermissionId === LicensePermissions.GUEST,
        };
        this.setState(licensePermissions);
      } else {
        this.setState({
          isUserCorporate: false,
          isUserVideographer: false,
          isUserInfluencer: false,
          isUserFreeTrial: false,
          isUserMyKey: false,
          isUserBasic: false,
          isUserLiveOmni: false,
          isUserGuest: false,
        });
      }
    }
  }

  isAtLeastOneCorporateLicense() {
    return this.state.primariesUserDetails.some(user => user.licensePermissionId === LicensePermissions.CORPORATE);
  }

  componentDidMount() {
    this.scheduler = setInterval(() => {
      this.getControllerInfo();
    }, 10 * 60000);

    this.getControllerInfo();

    if (localStorage.getItem('pagesTabsState') !== null) {
      const { showDashboardPage, showMapPage } = JSON.parse(
        localStorage.getItem('pagesTabsState')
      );
      this.setState({
        showDashboardPage,
        showMapPage,
      });
    } else {
      this.savePagesTabsStateToLocalStorage();
    }

    if (localStorage.getItem('primariesDisplayedNames') !== null) {
      const primariesDisplayedNames = JSON.parse(
        localStorage.getItem('primariesDisplayedNames')
      );
      this.setState({ primariesDisplayedNames });
    }

    if (localStorage.getItem('outputsDisplayedNames') !== null) {
      const outputsDisplayedNames = JSON.parse(
        localStorage.getItem('outputsDisplayedNames')
      );
      this.setState({ outputsDisplayedNames });
    }

    // document.addEventListener('keydown', (e) => {
    //   if (
    //     e.keyCode === SPACEBAR_KEY_CODE &&
    //     e.target.id !== 'displayed-name-input'
    //   ) {
    //     e.preventDefault();
    //     // this.handleSpacebarKeyEvents('keydown');  this function is not exist
    //   }
    // });

    // document.addEventListener('keyup', (e) => {
    //   if (
    //     e.keyCode === SPACEBAR_KEY_CODE &&
    //     e.target.id !== 'displayed-name-input'
    //   ) {
    //     // this.handleSpacebarKeyEvents('keyup');     this function is not exist
    //   }
    // });
  }

  _webSocket;
  _recorders = {};

  isWebSocketConnected = () => {
    if (this._webSocket && this._webSocket.readyState === WebSocket.OPEN) {
      return true;
    }
    return false;
  };

  connectWebSocket = () => {
    this._webSocket = new WebSocket(webSocketEndPoint);

    this._webSocket.onopen = () => {
      // List cameras and microphones.

      this.checkPermissions();

      clearInterval(this.state.keepAliveInterval);
      this.state.keepAliveInterval = setInterval(() => {
        if (this.isWebSocketConnected()) {
          let arrBuffer = new Int8Array(2); //ping msg
          const typeArr = shortToByteArray(KEEP_ALIVE_MSG);
          // const companyId = longToByteArray(this.state.companyId);
          arrBuffer.set(typeArr, 0);
          // arrBuffer.set(companyId,2);
          this._webSocket.send(arrBuffer);
        } else {
          clearInterval(this.state.keepAliveInterval);
        }
      }, 10000);
    };

    this._webSocket.onmessage = (e) => {
      this.__parseWebSocketMessage(e.data, this.state.focusedPrimaryId).then(
        (messageDataIncludingType) => {
          const { messageType, ...messageData } = messageDataIncludingType;
          if (messageType === 'settings_change') {
            const returnedPrimaryBroadcastSettings = messageData;
            const focusedPrimaryBroadcastSettings = {
              ...this.state.focusedPrimaryBroadcastSettings,
            };
            let isThereNewVal = false;

            for (let setting in returnedPrimaryBroadcastSettings) {
              if (
                focusedPrimaryBroadcastSettings[setting] !==
                returnedPrimaryBroadcastSettings[setting]
              ) {
                focusedPrimaryBroadcastSettings[setting] =
                  returnedPrimaryBroadcastSettings[setting];
                isThereNewVal = true;
              }
            }

            if (isThereNewVal === true) {
              this.setState({ focusedPrimaryBroadcastSettings });
            }

            this.setState({ arePrimaryBroadcastSettingsLoading: false });
          } else if (messageType === 'alert') {
            const { primaryId, alertType, alertMessage, time } = messageData;

            const capitalizedMessage = alertMessage ?
              alertMessage.slice(0, 1).toUpperCase() + alertMessage.slice(1) : '';

            const newLogObj = {
              type: alertType,
              message: capitalizedMessage,
              time,
            };

            let primariesLogs = { ...this.state.primariesLogs };
            if (primariesLogs[primaryId] !== undefined) {
              primariesLogs[primaryId].unshift(newLogObj);
              primariesLogs[primaryId] = primariesLogs[primaryId].slice(
                0,
                MAX_LOGS_PER_PRIMARY
              );
            } else {
              primariesLogs[primaryId] = [newLogObj];
            }

            this.setState({ primariesLogs });
          } else if (messageType === 'bitrate') {
            const { primaryId, bitrate, duration, operators } = messageData;
            const formatedBitrate = this._formatBitrateToString(bitrate);
            const formatedDuration = this._formatDurationToString(duration + 1); // Addiding offset of 1 in order to fix the constant delay of 1 sec in displayed duration
            let primariesBroadcastDetails = [
              ...this.state.primariesBroadcastDetails,
            ];
            let broadcastProperties = [...this.state.broadcastProperties];
            const i = primariesBroadcastDetails.findIndex(
              (obj) => obj.primaryId === primaryId
            );
            const k = broadcastProperties.findIndex(
              (obj) => obj.primaryId === primaryId
            );

            //--------------------------------Conditions for primariesBroadcastDetails---------------------------------------------

            if (i > -1) {
              const focusedPrimaryId = this.state.focusedPrimaryId;
              let formatedOperatorsArr = null;
              if (primaryId === focusedPrimaryId) {
                if (
                  primariesBroadcastDetails[i].operatorsDataArr.length === 0
                ) {
                  formatedOperatorsArr = this.formOperatorsData(
                    bitrate,
                    operators,
                    true
                  );
                  primariesBroadcastDetails[i].operatorsDataArr =
                    formatedOperatorsArr;
                } else {
                  formatedOperatorsArr = this.formOperatorsData(
                    bitrate,
                    operators,
                    false
                  );
                  primariesBroadcastDetails =
                    this.updatePrimariesBrodcastDetails(
                      formatedBitrate,
                      formatedDuration,
                      formatedOperatorsArr,
                      primariesBroadcastDetails,
                      i
                    );
                }
              } else {
                primariesBroadcastDetails[i].bitrateArr = [];
                primariesBroadcastDetails[i].durationArr = [];
                primariesBroadcastDetails[i].operatorsDataArr = [];
              }
            } else {
              const formatedOperatorsArr = this.formOperatorsData(
                bitrate,
                operators,
                true
              );
              const newPrimaryBroadcastDetailsObj = {
                primaryId,
                bitrateArr: [formatedBitrate],
                durationArr: [formatedDuration],
                operatorsDataArr: formatedOperatorsArr,
              };
              primariesBroadcastDetails.push(newPrimaryBroadcastDetailsObj);
            }

            //--------------------------------Conditions for broadcastProperties---------------------------------------------

            if (k > -1) {
              broadcastProperties[k].bitrateArr.push(formatedBitrate);
              broadcastProperties[k].bitrateArr =
                broadcastProperties[k].bitrateArr.slice(-300);
              broadcastProperties[k].durationArr.push(formatedDuration);
              broadcastProperties[k].durationArr =
                broadcastProperties[k].durationArr.slice(-300);
            } else {
              const newPrimaryBroadcastDetailsObj = {
                primaryId,
                bitrateArr: [formatedBitrate],
                durationArr: [formatedDuration],
              };
              broadcastProperties.push(newPrimaryBroadcastDetailsObj);
            }

            if (operators) {
              operators['primaryId'] = primaryId;
            }
            this.setState({
              primariesBroadcastDetails,
              broadcastDetailsJsonObject: operators,
            });
            this.setState({
              broadcastProperties,
              broadcastDetailsJsonObject: operators,
            });
          } else if (messageType === 'devices_info') {
            const { primaryId, devicesInfo } = messageData;
            let devicesSetsInfo = { ...this.state.devicesSetsInfo };

            devicesSetsInfo[primaryId] = devicesInfo;

            this.setState({
              devicesSetsInfo,
              isDevicesSetsInfoLoading: false,
            });
          } else if (messageType === 'primaries-outputs-videoServer-guestsTotal-guestsTotalHistory-guestsRemain-guestsFreeTrialRemain-guestsMax-guestsCurrentCount') {
            const {
              primaries,
              outputs,
              videoServer,
              primaryId,
              guestsTotal,
              guestsTotalHistory,
              guestsRemain,
              guestsFreeTrialRemain,
              guestsMax,
              guestsCurrentCount, } = messageData;

            if (guestsTotal !== undefined) {
              this.setState({ guestsTotal });
            }

            if (guestsTotalHistory !== undefined) {
              this.setState({ guestsTotalHistory });
            }

            if (guestsRemain !== undefined) {
              this.setState({ guestsRemain });
            }

            if (guestsFreeTrialRemain !== undefined) {
              this.setState({ guestsFreeTrialRemain });
            }

            if (guestsMax !== undefined) {
              this.setState({ guestsMax });
            }

            if (guestsCurrentCount !== undefined) {
              this.setState({ guestsCurrentCount });
            }

            if (videoServer !== undefined) {
              const videoServerStatus = {
                generalStatus: videoServer.GeneralStatus,
                errors: videoServer.Errors || [],
              };

              this.setState({ videoServerStatus });
            }

            if (primaries !== undefined) {
              // let arr = [{id:0,username:'test'}];
              // if (this.state.companyType===1){
              // 	arr.concat(primaries);
              // }
              // console.log("Oren: "+primaries[0]);

              //   this._handlePrimariesStartStopStateChange(primaries); when performing this function, the Bitrate Chart is lost

              if (this.state.focusedPrimaryId) {
                const focusedPrimaryPrevObj = this.state.primaryUsers.find(
                  (obj) => obj.id === this.state.focusedPrimaryId
                );

                this.setState(
                  {
                    primaryUsers: primaries,
                    arePrimaryUsersLoading: false,
                  },
                  () => {
                    const focusedPrimaryNewObj = this.state.primaryUsers.find(
                      (obj) => obj.id === this.state.focusedPrimaryId
                    );

                    if (!focusedPrimaryNewObj) return;

                    if (
                      focusedPrimaryPrevObj.isActive !==
                      focusedPrimaryNewObj.isActive &&
                      focusedPrimaryPrevObj.isActive === true
                    ) {
                      this.setState(
                        {
                          arePrimaryBroadcastSettingsLoading: true,
                          isDevicesSetsInfoLoading: true,
                          devicesSetsInfo: {},
                        },
                        () => {
                          this._getFocusedPrimaryBroadcastSettings();
                        }
                      );
                    }
                  }
                );

              } else {
                this.setState({
                  primaryUsers: primaries,
                  arePrimaryUsersLoading: false,
                });
              }
            }
            if (outputs !== undefined) {
              this.setState({
                outputs,
                areOutputsLoading: false,
              });

              let primariesAlarms = { ...this.state.primariesAlarms };

              outputs.forEach((output) => {
                if (output.primaryId !== null) {
                  let newAlarms = [];

                  if (
                    output.GeneralStatus === 'WARNING' ||
                    output.GeneralStatus === 'ERROR'
                  ) {
                    newAlarms = output.Statuses.map((status) => {
                      return {
                        name: status.Name,
                        type: status.Type,
                        message: status.Description,
                      };
                    });
                  }

                  primariesAlarms[output.primaryId] = newAlarms;

                  // Adding logs for every NEW alarm
                }
              });

              this.setState({ primariesAlarms });
            }
          } else if (messageType === 'dialog_alert_message') {
            if (this.state.isChangingStartStopInProgress) {
              this.setState({
                isAlertDialogDisplayed: messageData.isShown,
                currentAlertDialogType: messageData.dialogType,
                alertDialogPrimaryId: messageData.primaryId,
              });
            }
          } else if (messageType === 'device_records') {
            this.setState({
              primaryRecordsDetails: messageData.records,
              arePrimaryRecordsLoading: false,
            });
          } else if (messageType === 'record_percentage') {
            const { name, percentage } = messageData;
            const recordIndex = this.state.primaryRecordsDetails.findIndex(
              (record) => record.name === name
            );

            if (recordIndex !== -1) {
              const newRecords = [...this.state.primaryRecordsDetails];
              newRecords[recordIndex].percentage = percentage;
              this.setState({
                primaryRecordsDetails: newRecords,
              });
            }
          } else if (messageType === 'update_record') {
            const { name, status } = messageData;
            const recordIndex = this.state.primaryRecordsDetails.findIndex(
              (record) => record.name === name
            );

            if (recordIndex !== -1) {
              const newRecords = [...this.state.primaryRecordsDetails];
              newRecords[recordIndex].status = status;
              this.setState({
                primaryRecordsDetails: newRecords,
              });
            }
          }
        }
      );
    };

    this._webSocket.onerror = (err) => {
      console.error('Error in connection to server: ' + err.data);
    };

    this._webSocket.onclose = () => {
      this.getControllerInfo();
    };
  };

  closeConnectionToServer = () => {
    axios({
      method: 'get',
      url: APIs.logout,
      withCredentials: true,
    })
      .then(() => {
        this.props.setLogout();
      })
      .catch((err) => {
        console.error(err);
      });
    this._webSocket.close();
  };

  getAudioDevices() {
    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        let audioDevices = [];
        // let defaultSelectedDevice = {name:'No audio input',id:'',primaries:{},open:false};
        // audioDevices.push(defaultSelectedDevice);
        devices.forEach((device) => {
          if (
            device.kind === 'audioinput' &&
            device.deviceId !== 'communications' &&
            device.deviceId !== 'default'
          )
            audioDevices.push({
              name: device.label,
              id: device.deviceId,
              primaries: {},
              open: false,
            });
        });
        this.setState({ audioDevices: audioDevices });
      })
      .catch(function (err) {
        console.log(err.name + ': ' + err.message);
      });
  }

  createRecorder(deviceId) {
    let main = this;
    if (this._recorders[deviceId] !== undefined) {
      return;
    }
    navigator.mediaDevices
      .getUserMedia({
        // Check whether this code block can be removed as the below settings might be ignored
        audio: {
          latency: 0,
          sampleRate: { ideal: 8000 },
          channelCount: 1,
          echoCancellation: false,
          deviceId: deviceId,
        },
      })
      .then((stream) => {
        let recorder = new MediaStreamRecorder(stream);
        recorder.mimeType = 'audio/pcm';
        recorder.ondataavailable = (blob) => {
          // console.log('data!!')
          let audioDevices = this.state.audioDevices;
          audioDevices.forEach((audioDevice) => {
            if (audioDevice.id === deviceId) {
              let primaries = audioDevice.primaries;
              this.__sendIfbMessagesToPrimaries(primaries, blob);
            }
          });
        };
        main._recorders[deviceId] = recorder;
      })
      .catch((err) => {
        console.error(err);
      });
  }

  //---------------- WebSocket messages related methods ---------------//
  __parseWebSocketMessage(data, focusedPrimaryId) {
    return new Promise((resolve, reject) => {
      let parsedMessageData = {};

      const fileReader = new FileReader();
      fileReader.onload = function () {
        const arrayBuffer = this.result;
        const messageData = JSON.parse(
          String.fromCharCode.apply(null, new Uint8Array(arrayBuffer.slice(0)))
        );

        if (messageData.type === PRIMARY_TO_DASHBOARD_MSG) {
          const { primaryId, subType } = messageData;

          switch (PrimaryToDashboardMsgSubtypes[subType]) {
            case 'settings_change':
              if (primaryId === focusedPrimaryId) {
                parsedMessageData.messageType = 'settings_change';

                parsedMessageData.alarmsPrimary = messageData.alarms;

                parsedMessageData.camera =
                  availableBroadcastSettingsObj.camera[
                    messageData.camera
                  ].value;
                parsedMessageData.codec =
                  availableBroadcastSettingsObj.codec[messageData.codec].value;
                parsedMessageData.frameRate =
                  availableBroadcastSettingsObj.frameRate[
                    messageData.frameRate
                  ].value;

                parsedMessageData.maxBitrate =
                  availableBroadcastSettingsObj.maxBitrate[
                    messageData.maxBitrate
                  ].value;
                parsedMessageData.broadcastType =
                  availableBroadcastSettingsObj.broadcastType[
                    messageData.broadcastType
                  ].value;
                parsedMessageData.resolution =
                  availableBroadcastSettingsObj.resolution[
                    messageData.resolution
                  ].value;
                parsedMessageData.scanMode =
                  availableBroadcastSettingsObj.scanMode[
                    messageData.scanMode
                  ].value;
                parsedMessageData.streamType =
                  availableBroadcastSettingsObj.streamType[
                    messageData.streamType
                  ].value;
                parsedMessageData.videoFluency =
                  availableBroadcastSettingsObj.videoFluency[
                    messageData.videoFluency
                  ].value;
              }
              break;

            case 'alert':
              if (messageData.alertType >= 1) {
                parsedMessageData.messageType = 'alert';
                parsedMessageData.primaryId = primaryId;
                parsedMessageData.alertType = alertsTypes[messageData.logType];
                parsedMessageData.alertMessage = messageData.message;
                parsedMessageData.time = new Date().toLocaleTimeString();
              }
              break;

            case 'bitrate':
              parsedMessageData.messageType = 'bitrate';
              parsedMessageData.primaryId = primaryId;
              parsedMessageData.duration = messageData.duration;
              parsedMessageData.bitrate = (messageData.bitrate * 8) / 1000000;
              parsedMessageData.operators = messageData.operators;
              break;

            case 'devices_info':
              parsedMessageData.messageType = 'devices_info';
              parsedMessageData.primaryId = primaryId;
              parsedMessageData.devicesInfo = messageData.devicesInfo;
              break;

            case 'dialog_alert_message':
              parsedMessageData.messageType = 'dialog_alert_message';
              parsedMessageData.primaryId = primaryId;
              parsedMessageData.dialogType = messageData.dialogType;
              parsedMessageData.isShown = messageData.show;
              break;

            case 'device_records':
              parsedMessageData.messageType = 'device_records';
              parsedMessageData.records = messageData.records;
              break;

            case 'record_percentage':
              parsedMessageData.messageType = 'record_percentage';
              parsedMessageData.name = messageData.name;
              parsedMessageData.percentage = messageData.percentage;
              break;

            case 'update_record':
              parsedMessageData.messageType = 'update_record';
              parsedMessageData.name = messageData.name;
              parsedMessageData.status = messageData.status;
              break;

            default:
              parsedMessageData.messageType = 'undefined type';
          }
        } else if (messageData.type === CENTRAL_SERVER_TO_DASHBOARD_MSG) { // guests data can be fetched here...
          parsedMessageData.messageType = 'primaries-outputs-videoServer-guestsTotal-guestsTotalHistory-guestsRemain-guestsFreeTrialRemain-guestsMax-guestsCurrentCount';
          parsedMessageData.primaries = messageData.primaries;
          parsedMessageData.outputs = messageData.outputs;
          parsedMessageData.videoServer = messageData.videoServer;

          parsedMessageData.guestsTotal = messageData.guestsTotal;
          parsedMessageData.guguestsTotalHistoryestsTotal = messageData.guestsTotalHistory;
          parsedMessageData.guestsRemain = messageData.guestsRemain;
          parsedMessageData.guestsFreeTrialRemain = messageData.guestsFreeTrialRemain;
          parsedMessageData.guestsMax = messageData.guestsMax;
          parsedMessageData.guestsCurrentCount = messageData.guestsCurrentCount;
        }

        resolve(parsedMessageData);
      };

      if (data instanceof Blob) {
        fileReader.readAsArrayBuffer(data);
      }
    });
  }

  __prepareWebSocketMsgToPrimary(command, primaryId, additionalData) {
    // broadcastSettingsObj includes camera, scanMode, resolution, codec, frameRate, maxBitrate, streamType
    let arrBuffer;

    const typeArr = shortToByteArray(DASHBOARD_TO_PRIMARY_MSG);
    const idArr = longToByteArray(primaryId);
    let innerTypeArr;
    switch (command) {
      case 'start':
        innerTypeArr = shortToByteArray(START_SESSION_MSG);
        break;
      case 'stop':
        innerTypeArr = shortToByteArray(STOP_SESSION_MSG);
        break;
      case 'get settings':
        innerTypeArr = shortToByteArray(GET_PRIMARY_SETTINGS_MSG);
        break;
      case 'set settings':
        innerTypeArr = shortToByteArray(SET_PRIMARY_SETTINGS_MSG);
        break;
      case 'response dialog':
        innerTypeArr = shortToByteArray(DIALOG_ALERT_RESPONSE);
        break;
      case 'get records':
        innerTypeArr = shortToByteArray(GET_DEVICE_RECORDS);
        break;
      case 'start upload':
        innerTypeArr = shortToByteArray(START_RECORD_UPLOAD);
        break;
      case 'pause upload':
        innerTypeArr = shortToByteArray(PAUSE_RECORD_UPLOAD);
        break;
      case 'delete record':
        innerTypeArr = shortToByteArray(DELETE_RECORD);
        break;
      default:
        innerTypeArr = undefined;
    }

    if (
      command === 'start' ||
      command === 'stop' ||
      command === 'get settings' ||
      command === 'get records'
    ) {
      arrBuffer = new Int8Array(12);
      // The array structure is  XX    XXXXXXXX    XX
      // The array indexes are   0     2           10
      arrBuffer.set(typeArr, 0);
      arrBuffer.set(idArr, 2);
      arrBuffer.set(innerTypeArr, 10);
    } else if (
      command === 'set settings' ||
      command === 'response dialog' ||
      command === 'delete record' ||
      command === 'start upload' ||
      command === 'pause upload'
    ) {
      let dataArr;
      if (command === 'set settings') {
        let adjustedBroadcastSettingsObj = { ...additionalData };
        delete adjustedBroadcastSettingsObj.alarmsPrimary;
        let dataObj = {};
        for (let setting in adjustedBroadcastSettingsObj) {
          const tempObj = availableBroadcastSettingsObj[setting].find(
            (obj) => obj.value === adjustedBroadcastSettingsObj[setting]
          );
          dataObj[setting] = tempObj.type;
        }
        dataArr = Buffer.from(JSON.stringify(dataObj));
      } else {
        dataArr = Buffer.from(JSON.stringify(additionalData));
      }

      arrBuffer = new Int8Array(12 + dataArr.length);
      // The array structure is  XX    XXXXXXXX    XX    converted JSON (91)
      // The array indexes are   0     2           10    12
      arrBuffer.set(typeArr, 0);
      arrBuffer.set(idArr, 2);
      arrBuffer.set(innerTypeArr, 10);
      arrBuffer.set(dataArr, 12);
    }

    return arrBuffer;
  }

  __sendIfbMessagesToPrimaries(primaries, blob) {
    const fileReader = new FileReader();
    fileReader.onloadend = (e) => {
      const dataArr = e.srcElement.result; // arraybuffer object
      const typeArr = shortToByteArray(DASHBOARD_TO_PRIMARIES_MSG);
      let idsArr = [];

      Object.keys(primaries).forEach((key, index) => {
        // key: the name of the object key
        // index: the ordinal position of the key within the object

        const idArr = longToByteArray(key);
        idsArr.push(idArr);
      });

      // const idArr = longToByteArray(primaryId);
      const innerTypeArr = shortToByteArray(IFB_MSG);

      const MAX_FRAME_SIZE = 1024 * 512;
      const BYTES_PER_SAMPLE = this.state.IFBbytesPerSample;
      const SAMPLE_RATE = 48000;
      const RESAMPLING_RATIO = 2;
      const resampleRate = SAMPLE_RATE / RESAMPLING_RATIO;
      const calculatedConst = BYTES_PER_SAMPLE * (SAMPLE_RATE / resampleRate);
      const CHANNEL_COUNT = 2;

      let frameIndex = 0;
      for (let i = 0; i < dataArr.byteLength; i += MAX_FRAME_SIZE) {
        const indexArr = longToByteArray(frameIndex);
        frameIndex++;
        const sliceBuffer = new Int8Array(
          dataArr.slice(
            i,
            i + MAX_FRAME_SIZE > dataArr.length
              ? dataArr.length
              : i + MAX_FRAME_SIZE
          )
        );
        let cutLength = Math.floor(sliceBuffer.length / calculatedConst);
        cutLength = cutLength - (cutLength % 2);
        let cutSliceBuffer = new Int8Array(cutLength);
        for (
          let j = 0;
          j < sliceBuffer.length;
          j += CHANNEL_COUNT * calculatedConst
        ) {
          cutSliceBuffer[j / calculatedConst] = sliceBuffer[j];
          cutSliceBuffer[j / calculatedConst + 1] = sliceBuffer[j + 1];
        }

        let arrBuffer = new Int8Array(
          12 + 4 + 8 * idsArr.length + cutSliceBuffer.byteLength
        );
        // The array structure is  XX    XXXXXXXX    XX    XXXXXXXX    cutSliceBuffer.byteLength
        // The array indexes are   0     2           10    12          20
        arrBuffer.set(typeArr, 0);
        arrBuffer.set(intToByteArray(idsArr.length), 2);
        let offset = 6;
        idsArr.forEach((idArr) => {
          arrBuffer.set(idArr, offset);
          offset += 8;
        });
        // arrBuffer.set(idArr, 2);
        arrBuffer.set(innerTypeArr, offset);
        arrBuffer.set(indexArr, offset + 2);
        arrBuffer.set(cutSliceBuffer, offset + 2 + indexArr.length);

        this._webSocket.send(arrBuffer);
      }
    };

    fileReader.readAsArrayBuffer(blob);
  }

  __prepareWebSocketMsgToCentralServer(id, isDesignated) {
    const typeArr = shortToByteArray(DASHBOARD_TO_CENTRAL_SERVER_MSG);
    const innerTypeArr = shortToByteArray(SET_DESIGNATED_OUTPUT);
    const dataObj = {
      id,
      isDesignated,
    };
    const dataArr = Buffer.from(JSON.stringify(dataObj));

    const arrBuffer = new Int8Array(4 + dataArr.length);
    // The array structure is  XX    XX    converted JSON
    // The array indexes are   0     2     4
    arrBuffer.set(typeArr, 0);
    arrBuffer.set(innerTypeArr, 2);
    arrBuffer.set(dataArr, 4);

    return arrBuffer;
  }
  //---------------------------------------------------------------//

  //--------------------- REST calls methods ---------------------//
  getControllerInfo = (callbackFunc) => {
    axios({
      method: 'get',
      url: APIs.getControllerInfo,
      withCredentials: true,
      validateStatus: function (status) {
        return status >= 200;
      },
    })
      .then((response) => {
        if (response.status === 200) {
          if (response.data.companyName !== undefined) {
            this.setState({
              companyName: response.data.companyName,
              adminFirstName: response.data.adminFirstName,
              adminLastName: response.data.adminLastName,
              companyType: response.data.type,
              companyId: response.data.id,
              primariesUserDetails: response.data.primaries,
              guestsTotal: response.data.guestsTotal,
              guestsTotalHistory: response.data.guestsTotalHistory,
              guestsRemain: response.data.guestsRemain,
              guestsFreeTrialRemain: response.data.guestsFreeTrialRemain,
              guestsMax: response.data.guestsMax,
              guestsCurrentCount: response.data.guestsCurrentCount,
            });
          }
          if (callbackFunc !== undefined) {
            callbackFunc();
          }
          if (!this.isWebSocketConnected()) {
            this.connectWebSocket();
          }
        } else if (response.status === 401 || response.status === 403) {
          this.props.setLogout();
        }
      })
      .catch((err) => {
        this.props.setLogout();
      });
  };
  //-------------------------------------------------------------//

  _timerOfGetFocusedPrimaryBroadcastSettings;
  _isTimerOfGetFocusedPrimaryBroadcastSettingsSet = false;

  _getFocusedPrimaryBroadcastSettings = () => {
    let msg = this.__prepareWebSocketMsgToPrimary(
      'get settings',
      this.state.focusedPrimaryId
    );
    this._webSocket.send(msg);

    msg = this.__prepareWebSocketMsgToPrimary(
      'get records',
      this.state.focusedPrimaryId
    );
    this._webSocket.send(msg);

    if (this._isTimerOfGetFocusedPrimaryBroadcastSettingsSet === true) {
      clearTimeout(this._timerOfGetFocusedPrimaryBroadcastSettings);
    }

    this._timerOfGetFocusedPrimaryBroadcastSettings = setTimeout(() => {
      this._isTimerOfGetFocusedPrimaryBroadcastSettingsSet = false;

      if (this.state.arePrimaryBroadcastSettingsLoading === true) {
        this.setState({
          focusedPrimaryBroadcastSettings: {},
          primaryRecordsDetails: [],
          arePrimaryBroadcastSettingsLoading: false,
          arePrimaryRecordsLoading: false,
        });
      }

      if (this.state.isDevicesSetsInfoLoading === true) {
        this.setState({
          isDevicesSetsInfoLoading: false,
        });
      }
    }, 3000);

    this.setState({
      focusedPrimaryBroadcastSettings: {},
      primaryRecordsDetails: [],
      arePrimaryBroadcastSettingsLoading: false,
      arePrimaryRecordsLoading: false,
    });
    this._isTimerOfGetFocusedPrimaryBroadcastSettingsSet = true;
  };

  _handlePrimariesStartStopStateChange = (newPrimariesArr) => {
    const prevPrimariesArr = [...this.state.primaryUsers];
    prevPrimariesArr.forEach((prevPrimaryObj) => {
      const newPrimaryObj = newPrimariesArr.find(
        (obj) => obj.id === prevPrimaryObj.id
      );
      if (newPrimaryObj !== undefined) {
        if (newPrimaryObj.isBroadcasting !== prevPrimaryObj.isBroadcasting) {
          if (newPrimaryObj.id === this.state.focusedPrimaryId) {
            this.setState({ isChangingStartStopInProgress: false });
          }
          if (newPrimaryObj.isBroadcasting === true) {
            let primariesBroadcastDetails = [
              ...this.state.primariesBroadcastDetails,
            ];
            let broadcastProperties = [...this.state.broadcastProperties];
            const i = primariesBroadcastDetails.findIndex(
              (obj) => obj.primaryId === newPrimaryObj.id
            );
            const k = broadcastProperties.findIndex(
              (obj) => obj.primaryId === newPrimaryObj.id
            );
            if (i > -1) {
              primariesBroadcastDetails[i].bitrateArr = [];
              primariesBroadcastDetails[i].durationArr = [];
              primariesBroadcastDetails[i].operatorsDataArr = [];
              this.setState({ primariesBroadcastDetails });
            }
            if (k > -1) {
              primariesBroadcastDetails[k].bitrateArr = [];
              primariesBroadcastDetails[k].durationArr = [];
              this.setState({ broadcastProperties });
            }
          }
        }
      }
    });
  };

  _formatBitrateToString = (bitrate) => {
    let formatedBitrate = '';
    const temp = Math.round(bitrate * 100);
    formatedBitrate = (temp / 100).toString();

    if (temp % 100 === 0) {
      formatedBitrate += '.00';
    } else if (temp % 10 === 0) {
      formatedBitrate += '0';
    }

    return formatedBitrate;
  };

  _formatDurationToString = (duration) => {
    let hoursStr, minutesStr, secondsStr;

    const hours = Math.floor(duration / 3600);
    if (hours === 0) {
      hoursStr = '';
    } else {
      hoursStr = hours.toString();
      if (hours / 10 < 1) {
        hoursStr = '0' + hoursStr;
      }
    }

    const minutes = Math.floor((duration % 3600) / 60);
    minutesStr = minutes.toString();
    if (minutes / 10 < 1) {
      minutesStr = '0' + minutesStr;
    }

    const seconds = duration % 60;
    secondsStr = seconds.toString();
    if (seconds / 10 < 1) {
      secondsStr = '0' + secondsStr;
    }

    return hoursStr
      ? `${hoursStr}:${minutesStr}:${secondsStr}`
      : `${minutesStr}:${secondsStr}`;
  };

  //------------- App tabs switching related methods -------------//
  handlePagesSwitch = (e) => {
    const showDashboardPage = e.target.id === 'dashboard';
    const showMapPage = e.target.id === 'map';

    this.setState(
      {
        showDashboardPage,
        showMapPage,
      },
      () => {
        this.savePagesTabsStateToLocalStorage();
      }
    );
  };

  savePagesTabsStateToLocalStorage = () => {
    const { showDashboardPage, showMapPage } = this.state;
    const pagesTabsState = { showDashboardPage, showMapPage };
    localStorage.setItem('pagesTabsState', JSON.stringify(pagesTabsState));
  };
  //------------------------------------------------------------//

  //----------- Buttons and elements click handlers ------------//
  handlePrimaryFocus = (primaryId) => {
    let audioDevices = this.state.audioDevices;
    let selectedDevice = ''; //audioDevices[0];

    audioDevices.forEach((audioDevice) => {
      if (audioDevice.primaries[primaryId] !== undefined) {
        selectedDevice = audioDevice;
      }
    });
    let isAudioChannelOpen = selectedDevice.open;

    this.setState(
      {
        focusedPrimaryId: primaryId,
        isChangingStartStopInProgress: false,
        arePrimaryBroadcastSettingsLoading: true,
        arePrimaryRecordsLoading: true,
        isDevicesSetsInfoLoading: true,
        selectedDevice: selectedDevice,
        isAudioChannelOpen: isAudioChannelOpen,
      },
      () => {
        this._getFocusedPrimaryBroadcastSettings();
      }
    );
  };

  handleStartStopClick = () => {
    this.setState({ isChangingStartStopInProgress: true });
    this.setState({ primariesBroadcastDetails: [] });
    const { primaryUsers, focusedPrimaryId } = this.state;
    const focusedPrimaryObj = primaryUsers.find(
      (obj) => obj.id === focusedPrimaryId
    );

    const command = !focusedPrimaryObj.isBroadcasting ? 'start' : 'stop';
    const msg = this.__prepareWebSocketMsgToPrimary(command, focusedPrimaryId);
    this._webSocket.send(msg);

    setTimeout(() => {
      if (this.state.isChangingStartStopInProgress === true) {
        this.setState({ isChangingStartStopInProgress: false });
      }
    }, 5000);
  };

  handleSettingsApplyClick = (newSettingsObj) => {
    const msg = this.__prepareWebSocketMsgToPrimary(
      'set settings',
      this.state.focusedPrimaryId,
      newSettingsObj
    );
    this._webSocket.send(msg);
  };

  handleLogsClear = (primaryId) => {
    let primariesLogs = { ...this.state.primariesLogs };
    primariesLogs[primaryId] = [];

    this.setState({ primariesLogs });
  };

  handleAlertDialogButtonsClick = (isConfirmed) => {
    this.setState({
      isAlertDialogDisplayed: false,
      isChangingStartStopInProgress: false,
    });
    const response = {
      dialogType: this.state.currentAlertDialogType,
      isConfirmed,
    };
    const msg = this.__prepareWebSocketMsgToPrimary(
      'response dialog',
      this.state.alertDialogPrimaryId,
      response
    );
    this._webSocket.send(msg);
  };

  //-----------------------------------------------------------//

  //------------------- IFB related methods -------------------//
  _openIFBchannel = (selectedDevice) => {
    if (
      this._recorders[selectedDevice.id] !== undefined &&
      !selectedDevice.open
    ) {
      this._recorders[selectedDevice.id].start(30);
      selectedDevice.open = true;
      this.setState({
        selectedDevice: selectedDevice,
        isAudioChannelOpen: true,
      });
    }
  };

  _closeIFBchannel = (selectedDevice) => {
    if (
      this._recorders[selectedDevice.id] !== undefined &&
      selectedDevice.open
    ) {
      this._recorders[selectedDevice.id].stop();
      selectedDevice.open = false;
      this.setState({
        selectedDevice: selectedDevice,
        isAudioChannelOpen: false,
      });
    }
  };

  handleIFBdownEvents = (selectedDevice) => {
    if (selectedDevice) {
      this._openIFBchannel(selectedDevice);
    }
  };

  handleIFBupEvents = (selectedDevice) => {
    if (selectedDevice) {
      this._closeIFBchannel(selectedDevice);
    }
  };

  // handleSpacebarKeyEvents = eventName => {
  // 	if (this.state.isPrimaryFocused === true) {
  // 		const primaryObj = this.state.primaryUsers.find(
  // 			obj => obj.id === this.state.focusedPrimaryId
  // 		);
  // 		if (primaryObj.isActive === true) {
  // 			if (eventName === 'keydown') {
  // 				this.state.isIfbButtonLocked
  // 					? this.handleIFBpressEvents()
  // 					: this.handleIFBdownEvents();
  // 			} else if (eventName === 'keyup') {
  // 				this.handleIFBupEvents();
  // 			}
  // 		}
  // 	}
  // };

  handleStartRecordUpload = (recordName) => {
    this.sendRecordActionMessage('start upload', recordName);
  };

  handlePauseRecordUpload = (recordName) => {
    this.sendRecordActionMessage('pause upload', recordName);
  };

  handleDeleteRecord = (recordName) => {
    this.sendRecordActionMessage('delete record', recordName);
  };

  onAttachPrimary = (selectedDevice, primaryId) => {
    const focusedPrimaryPrevObj = this.state.primaryUsers.find(
      (obj) => obj.id === primaryId
    );
    if (focusedPrimaryPrevObj === undefined) {
      return;
    }
    let audioDevices = this.state.audioDevices;
    audioDevices.forEach((audioDevice) => {
      if (audioDevice.id === selectedDevice.id && selectedDevice.id !== '') {
        audioDevice.primaries[focusedPrimaryPrevObj.id] =
          focusedPrimaryPrevObj.username;
      } else {
        delete audioDevice.primaries[focusedPrimaryPrevObj.id];
      }
    });
    this.setState({ toggleRefresh: !this.state.toggleRefresh });
  };

  onDettachPrimary = (selectedDevice, primaryId) => {
    delete selectedDevice.primaries[primaryId];
    if (Object.keys(selectedDevice.primaries).length === 0) {
      this._closeIFBchannel(selectedDevice);
    }
    this.setState({ toggleRefresh: !this.state.toggleRefresh });
  };

  onAudioSelect = (selectedDevice) => {
    this.createRecorder(selectedDevice.id);
    this.setState({
      selectedDevice: selectedDevice,
      isAudioChannelOpen: selectedDevice.open,
    });
  };

  sendRecordActionMessage(command, recordName) {
    const additionalData = {
      name: recordName,
    };

    const msg = this.__prepareWebSocketMsgToPrimary(
      command,
      this.state.focusedPrimaryId,
      additionalData
    );
    this._webSocket.send(msg);
  }

  changeIFBbytesPerSample = (value) => {
    this.setState({ IFBbytesPerSample: value });
  };
  //-----------------------------------------------------------//

  //--------------- Notification related methods ---------------//
  _notificationTimer;

  showNotification = (type, data) => {
    this.setState(
      {
        isNotificationDisplayed: true,
        currentNotificationType: type,
        notificationMsg: data,
      },
      () => {
        clearTimeout(this._notificationTimer);
        this._notificationTimer = setTimeout(() => {
          this.hideNotification();
        }, 5000);
      }
    );
  };

  hideNotification = () => {
    this.setState({ isNotificationDisplayed: false });
  };
  //-----------------------------------------------------------//

  setDisplayedName = (type, id, newName) => {
    const identificator =
      type === 'Primary' ? 'primariesDisplayedNames' : 'outputsDisplayedNames';

    let displayedNamesArr = { ...this.state[identificator] };
    displayedNamesArr[id] = newName;

    this.setState({ [identificator]: displayedNamesArr }, () => {
      localStorage.setItem(
        [identificator],
        JSON.stringify(this.state[identificator])
      );
    });
  };

  checkPermissions() {
    const permissions = navigator.mediaDevices.getUserMedia({
      audio: true,
      video: false,
    });
    permissions
      .then((stream) => {
        //   alert('accepted the permissions');
        this.getAudioDevices();
      })
      .catch((err) => {
        console.log(`${err.name} : ${err.message}`);
      });
  }

  formOperatorsData = (bitrate, operators, isPrimaryBroadcast) => {
    let broadcastOperatorsArray = operators.providers;
    let totalDeliveredPackets = operators.totalDeliveredPackets;
    let data = [];
    for (let index = 0; index < broadcastOperatorsArray.length; index++) {
      let broadcastOperatorJsonObject = broadcastOperatorsArray[index];
      let deliveredPackets = broadcastOperatorJsonObject['deliveredPackets'];
      let chartPortionParam = Math.round(
        (deliveredPackets * 100) / totalDeliveredPackets
      );
      chartPortionParam = (bitrate * chartPortionParam) / 100;
      if (totalDeliveredPackets === 0) chartPortionParam = 0;
      let chartNamesParam = broadcastOperatorJsonObject['providerName'];
      if (isPrimaryBroadcast) {
        const operatorData = {
          id: index + 'operator',
          operatorName: chartNamesParam,
          operatorData: [this._formatBitrateToString(chartPortionParam)],
        };
        data.push(operatorData);
      } else {
        const operatorData = {
          id: index + 'operator',
          operatorName: chartNamesParam,
          operatorData: this._formatBitrateToString(chartPortionParam),
        };
        data.push(operatorData);
      }
    }
    return data;
  };

  updatePrimariesBrodcastDetails = (
    formatedBitrate,
    formatedDuration,
    formatedOperatorsArr,
    primariesBroadcastDetails,
    i
  ) => {
    primariesBroadcastDetails[i].bitrateArr.push(formatedBitrate);
    primariesBroadcastDetails[i].bitrateArr = primariesBroadcastDetails[i].bitrateArr.slice(-300);
    primariesBroadcastDetails[i].durationArr.push(formatedDuration);
    primariesBroadcastDetails[i].durationArr = primariesBroadcastDetails[i].durationArr.slice(-300);

    const newOperatorsDataArr = [];

    formatedOperatorsArr.forEach((formattedOperator) => {
      const existingOperator = primariesBroadcastDetails[i].operatorsDataArr.find(
        (op) => op.id === formattedOperator.id
      );

      if (existingOperator) {
        const updatedData = [...existingOperator.operatorData, formattedOperator.operatorData].slice(-300);
        newOperatorsDataArr.push({
          id: formattedOperator.id,
          operatorName: formattedOperator.operatorName,
          operatorData: updatedData,
        });
      } else {
        newOperatorsDataArr.push({
          id: formattedOperator.id,
          operatorName: formattedOperator.operatorName,
          operatorData: [formattedOperator.operatorData],
        });
      }
    });

    primariesBroadcastDetails[i].operatorsDataArr = newOperatorsDataArr;

    // const operatorNames = primariesBroadcastDetails[i].operatorsDataArr.map((op) => op.operatorName)
    // console.log('Operator names: (updatePrimariesBrodcastDetails)', operatorNames);
    return primariesBroadcastDetails;
  };

  setFetchExportListFn = (fn) => {
    this.setState({ ...this.state, fetchExportList: fn })
  }

  render() {
    const {
      companyName,
      adminFirstName,
      adminLastName,
      primaryUsers,
      primariesDisplayedNames,
      arePrimaryUsersLoading,
      broadcastDetailsJsonObject,
      outputs,
      outputsDisplayedNames,
      areOutputsLoading,
      videoServerStatus,

      focusedPrimaryId,
      isChangingStartStopInProgress,

      focusedPrimaryBroadcastSettings,
      arePrimaryBroadcastSettingsLoading,
      arePrimaryRecordsLoading,
      primaryRecordsDetails,

      primariesAlarms,
      primariesLogs,
      primariesBroadcastDetails,
      isUserCorporate,
      isUserVideographer,
      isUserInfluencer,
      isUserFreeTrial,
      isUserMyKey,
      isUserBasic,
      isUserLiveOmni,
      isUserGuest,
      broadcastProperties,
      devicesSetsInfo,
      isDevicesSetsInfoLoading,

      isNotificationDisplayed,
      currentNotificationType,
      notificationMsg,

      isAlertDialogDisplayed,
      currentAlertDialogType,

      showDashboardPage,
      showMapPage,

      audioDevices,
      selectedDevice,
      isAudioChannelOpen,
      companyType,

      guestsTotal,
      guestsTotalHistory,
      guestsRemain,
      guestsFreeTrialRemain,
      guestsMax,
      guestsCurrentCount,
      primariesUserDetails,
      fetchExportList,
    } = this.state;

    const {
      // companyType,
      screenSize,
    } = this.props;

    if (!focusedPrimaryId && primaryUsers.length > 0) {
      // this.setState({focusedPrimaryId:primaryUsers[0].id});
      this.handlePrimaryFocus(primaryUsers[0].id);
    }

    const filteredPrimaryUsersWithDisplayedName = primaryUsers.map(
      ({
        id,
        username,
        isActive,
        isBroadcasting,
        type,
        url,
        latitude,
        longitude,
        Statuses,
        outputAddressId,
        expiration,
      }) => {
        const statuses = Statuses || [];
        const displayedName = primariesDisplayedNames[id]
          ? primariesDisplayedNames[id]
          : '';
        const userDetail = primariesUserDetails.find(user => user.primaryId === id);
        const licensePermission = userDetail ? userDetail.licensePermission : null;
        const licensePermissionId = userDetail ? userDetail.licensePermissionId : null;
        const licenseTypeId = userDetail ? userDetail.licenseTypeId : null;
        const deviceOS = userDetail ? userDetail.deviceOS : null;
        const licenseSource = userDetail ? userDetail.licenseSource : null;
        return {
          id,
          username,
          displayedName,
          isActive,
          isBroadcasting,
          type,
          url,
          latitude,
          longitude,
          statuses,
          outputAddressId,
          daysToExpire: (Date.parse(expiration) - Date.now()) / 86400000,
          licensePermission,
          licensePermissionId,
          licenseTypeId,
          deviceOS,
          licenseSource,
        };
      }
    );

    const filteredOutputsWithDisplayedName = outputs.map(
      ({
        id,
        name,
        uuid,
        isActive,
        isDesignated,
        GeneralStatus,
        Statuses,
        primaryId,
      }) => {
        const generalStatus = GeneralStatus;
        const statuses = Statuses || [];
        const displayedName = outputsDisplayedNames[id]
          ? outputsDisplayedNames[id]
          : '';
        return {
          id,
          name,
          uuid,
          displayedName,
          isActive,
          isDesignated,
          generalStatus,
          statuses,
          primaryId,
        };
      }
    );

    const trimmedPrimariesBroadcastDetails = primariesBroadcastDetails.map(
      ({ primaryId, bitrateArr, durationArr }) => {
        return {
          primaryId,
          bitrate: bitrateArr[bitrateArr.length - 1],
          duration: durationArr[durationArr.length - 1],
        };
      }
    );

    const trimmedBroadcastProperties = broadcastProperties.map(
      ({ primaryId, bitrateArr, durationArr }) => {
        return {
          primaryId,
          bitrate: bitrateArr[bitrateArr.length - 1],
          duration: durationArr[durationArr.length - 1],
        };
      }
    );

    let focusedPrimayBroadcastDetails = {};
    let focusedPrimayDevicesSetInfo = [];
    let focusedPrimaryVideoAlarms = [];
    let focusedPrimaryLogs = [];

    if (focusedPrimaryId) {
      focusedPrimayBroadcastDetails = primariesBroadcastDetails.find(
        (obj) => obj.primaryId === focusedPrimaryId
      );
      focusedPrimayBroadcastDetails = focusedPrimayBroadcastDetails || {};

      if (devicesSetsInfo[focusedPrimaryId] !== undefined) {
        focusedPrimayDevicesSetInfo = devicesSetsInfo[focusedPrimaryId];
      }

      if (primariesAlarms[focusedPrimaryId] !== undefined) {
        focusedPrimaryVideoAlarms = primariesAlarms[focusedPrimaryId];
      }

      if (primariesLogs[focusedPrimaryId] !== undefined) {
        focusedPrimaryLogs = primariesLogs[focusedPrimaryId];
      }
    }

    const focusedPrimary = primariesUserDetails.find(({ primaryId }) => primaryId === focusedPrimaryId);
    const dashboardCompanyName = companyName.charAt(0).toUpperCase() + companyName.substring(1);
    const dashboardAdminFirstName = adminFirstName.charAt(0).toUpperCase() + adminFirstName.substring(1);
    const dashboardAdminLastName = adminLastName.charAt(0).toUpperCase() + adminLastName.substring(1);
    return (
      <div className="main">
        <HashRouter>
          <Header
            dashboardCompanyName={dashboardCompanyName}
            dashboardAdminFirstName={dashboardAdminFirstName}
            dashboardAdminLastName={dashboardAdminLastName}
            logout={this.closeConnectionToServer}
            focusedPrimary={focusedPrimary}
            guestsInfo={{ guestsTotal, guestsTotalHistory, guestsRemain, guestsFreeTrialRemain, guestsMax, guestsCurrentCount }}
            filteredOutputs={filteredOutputsWithDisplayedName}
            companyType={companyType}
            isAtLeastOneCorporateLicense={this.isAtLeastOneCorporateLicense()}
            isNotificationDisplayed={isNotificationDisplayed}
            currentNotificationType={currentNotificationType}
            notificationMsg={notificationMsg}
            showDashboardPage={showDashboardPage}
            showMapPage={showMapPage}
            handlePagesSwitch={this.handlePagesSwitch}
            hideNotification={this.hideNotification}
            showNotification={this.showNotification}
            handlePrimaryFocus={this.handlePrimaryFocus}
            primaryUsers={primaryUsers}
            getControllerInfo={this.getControllerInfo}
            fetchExportList={fetchExportList}
            setDisplayedName={this.setDisplayedName}
          />
          <Switch>
            <Redirect exact from="/" to="/dashboard" />
            <Route path="/dashboard">
              <Dashboard
                isWebSocketConnected={this.isWebSocketConnected()}
                primaryUsers={filteredPrimaryUsersWithDisplayedName}
                broadcastDetailsJsonObject={broadcastDetailsJsonObject}
                arePrimaryUsersLoading={arePrimaryUsersLoading}
                outputs={filteredOutputsWithDisplayedName}
                areOutputsLoading={areOutputsLoading}
                videoServerStatus={videoServerStatus}
                focusedPrimaryId={focusedPrimaryId}
                isChangingStartStopInProgress={isChangingStartStopInProgress}
                primariesBroadcastDetails={trimmedPrimariesBroadcastDetails}
                isUserCorporate={isUserCorporate}
                isUserVideographer={isUserVideographer}
                isUserInfluencer={isUserInfluencer}
                isUserFreeTrial={isUserFreeTrial}
                isUserMyKey={isUserMyKey}
                isUserBasic={isUserBasic}
                isUserLiveOmni={isUserLiveOmni}
                isUserGuest={isUserGuest}
                broadcastProperties={trimmedBroadcastProperties}
                focusedPrimaryBroadcastSettings={
                  focusedPrimaryBroadcastSettings
                }
                arePrimaryBroadcastSettingsLoading={
                  arePrimaryBroadcastSettingsLoading
                }
                arePrimaryRecordsLoading={arePrimaryRecordsLoading}
                focusedPrimayBroadcastDetails={focusedPrimayBroadcastDetails}
                primaryRecordsDetails={primaryRecordsDetails}
                focusedPrimayDevicesSetInfo={focusedPrimayDevicesSetInfo}
                isDevicesSetsInfoLoading={isDevicesSetsInfoLoading}
                focusedPrimaryVideoAlarms={focusedPrimaryVideoAlarms}
                focusedPrimaryLogs={focusedPrimaryLogs}
                isBitrateChartDisplayed={showDashboardPage}
                isAlertDialogDisplayed={isAlertDialogDisplayed}
                alertDialogType={alertDialogType[currentAlertDialogType]}
                screenSize={screenSize}
                companyType={companyType}
                showNotification={this.showNotification}
                handlePrimaryFocus={this.handlePrimaryFocus}
                setDisplayedName={this.setDisplayedName}
                clickStartStop={this.handleStartStopClick}
                clickIFBmouseDown={this.handleIFBdownEvents}
                clickIFBmouseUp={this.handleIFBupEvents}
                clickSettingsApply={this.handleSettingsApplyClick}
                clickLogsClear={this.handleLogsClear}
                onAlertDialogClick={this.handleAlertDialogButtonsClick}
                handleStartRecordUpload={this.handleStartRecordUpload}
                handlePauseRecordUpload={this.handlePauseRecordUpload}
                handleDeleteRecord={this.handleDeleteRecord}
                onAudioSelect={this.onAudioSelect}
                onAttachPrimary={this.onAttachPrimary}
                onDettachPrimary={this.onDettachPrimary}
                audioDevices={audioDevices}
                selectedDevice={selectedDevice}
                isAudioChannelOpen={isAudioChannelOpen}
                setFetchExportListFn={this.setFetchExportListFn}
                dashboardCompanyName={dashboardCompanyName}
              />
            </Route>
            <Route path="/map">
              <Map
                primaryUsers={filteredPrimaryUsersWithDisplayedName.map(
                  ({
                    id,
                    username,
                    displayedName,
                    isActive,
                    isBroadcasting,
                    latitude,
                    longitude,
                  }) => {
                    return {
                      id,
                      username,
                      displayedName,
                      isActive,
                      isBroadcasting,
                      latitude,
                      longitude,
                    };
                  }
                )}
                focusedPrimaryId={focusedPrimaryId}
                screenSize={this.props.screenSize}
                handlePrimaryFocus={this.handlePrimaryFocus}
                clickIFBmouseDown={this.handleIFBdownEvents}
              />
            </Route>
          </Switch>
          <div className="container-fluid">
            <Modal
              uniqueId="codec-rtmp-conflict-modal"
              headerTitle="Pay attention"
              showExclamationIcon={true}
              buttons={[{ name: 'Close', className: 'info' }]}
            >
              <p>RTMP streaming can not be done when H.265 codec is used.</p>
              <p>Clicking "Apply" button below will disable RTMP streaming.</p>
            </Modal>
            <IfbModal changeIFBbytesPerSample={this.changeIFBbytesPerSample} />
          </div>
        </HashRouter>
      </div>
    );
  }
}

Main.propTypes = {
  setLogout: PropTypes.func.isRequired,
};

export default Main;
