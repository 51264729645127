import React from 'react';
import PropTypes from 'prop-types';

import './AlarmIcon.css';

//--------------- AlarmIcon.js local constants ---------------//
const CRITICAL_CLASSES = ['', 'warning', 'danger'];
//-----------------------------------------------------------//

class AlarmIcon extends React.Component{
	state = {
		isTooltipDisplayed: false
	}

	handleTooltipToggle = (e) => {
		if (e.type === 'mouseenter') {
			this.setState({ isTooltipDisplayed: true });
		} else if (e.type === 'mouseleave') {
			this.setState({ isTooltipDisplayed: false });
		}
	};

	render() {
		const {
			tooltipDirection,
			criticalLevel,
			alarmTooltipText,
			alarmIcon,
			isIconBold,
			position
		} = this.props;

        return (
            <div
                className={`alarm-icon-container-${position} alarm-icon-tooltip-${tooltipDirection}`}>
                <i
                    className={`material-icons alarm-icon 
									text-${CRITICAL_CLASSES[criticalLevel]}
									${isIconBold ? ' font-weight-bold' : ''}`}
                    onMouseEnter={this.handleTooltipToggle}
                    onMouseLeave={this.handleTooltipToggle}>
                    {alarmIcon}
                </i>
                <span
                    className={`tooltip-message${this.state.isTooltipDisplayed
                        ? ' show-tooltip'
                        : ''}`}
                >
			{alarmTooltipText}
		            </span>
            </div>
        );
	}
}

AlarmIcon.propTypes = {
	position: PropTypes.string.isRequired,
	tooltipDirection: PropTypes.string.isRequired,
	criticalLevel: PropTypes.number.isRequired,
	alarmTooltipText: PropTypes.string.isRequired,
	alarmIcon: PropTypes.string.isRequired,
	isIconBold: PropTypes.bool
};

export default React.memo(AlarmIcon);
