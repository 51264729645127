export const exportTypeMapping = {
    0: 'CUSTOM RTMP',
    1: 'SRT CALLER',
    2: 'SRT LISTENER',
    3: 'FACEBOOK USER PROFILE',
    4: 'FACEBOOK PAGE ADMIN',
    5: 'FACEBOOK GROUP',
    6: 'YOUTUBE',
    7: 'LIVE OMNI - RTMP',
    8: 'LIVE OMNI - SRT',
    9: 'TWITCH',
    10: 'TELEGRAM',
    11: 'INSTAGRAM',
};
