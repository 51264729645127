import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';

import './Viewer.css';

import InfoHeader from '../InfoHeader/InfoHeader';
import MiniPreview from '../MiniPreview/MiniPreview';
import AlarmIcon from '../common/AlarmIcon/AlarmIcon';
import Button from '../common/Button/Button';
import config from '../../dashboard_config.json';

class Viewer extends React.Component {
	state = {
		isAlarmIconDisplayed: false,
		isAlarmCritical: false,
		alarmTooltipText: '',
		criticalAlarmTooltipText: '',
	};

	_isSchedulerSet = false;
	durationStr = '00:00:00';

	componentDidMount() {
		this.checkStatusesForAlarms(this.props.statuses);
		$('.viewer').fadeIn(500);
	}

	componentDidUpdate(prevProps) {
		const { isBroadcasting, broadcastDetails, statuses } = this.props;

		if (isBroadcasting !== prevProps.isBroadcasting) {
			if (isBroadcasting) {
				this.setDurationScheduler();
				this._isSchedulerSet = true;
			} else {
				clearInterval(this._durationScheduler);
				this._isSchedulerSet = false;
				this.durationStr = '00:00:00';
			}
		}

		if (broadcastDetails !== prevProps.broadcastDetails) {
			if (isBroadcasting && !this._isSchedulerSet) {
				this.setDurationScheduler();
				this._isSchedulerSet = true;
			}
		}

		if (statuses !== prevProps.statuses) {
			this.checkStatusesForAlarms(statuses);
		}
	}

	componentWillUnmount() {
		clearInterval(this._durationScheduler);
	}

	setDurationScheduler = () => {
		if (this.props.broadcastDetails) {
			this.durationStr = this.props.broadcastDetails.duration;
		}
		this._durationScheduler = setInterval(() => {
			if (this.props.broadcastDetails) {
				this.durationStr = this.props.broadcastDetails.duration;
			}
		}, 1000);
	};

	checkStatusesForAlarms = (statuses) => {
		const criticalAlarmIndex = statuses.findIndex(
			(status) => status.Name === 'PortsUnavailable'
		);
		const criticalAlarmTooltipText = criticalAlarmIndex > -1 ? statuses[criticalAlarmIndex].Description : '';

		const alarmIndex = statuses.findIndex(
			(status) => status.Name === 'DownLinkStatus'
		);
		const alarmTooltipText = 
		alarmIndex > -1 ? statuses[alarmIndex].Description : '';

		const isAlarmCritical = !!criticalAlarmTooltipText;
		const isAlarmIconDisplayed = !!alarmTooltipText || !!criticalAlarmTooltipText;

		this.setState({
			alarmTooltipText,
			criticalAlarmTooltipText,
			isAlarmCritical,
			isAlarmIconDisplayed,
		});
	};

	handleClick = (e) => {
		const classNameStr = e.target.className.toString();
		if (!classNameStr.includes('name-edit-icon')) {
			this.props.handlePrimaryFocus(this.props.primaryId);
		}
	};

	render() {
		const {
			focusedPrimaryId,
			primaryId,
			primaryName,
			displayedName,
			isActive,
			isBroadcasting,
			broadcastDetails,
			screenSize,
			setDraggedElement,
			handleDrop,
			setDetailsForModal,
			outputAddressId,
			daysToExpire,
			onAttachPrimary,
			onDettachPrimary,
			audioDevices,
			selectedDevice,
			companyType,
			isPrimaryBroadcasting,
			streamType,
			clickStartStop,
			isChangingStartStopInProgress,
			showNotification,
			setShareId,
			setPrimaryToDelete,
			primaryUsers,
			licensePermission,
			licensePermissionId,
			licenseTypeId,
			licenseSource,
		} = this.props;

		const {
			isAlarmIconDisplayed,
			isAlarmCritical,
			alarmTooltipText,
			criticalAlarmTooltipText,
		} = this.state;

		// Map primary users to their respective device OS.
		const primaryDeviceMappings = primaryUsers.map((user) => ({
			primaryId: user.id,
			deviceOS: user.deviceOS,
		}));

		// Function to find the deviceOS for a given primaryId.
		const getDeviceOSForPrimary = (primaryId) => {
			const mapping = primaryDeviceMappings.find(
				(mapping) => mapping.primaryId === primaryId
			);
			return mapping ? mapping.deviceOS : null;
		};

		// Determine the deviceOS for the current primary.
		const deviceOS = getDeviceOSForPrimary(primaryId);

		// Function to determine if the button should be shown based on the configuration.
		const shouldShowButton = (deviceOS) => {
			const { ifb } = config.dashboard;
			const showIfb = ifb && ifb[deviceOS] && ifb[deviceOS].showIfbButtonsAndDevicesList;
			return showIfb !== undefined ? showIfb : true;
		};

		// Define the iconName for the headset-related logic (companyType === 0).
		const headsetIconName =
			selectedDevice && selectedDevice.primaries[primaryId]
				? 'headset'
				: 'headset_off';

		// Define the play-stop icon for companyType 1.
		const playStopIconName = isPrimaryBroadcasting ? 'stop' : 'play_arrow';

		return (
			<div
				className={`viewer mb-1${focusedPrimaryId === primaryId
					? ' bg-info'
					: ''}`}

			>
				<div
					onClick={this.handleClick}
					className="drag-and-drop-container"
					draggable="true"
					onDragStart={() => setDraggedElement({ type: 'primary', id: primaryId })}
					onDragOver={(e) => e.preventDefault()}
					onDrop={() => handleDrop({ type: 'primary', id: primaryId })}
				>
					<InfoHeader
						type="Primary"
						id={focusedPrimaryId}
						primaryId={primaryId}
						name={primaryName}
						displayedName={displayedName}
						isActive={isActive}
						isBroadcasting={isBroadcasting}
						screenSize={screenSize}
						componentLocation="dashboard-input"
						setDetailsForModal={setDetailsForModal}
						outputAddressId={outputAddressId}
						daysToExpire={daysToExpire}
						companyType={companyType}
						showNotification={showNotification}
						setShareId={setShareId}
						setPrimaryToDelete={setPrimaryToDelete}
						primaryUsers={primaryUsers}
						licensePermission={licensePermission}
						licensePermissionId={licensePermissionId}
						licenseTypeId={licenseTypeId}
						licenseSource={licenseSource}
					/>
					<div className="parent-class">
						{isAlarmIconDisplayed && (
							<AlarmIcon
								position="absolute"
								tooltipDirection="down"
								criticalLevel={isAlarmCritical ? 2 : 1}
								alarmTooltipText={isAlarmCritical ? criticalAlarmTooltipText : alarmTooltipText}
								alarmIcon="warning"/>
						)}

						<MiniPreview
							isInput={true}
							signalId={primaryId}
							isThereSignal={isBroadcasting}
							screenSize={screenSize}
							audioDevices={audioDevices}
							primaryId={primaryId}
							companyType={companyType}
						/>

						<div
							className={`broadcast-info${companyType===0
								? ''
								: '-extended'} font-weight-bold ${isBroadcasting
								? 'visible'
								: 'invisible'}`}
						>
							<span>{broadcastDetails ? broadcastDetails.bitrate : '0.0'} Mb/s</span>
							<span>{this.durationStr}</span>
						</div>
					</div>
				</div>
				{companyType === 0 && shouldShowButton(deviceOS) ? (
					// Headset button for companyType 0 based on config
					<Button
						bsClassSuffix="white"
						iconName={headsetIconName}
						isDisabled={!primaryId || selectedDevice === ''}
						clickHandler={() => {
							if (selectedDevice.primaries[primaryId] === undefined) {
								onAttachPrimary(selectedDevice, primaryId);
							} else {
								onDettachPrimary(selectedDevice, primaryId);
							}
						}}
					/>
				) : companyType === 1 && focusedPrimaryId === primaryId ? (
					// Play-Stop button for companyType 1 when the primary is focused
					!isChangingStartStopInProgress ? (
						<Button
							bsClassSuffix={focusedPrimaryId === primaryId ? 'white' : 'black'}
							iconName={playStopIconName}
							clickHandler={clickStartStop}
						/>
					) : (
						<Button
							uniqueId="play-stop-btn"
							showSpinner={true}
							clickHandler={() => { }}
						/>
					)
				) : null}
			</div>
		);
	}
}

Viewer.propTypes = {
	focusedPrimaryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	primaryId: PropTypes.number.isRequired,
	isBroadcasting: PropTypes.bool.isRequired,
	statuses: PropTypes.array.isRequired,
	broadcastDetails: PropTypes.object,
	handlePrimaryFocus: PropTypes.func.isRequired,
	setDraggedElement: PropTypes.func.isRequired,
	handleDrop: PropTypes.func.isRequired,
};

export default Viewer;
