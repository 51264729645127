import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import './BroadcastDetails.css';

import BroadcastSettings from '../BroadcastSettings/BroadcastSettings';
import BitrateChartAndDevicesInfo from '../BitrateChartAndDevicesInfo/BitrateChartAndDevicesInfo';
import AlarmsAndAlerts from '../AlarmsAndAlerts/AlarmsAndAlerts';
import Spinner from '../common/Spinner/Spinner';

const BroadcastDetails = ({
  isWebSocketConnected, // This prop is used in this component
  outputs,
  broadcastDetailsJsonObject,
  focusedPrimaryId, // This prop is used in this component,
  primaryName,
  isPrimaryBroadcasting,
  broadcastSettings, // This prop is used in this component
  areBroadcastSettingsLoading,
  primaryUsers,
  arePrimaryRecordsLoading,
  socialMediaSettings,
  alarmsVideo,
  logs,
  broadcastDetails,
  primaryRecords,
  devicesSetInfo,
  isDevicesSetsInfoLoading,
  isBitrateChartDisplayed,
  screenSize,
  clickSettingsApply,
  associatePrimaryWithOutput,
  clickLogsClear, // This prop is used in this component
  handleStartRecordUpload,
  handlePauseRecordUpload,
  handleDeleteRecord,
  setDetailsForDeleteRecordModal,
  showNotification,
  companyType,
  setExportModalData,
  isUserFreeTrial,
  isUserBasic,
  isUserLiveOmni,
  setFetchExportListFn,
}) => {
  return (
    <div className="broadcast-details d-flex">
      {isWebSocketConnected ? (
        <Fragment>
          <div className="details-container">
            <div className="row">
              <BroadcastSettings
                outputs={outputs}
                focusedPrimaryId={focusedPrimaryId}
                isPrimaryBroadcasting={isPrimaryBroadcasting}
                broadcastSettings={broadcastSettings}
                areBroadcastSettingsLoading={areBroadcastSettingsLoading}
                primaryUsers={primaryUsers}
                arePrimaryRecordsLoading={arePrimaryRecordsLoading}
                socialMediaSettings={socialMediaSettings}
                primaryRecords={primaryRecords}
                clickSettingsApply={clickSettingsApply}
                associatePrimaryWithOutput={associatePrimaryWithOutput}
                handleStartRecordUpload={handleStartRecordUpload}
                handlePauseRecordUpload={handlePauseRecordUpload}
                handleDeleteRecord={handleDeleteRecord}
                setDetailsForDeleteRecordModal={setDetailsForDeleteRecordModal}
                screenSize={screenSize}
                showNotification={showNotification}
                companyType={companyType}
                setExportModalData={setExportModalData}
                primaryName={primaryName}
                isUserFreeTrial={isUserFreeTrial}
                isUserBasic={isUserBasic}
                isUserLiveOmni={isUserLiveOmni}
                setFetchExportListFn={setFetchExportListFn}
              />
              <BitrateChartAndDevicesInfo
                broadcastDetailsJsonObject={broadcastDetailsJsonObject}
                focusedPrimaryId={focusedPrimaryId}
                isPrimaryBroadcasting={isPrimaryBroadcasting}
                broadcastSettings={broadcastSettings}
                broadcastDetails={broadcastDetails}
                devicesSetInfo={devicesSetInfo}
                isDevicesSetsInfoLoading={isDevicesSetsInfoLoading}
                isBitrateChartDisplayed={isBitrateChartDisplayed}
                screenSize={screenSize}
              />
            </div>
          </div>

          <div className="alarms-container">
            <AlarmsAndAlerts
              primaryName={primaryName}
              alarmsVideo={alarmsVideo}
              alarmsPrimary={broadcastSettings.alarmsPrimary}
              logs={logs}
              clickLogsClear={() => clickLogsClear(focusedPrimaryId)}
              isPrimaryBroadcasting={isPrimaryBroadcasting}
              focusedPrimaryId={focusedPrimaryId}
            />
          </div>
        </Fragment>
      ) : (
        <Spinner
          customClass="broadcast-details-spinner"
          customText="Connection to server is lost. Trying to reconnect"
        />
      )}
    </div>
  );
};

BroadcastDetails.propTypes = {
  isWebSocketConnected: PropTypes.bool.isRequired,
  focusedPrimaryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  broadcastSettings: PropTypes.object.isRequired,
};

export default React.memo(BroadcastDetails);
