import React from 'react';
import PropTypes from 'prop-types';

import './SelectInput.css';

const SelectInput = ({
	uniqueId,
	customClass,
	labelName,
	valuesArray,
	currentValue,
	isDisabled,
	changeHandler
}) => (
	<div
		className={`${customClass
			? customClass + ' '
			: ''}select-input form-inline `}
	>
		<label htmlFor={uniqueId}>{labelName}</label>
		<select
			className="form-control-sm"
			id={uniqueId}
			value={currentValue}
			onChange={changeHandler}
			disabled={isDisabled}
		>
			{valuesArray.map(({ value, displayedName }, i) => (
				<option key={i} value={value}>
					{displayedName}
				</option>
			))}
		</select>
	</div>
);

SelectInput.propTypes = {
	uniqueId: PropTypes.string.isRequired,
	customClass: PropTypes.string,
	labelName: PropTypes.string.isRequired,
	valuesArray: PropTypes.array.isRequired,
	currentValue: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
	isDisabled: PropTypes.oneOf([ undefined, false, true ]),
	changeHandler: PropTypes.func.isRequired
};

export default React.memo(SelectInput);
