import React from "react";
import s from "./SRTCallerForm.module.css";
import { TextFieldForm } from "../TextFieldForm/TextFieldForm";

export const SRTCallerForm = ({ exportForm, setExportForm }) => {

    const handleInputChange = (name, value) => {
        setExportForm((prevForm) => ({
            ...prevForm,
            [name]: value,
        }));
    };

    return (
        <div className={s.mainContext}>
            <div>
                <TextFieldForm
                    label="Steam host"
                    name="host"
                    style={{ width: "100%" }}
                    isBlackInput={true}
                    isSmallHeight={true}
                    required={true}
                    value={exportForm.host}
                    onChange={(e) => {
                        handleInputChange("srtHost", e.target.value);
                    }}
                />
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                    <TextFieldForm
                        label="Port"
                        name="port"
                        style={{ width: "200px" }}
                        isBlackInput={true}
                        isSmallHeight={true}
                        required={true}
                        value={exportForm.port}
                        onChange={(e) => {
                            handleInputChange("port", e.target.value);
                        }}
                    />
                </div>

                <div>
                    <TextFieldForm
                        label="Steam ID"
                        name="id"
                        style={{ width: "200px" }}
                        isBlackInput={true}
                        isSmallHeight={true}
                        value={exportForm.id}
                        onChange={(e) => handleInputChange("id", e.target.value)}
                    />
                </div>
            </div>

            <div>
                <TextFieldForm
                    label="Passphrase"
                    name="passphrase"
                    style={{ width: "100%" }}
                    isBlackInput={true}
                    isSmallHeight={true}
                    value={exportForm.passphrase}
                    onChange={(e) => handleInputChange("passphrase", e.target.value)}
                />
            </div>
        </div>
    );
};
