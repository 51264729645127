import React from 'react';
import axios from 'axios';
import Modal from '../common/Modal/Modal'; // Adjust import path based on your project structure
import { PRIMARY_API } from '../../constants/endPointsURLs';
import './DeletePrimaryModal.modal.css';
import $ from 'jquery';

export const DeletePrimaryModal = ({ id, showNotification, primaryName }) => {

    const deletePrimaryById = async () => {
        try {
            const response = await axios.delete(PRIMARY_API.deletePrimaryById(id), { withCredentials: true });
            if (response.status === 200) {
                showNotification('success', `${primaryName} successfully deleted`);
            } else {
                console.error(`Failed to delete item. Status code: ${response.status}`);
            }
        } catch (error) {
            console.error('Error deleting primary', error);
            showNotification('error', 'Failed to delete item. Please try again.');
        }
    };

    const handleCloseModal = () => {
        $('#delete-primary-modal').modal('hide');
    };

    return (
        <Modal
            uniqueId="delete-primary-modal"
            headerTitle="Delete Guest?"
            bodyClass="modal-body modal-body-alert"
            buttons={[
                {
                    name: 'Yes',
                    className: 'danger danger-submit-btn',
                    clickHandler: deletePrimaryById,
                },
                {
                    name: 'No',
                    className: 'danger danger-submit-btn',
                    clickHandler: handleCloseModal,
                },
            ]}
        >
            <p>Are you sure you want to delete {primaryName}?<br/>
            This action cannot be undone.</p>
        </Modal>
    );
};
