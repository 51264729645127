import React from 'react';
import PropTypes from 'prop-types';

import './Button.css';

const Button = ({
	uniqueId,
	bsClassSuffix,
	size,
	iconName,
	showSpinner,
	text,
	isDisabled,
	clickHandler,
	mouseDownHandler,
	mouseUpHandler,
}) => (

	<button
		id={uniqueId}
		className={`btn btn-${bsClassSuffix || 'info'} ${size} d-flex justify-content-center align-items-center`}
		onClick={clickHandler}
		onMouseDown={mouseDownHandler}
		onMouseUp={mouseUpHandler}
		disabled={isDisabled || false}>
		
		{text}
		{iconName && (
			<i className={`material-icons${text ? ' ml-1' : ''}`}>{iconName}</i>
		)}
		{showSpinner && (
			<div
				className={`spinner-border spinner-border-sm${text ? ' ml-1' : ''}`}
			/>
		)}
	</button>
);

Button.propTypes = {
	uniqueId: PropTypes.string,
	bsClassSuffix: PropTypes.string,
	size: PropTypes.string,
	iconName: PropTypes.string,
	showSpinner: PropTypes.oneOf([undefined, false, true]),
	text: PropTypes.string,
	isDisabled: PropTypes.oneOf([undefined, false, true]),
	clickHandler: PropTypes.func.isRequired,
	mouseDownHandler: PropTypes.func,
};

export default React.memo(Button);
