import React, { Fragment } from 'react';
import './Carousel.css';

class Carousel extends React.Component {
  state = {
    arrayLength: 1,
    currentPosition: 1,
    arrIndex: [],
  };

  componentDidMount() {
    const arrayLength = this.props.exportList ? this.props.exportList.length : null;
    const arrIndex = [];
    for (let i = 0; i < this.props.exportList.length; i++) {
      arrIndex.push(i);
    }
    this.setState({ arrayLength, arrIndex });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.exportList.length !== this.props.exportList.length) {
      const arrayLength = this.props.exportList ? this.props.exportList.length : null;
      const arrIndex = [];
      for (let i = 0; i < this.props.exportList.length; i++) {
        arrIndex.push(i);
      }

      if (prevProps.exportList.length > this.props.exportList.length) {
        if (arrIndex.length === 1) {
          this.setState({ arrayLength, arrIndex, currentPosition: 1 });
        } else {
          if (this.state.currentPosition !== 1) {
            this.setState({
              arrayLength,
              arrIndex,
              currentPosition: this.state.currentPosition - 1,
            });
          } else {
            this.setState({ arrayLength, arrIndex, currentPosition: 1 });
          }
        }
      } else {
        this.setState({ arrayLength, arrIndex });
      }
    }
  }

  render() {
    const toDoNext = () => {
      if (this.state.arrayLength === 0 || this.state.arrayLength === 1) {
        return;
      } else {
        if (this.state.currentPosition === this.state.arrayLength) {
          this.setState({ currentPosition: 1 });
        } else {
          this.setState({ currentPosition: this.state.currentPosition + 1 });
        }
        this.props.handleResetShowText();
      }
    };

    const toDoBefore = () => {
      if (this.state.arrayLength === 0 || this.state.arrayLength === 1) {
        return;
      } else {
        if (this.state.currentPosition === 1) {
          this.setState({ currentPosition: this.state.arrayLength });
        } else {
          this.setState({ currentPosition: this.state.currentPosition - 1 });
        }
        this.props.handleResetShowText();
      }
    };

    const generateDots = () => {
      return (
        <Fragment>
          {this.state.arrIndex.map((elem, index) => {
            elem++;
            return (
              <div
                key={index}
                className={`${
                  elem === this.state.currentPosition
                    ? 'carousel-dot-active-icon'
                    : 'carousel-dot-icon'
                } carousel-dot-mr`}
                onClick={() => this.setState({ currentPosition: elem })}></div>
            );
          })}
        </Fragment>
      );
    };

    return (
      <div className="carousel-wrapper">
        {this.props.exportComponent[this.state.currentPosition - 1]}
        <div className="carousel-container">
          <span className="material-icons carousel-arrow-icon" onClick={toDoBefore}>
            navigate_before
          </span>
          <div className="carousel-dots-container">{generateDots()}</div>
          <span className="material-icons carousel-arrow-icon" onClick={toDoNext}>
            navigate_next
          </span>
        </div>
      </div>
    );
  }
}

export default Carousel;
