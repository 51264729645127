import React from 'react';
import s from './StreamInfo.module.css';
import { MultiSelectField } from '../../components/MultiSelectField/MultiSelectField';
import { cameraSourceOptions, resolutionOptions, bitrateOptions, codecOptions, autoStartOptions, orientationOptions, streamTypeOptions } from './options';

export const StreamInfo = ({ formField, filteredOutputs }) => {
    const { camera, resolution, bitrate, codec, streamType, orientation, broadcastOutputId, autoStart } = formField;

    const broadcastOutput = [
        { value: 'None', displayName: 'None' },
        ...filteredOutputs.map(item => ({ value: item.id, displayName: item.name }))
    ];

    return (
        <div className={s.container}>
            <MultiSelectField label={camera.label} options={cameraSourceOptions} name={camera.name} />
            <MultiSelectField label={resolution.label} options={resolutionOptions} name={resolution.name} />
            <MultiSelectField label={bitrate.label} options={bitrateOptions} name={bitrate.name} />
            <MultiSelectField label={codec.label} options={codecOptions} name={codec.name} />
            <MultiSelectField label={streamType.label} options={streamTypeOptions} name={streamType.name} />
            <MultiSelectField label={orientation.label} options={orientationOptions} name={orientation.name} />
            <MultiSelectField label={broadcastOutputId.label} options={broadcastOutput} name={broadcastOutputId.name} />
            <MultiSelectField label={autoStart.label} options={autoStartOptions} name={autoStart.name} />
        </div>
    );
};
