import React from 'react';
import PropTypes from 'prop-types';

import SelectInput from '../common/SelectInput/SelectInput';

const OutputAssociation = ({
							   outputs,
							   focusedPrimaryId,
							   handleOutputChange,
							   currentOutput
						   }) => {

	let outputsNamesArray = outputs.map(({name}) => {
		return {
			value: name.toLowerCase().replace(/ /g, '-'),
			displayedName: name
		};
	});

	outputsNamesArray.splice(0, 0, {
		value: 'not-connected',
		displayedName: 'None'
	});

	return (
		<div className="output-association mt-3 mb-3">
			<SelectInput
				uniqueId="output"
				customClass="output-association-select-input"
				labelName="Output"
				valuesArray={outputsNamesArray}
				currentValue={currentOutput}
				changeHandler={handleOutputChange}
				isDisabled={!focusedPrimaryId}
			/>
		</div>
	);
}

OutputAssociation.propTypes = {
	outputs: PropTypes.array.isRequired,
	focusedPrimaryId: PropTypes.number,
};

export default OutputAssociation;
