import checkoutFormModel from './guestFormModel';

const {
  formField: {
    firstName,
    lastName,
    email,
    countryCode,
    phoneNumber,
    camera,
    resolution,
    bitrate,
    codec,
    streamType,
    orientation,
    broadcastOutputId,
    autoStart },
} = checkoutFormModel;

const initialValues = {
  [firstName.name]: '',
  [lastName.name]: '',
  [email.name]: '',
  [countryCode.name]: '',
  [phoneNumber.name]: '',
  [camera.name]: 'front',
  [resolution.name]: '1080',
  [bitrate.name]: 3,
  [codec.name]: 'h264',
  [streamType.name]: 'Best Quality',
  [orientation.name]: 'Landscape',
  [broadcastOutputId.name]: 'None',
  [autoStart.name]: true,
};

export default initialValues;
