import React, { useState } from "react";
import s from "./CustomRTMPForm.module.css";
import { TextFieldForm } from "../TextFieldForm/TextFieldForm";

export const CustomRTMPForm = ({ exportForm, setExportForm }) => {

    const handleInputChange = (name, value) => {
        setExportForm((prevForm) => ({
            ...prevForm,
            [name]: value,
        }));
    };

    return (
        <div className={s.mainContext}>
            <div style={{ marginBottom: 8 }}>
                <TextFieldForm
                    label="RTMP URL"
                    name="host"
                    style={{ width: "100%" }}
                    isBlackInput={true}
                    required={true}
                    value={exportForm.host}
                    onChange={(e) => {
                        handleInputChange("rtmpHost", e.target.value);
                    }}
                />
            </div>

            <div>
                <TextFieldForm
                    label="RTMP Key"
                    name="key"
                    style={{ width: "100%" }}
                    isBlackInput={true}
                    value={exportForm.key}
                    onChange={(e) => handleInputChange("key", e.target.value)}
                />
            </div>
        </div>
    );
};
