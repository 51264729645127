import React from 'react';
import PropTypes from 'prop-types';
import s from './GuestGetMoreGuestsModal.module.css';
import config from '../../../../dashboard_config.json'

const GET_GUESTS_URL = config.dashboard.guests.buyGuestsUrl;

export const GuestGetMoreGuestsModal = ({ showExclamationIcon }) => {
  return (
    <div className="modal fade" id="get-guest-mode-modal">
      <div className="modal-dialog">
        <div className={`modal-content ${s['get-more-guests-modal']}`}>
          <div className="modal-header d-flex align-items-center">
            {showExclamationIcon && <i className="material-icons text-warning mr-2">warning</i>}
            <div id="first-modal" className="modal-title">
              Guest Invitations Limit Reached
            </div>
            <button className="close close-modal-btn" data-dismiss="modal">
              &times;
            </button>
          </div>

          <div className={`modal-body-guests-reach-limit ${s.body}`}>
            You've used up all your guest invitations.<br />
            Please purchase additional invitations to continue inviting guests.
          </div>

          <div className="modal-footer">
            <a
              href={GET_GUESTS_URL}
              target="_blank"
              style={{ width: '200px' }}
              className="btn list-bottom-buttons"
            >
              GET MORE GUESTS
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

GuestGetMoreGuestsModal.propTypes = {
  uniqueId: PropTypes.string.isRequired,
  headerTitle: PropTypes.string,
  showExclamationIcon: PropTypes.bool,
  children: PropTypes.any.isRequired,
};
