import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import s from './GuestInfo.module.css';
import { TextField } from '../../components/TextField/TextField';
import { PhoneNumberField } from '../../components/PhoneNumberField/PhoneNumberField';
import { getUserCountryFromLocalStorage } from '../../utils/userCountryUtils';

export const GuestInfo = ({ formField, isModalClosed }) => {
  const context = useFormikContext();
  const { firstName, lastName, email, phoneNumber } = formField;
  const { values, setFieldValue } = context;

  const capitalizeFirstLetterOfEachWord = (string) => {
    if (!string) return '';
    return string
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const defaultCountry = getUserCountryFromLocalStorage();

  useEffect(() => {
    if (values['countryCode'] !== defaultCountry) {
      setFieldValue('countryCode', defaultCountry);
    }
  }, [])

  useEffect(() => {
    const updatedFirstName = capitalizeFirstLetterOfEachWord(values[firstName.name]);
    if (values[firstName.name] !== updatedFirstName) {
      setFieldValue(firstName.name, updatedFirstName);
    }

    const updatedLastName = capitalizeFirstLetterOfEachWord(values[lastName.name]);
    if (values[lastName.name] !== updatedLastName) {
      setFieldValue(lastName.name, updatedLastName);
    }
  }, [values[firstName.name], values[lastName.name], setFieldValue, firstName.name, lastName.name]);

  return (
    <div className={s.root}>
      <div className={s.field}>
        <TextField name={firstName.name} label={firstName.label} required />
        <TextField name={lastName.name} label={lastName.label} required />
      </div>
      <div className={s.field}>
        <TextField name={email.name} label={email.label} optinal />
        <PhoneNumberField name={phoneNumber.name} label={phoneNumber.label} required />
      </div>
    </div>
  );
};
